import Big from 'big.js';
import { parseNearAmount } from 'near-api-js/lib/utils/format';

export const docsLink = 'https://jumbo-exchange.gitbook.io/product-docs/';
export const telegramLink = 'https://t.me/jumbo_exchange';
export const twitterLink = 'https://twitter.com/jumbo_exchange';
export const mediumLink = 'https://medium.com/jumbo-dex';
export const hapiLink = 'https://hapi.one/';

export const SLIPPAGE_TOLERANCE_DEFAULT = '1';
export const SLIPPAGE_TOLERANCE_DEFAULT_ADD_STABLE_LIQ = '0.1';
export const TOTAL_FEE_DEFAULT = '0.3';

export const MAX_TOGGLE_AMOUNT = 100;
export const MIN_TOGGLE_AMOUNT = 0;

export const MAX_SLIPPAGE_TOLERANCE = 100;
export const MIN_SLIPPAGE_TOLERANCE = 0;

export const MAX_TOTAL_FEE = 20;
export const MIN_TOTAL_FEE = 0;

export const COEFFICIENT_SLIPPAGE = 0.33;
export const COEFFICIENT_TOTAL_FEE = 0.33;

export const MIN_FEE_CREATE_POOL = '0.01';
export const MAX_FEE_CREATE_POOL = '20';

export const poolFeeOptions = [
  { label: '0.2%', value: '0.2' },
  { label: '0.3%', value: '0.3' },
  { label: '0.6%', value: '0.6' },
];

export const slippageToleranceOptions: {label: string, value: string}[] = [
  { label: '0.1%', value: '0.1' },
  { label: '1%', value: '1' },
  { label: '5%', value: '5' },
];

export const slippageToleranceOptionsAddStableLiq: {label: string, value: string}[] = [
  { label: '0.05%', value: '0.05' },
  { label: '0.1%', value: '0.1' },
  { label: '0.2%', value: '0.2' },
];

export const FT_GAS = '180000000000000';
export const DEFAULT_FT_GAS = '100000000000000';

export const ONE_YOCTO_NEAR = '0.000000000000000000000001';
export const FT_STORAGE_DEPOSIT_GAS = parseNearAmount('0.00000000003');
export const FT_TRANSFER_GAS = parseNearAmount('0.00000000003');
export const ONE_MORE_DEPOSIT_AMOUNT = '0.01';
export const ACCOUNT_MIN_STORAGE_AMOUNT = '0.005';
export const MIN_DEPOSIT_PER_TOKEN = new Big('5000000000000000000000');
export const STORAGE_PER_TOKEN = '0.005';
export const LP_STORAGE_AMOUNT = '0.01';
export const YOCTO_IN_NEAR_DECIMALS = 24;
export const FEE_DIVISOR = 10000;

export const STORAGE_TO_REGISTER_MFT = '0.045';
export const MIN_DEPOSIT_PER_TOKEN_FARM = new Big('45000000000000000000000');

export const LP_TOKEN_DECIMALS = 24;
export const STABLE_LP_TOKEN_DECIMALS = 18;

export const BAD_PRICE_IMPACT = 2;
export const SHOW_WARNING_PRICE_IMPACT = 10;

export const NEAR_TOKEN_ID = 'NEAR';

export const SWAP_INPUT_KEY = 'JUMBO_SWAP_INPUT_TOKEN';
export const SWAP_OUTPUT_KEY = 'JUMBO_SWAP_OUTPUT_TOKEN';

export const URL_INPUT_TOKEN = 'inputToken';
export const URL_OUTPUT_TOKEN = 'outputToken';

export const INITIAL_INPUT_PLACEHOLDER = '';
export const ZERO = 0;
export const ZERO_AMOUNT = '0';
export const SMALL_SHARE = '0.001';

export const MIN_DEPOSIT_SHARES = '10000000000000000000';

export const SECONDS_IN_A_DAY = 86400;
export const DAYS_A_YEAR = 365;
export const ONE_HUNDRED = 100;
export const SHOW_MIN_TOTAL_LIQUIDITY = '1000';

export const UPDATE_USER_FARM_DATA = 5;

export const ACCOUNT_TRIM_LENGTH = 10;
export const FULL_PERCENTAGE = 100;

export const URL_MODAL = 'modal';
export const URL_POOL_ID = 'poolId';

export const TIMESTAMP = {
  ONE_DAY: 60 * 60 * 24,
  ONE_HOUR: 60 * 60,
  ONE_MINUTE: 60,
};
export const ONE_SECOND = 1000;

export enum EDimensions {
  EXTRA_SMALL = 'EXTRA_SMALL',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  EXTRA_LARGE = 'EXTRA_LARGE',
  UNKNOWN = 'UNKNOWN',
}

export const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1280,
};

export const tokenIds = {
  ONE_MIL: 'a4ef4b0b23c1fc81d3f9ecf93510e64f58a4a016.factory.bridge.near',
  CHICA: 'token.bocachica_mars.near',
};

export const DASH = '-';
export const BALANCE_PRECISION = 3;
export const EMPTY_STRING = '';
export const THOUSAND = 1000;
export const SECONDS_IN_MONTH = 30 * 24 * 60 * 60;
