import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { ReactComponent as CloseIcon } from 'assets/images-app/close.svg';
import GifLoading from 'assets/gif/loading.gif';
import PoolContract from 'services/contracts/PoolContract';
import { formatBalance, formatTokenAmount, removeTrailingZeros } from 'utils/calculations';
import Big from 'big.js';
import { ButtonPrimary } from 'components/Button';
import { useTranslation } from 'react-i18next';

import { useWalletSelector } from 'providers/wallet-provider';
import ClaimArrow from 'assets/images-app/claim-arrow';
import { IBaseModal } from 'providers/modals.interfaces';
import { useAppSelector } from 'hooks/redux-hooks';
import { selectTokens } from 'store/slices';
import { selectJumboRuntime } from 'store/slices/jumboRuntime';
import {
  Layout, ModalBlock, ModalIcon, ModalTitle,
} from '../styles';
import {
  LogoContainer,
  TokenDescriptionBlock,
  TokenRowContainer,
  TokenSubtitle,
  TokenTitle,
  ClaimModalBlock,
  ClaimModal,
  ModalFooter,
  LogoClaim,
  Container,
  NoClaimBlock,
} from './styles';

const WithdrawDepositModal: React.FC<IBaseModal> = ({ closeModal }) => {
  const {
    isSignedIn, RPCProvider, accountId, requestSignTransactions,
  } = useWalletSelector();
  const isConnected = isSignedIn();

  const [tokensArray, setTokensArray] = useState<{[key:string]: string}>({});
  const poolContract = useMemo(
    () => new PoolContract(RPCProvider, accountId),
    [RPCProvider, accountId],
  );
  const { loading } = useAppSelector(selectJumboRuntime);
  const tokens = useAppSelector(selectTokens);
  const { t } = useTranslation();

  const getDeposits = useCallback(async () => {
    if (!isConnected) return;
    const useDeposits = await poolContract.getDeposits();
    setTokensArray(useDeposits);
  }, [setTokensArray, poolContract, isConnected]);

  useEffect(() => {
    getDeposits();
  }, [getDeposits]);

  const claimList = Object.entries(tokensArray).filter(([, value]) => Big(value).gt(0));

  const canClaim = claimList.length !== 0;
  const titleButton = canClaim ? t('action.claimAll') : t('action.ok');

  const claimListArray = useMemo(() => claimList.map(([tokenId, value]) => {
    const isShowing = Big(value).lte(0);
    const token = tokens[tokenId] || null;
    if (isShowing || !token) return null;
    const {
      symbol, decimals, icon, name,
    } = token.metadata;
    const formatValue = removeTrailingZeros(
      formatBalance(formatTokenAmount(value, decimals)),
    );
    return (
      <TokenRowContainer key={symbol}>
        <LogoContainer>
          <img src={icon} alt={symbol} />
        </LogoContainer>
        <TokenDescriptionBlock>
          <TokenTitle>
            <div>{symbol}</div>
            <div>{formatValue}</div>
          </TokenTitle>
          <TokenSubtitle>
            <div>{name}</div>
          </TokenSubtitle>
        </TokenDescriptionBlock>
      </TokenRowContainer>
    );
  }), [claimList, tokens]);

  const onClaim = useCallback(async () => {
    if (!canClaim || !tokens) {
      closeModal();
      return;
    }
    const transactions = await poolContract.withdraw({ claimList, tokens });
    if (transactions) {
      await requestSignTransactions(transactions);
      closeModal();
    }
  }, [
    canClaim,
    poolContract,
    claimList,
    closeModal,
    tokens,
    requestSignTransactions,
  ]);

  if (loading) return null;
  return (
    <>
      <Layout onClick={closeModal}>
        <Container>
          <ClaimModal onClick={(e) => e.stopPropagation()}>
            <ModalBlock>
              <ModalTitle>
                Claim
              </ModalTitle>
              <ModalIcon onClick={closeModal}>
                <CloseIcon />
              </ModalIcon>
            </ModalBlock>
            <ClaimModalBlock canClaim={canClaim}>
              {tokensArray && canClaim
                ? claimListArray
                : (
                  <NoClaimBlock>
                    <img src={GifLoading} alt="loading" />
                    <p>{t('noResult.nothingToClaim')}</p>
                  </NoClaimBlock>
                )}
            </ClaimModalBlock>
            <ModalFooter>
              <ButtonPrimary
                onClick={onClaim}
              >
                {canClaim && (
                  <LogoClaim>
                    <ClaimArrow />
                  </LogoClaim>
                )}
                {titleButton}
              </ButtonPrimary>
            </ModalFooter>
          </ClaimModal>
        </Container>
      </Layout>
    </>
  );
};

export default WithdrawDepositModal;
