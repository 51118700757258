import { configureStore } from '@reduxjs/toolkit';
import tokensSlice from './slices/tokens';
import poolsSlice from './slices/pools';
import farmsSlice from './slices/farms';
import balancesSlice from './slices/balances';
import pricesSlice from './slices/prices';
import userRewardsSlice from './slices/userRewards';
import jumboRuntimeSlice from './slices/jumboRuntime';
import stakingSlice from './slices/staking';

const store = configureStore({
  reducer: {
    tokens: tokensSlice,
    pools: poolsSlice,
    farms: farmsSlice,
    balances: balancesSlice,
    prices: pricesSlice,
    userRewards: userRewardsSlice,
    jumboRuntime: jumboRuntimeSlice,
    staking: stakingSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['tokens/setTokens', 'jumboRuntime/setInputToken', 'jumboRuntime/setOutputToken', 'jumboRuntime/setCurrentToken'],
      ignoredPaths: ['tokens.value', 'jumboRuntime.inputToken', 'jumboRuntime.outputToken'],
    },
  }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
