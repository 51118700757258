import { Suspense, lazy } from 'react';
import Footer from 'components/Footer';
import { isMobile } from 'utils/userAgent';
import GifLoading from 'assets/gif/loading.gif';
import { ReactComponent as JumboLogo } from 'assets/images-app/jumbo-logo.svg';

import {
  Route,
  Routes,
  Link,
  NavLink,
  useLocation,
} from 'react-router-dom';
import type { LinkProps } from 'react-router-dom';

import {
  ALL_MATCH,
  LANDING,
  POOL,
  RETRODROP,
  SWAP,
  SWAP_FUTURES,
  STAKING,
} from 'utils/routes';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import Menu from 'components/Menu';

import SwapFutures from 'pages/SwapFutures';
import Retrodrop from 'pages/Retrodrop';
import { useWalletSelector } from 'providers/wallet-provider';
import Staking from 'pages/Staking';
import useTransactionHash from 'hooks/useTransactionHash';
import {
  Container,
  Header,
  LogoContainer,
  LogoTitle,
  NavBar,
  NavButton,
  BlockButton,
  Body,
  LinkContainer,
  LoadingBlock,
} from './styles';
import ConnectionButton from './ConnectionButton';

const Swap = lazy(() => import('pages/Swap'));
const Pool = lazy(() => import('pages/Pool'));
const Error = lazy(() => import('pages/Error'));

function CustomLink({
  children, to,
}: LinkProps) {
  return (
    <NavLink to={to}>
      {({ isActive }) => (
        <NavButton isActive={isActive}>
          {children}
        </NavButton>
      )}
    </NavLink>
  );
}

export default function App() {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { accountId } = useWalletSelector();
  useTransactionHash(search, accountId);

  return (
    <Container>
      <Header>
        <LinkContainer>
          <Link to={LANDING}>
            <LogoContainer>
              <JumboLogo />
              {isMobile ? null : (<LogoTitle>jumbo</LogoTitle>)}
            </LogoContainer>
          </Link>
        </LinkContainer>
        <NavBar>
          <CustomLink to={SWAP}>
            {t('general.swap')}
          </CustomLink>
          <CustomLink to={POOL}>
            {t('general.pool')}
          </CustomLink>
          <CustomLink to={STAKING}>
            {t('general.staking')}
          </CustomLink>
        </NavBar>
        <BlockButton>
          <ConnectionButton />
        </BlockButton>
        <Menu />
      </Header>
      <Body>
        <Suspense fallback={(
          <LoadingBlock isApp>
            <img src={GifLoading} alt={t('common.loading')} />
          </LoadingBlock>
              )}
        >
          <Routes>
            <Route path={SWAP} element={<Swap />} />
            <Route path={SWAP_FUTURES} element={<SwapFutures />} />
            <Route path={RETRODROP} element={<Retrodrop />} />
            <Route path={`${POOL}/*`} element={<Pool />} />
            <Route path={`${STAKING}/*`} element={<Staking />} />
            <Route path={ALL_MATCH} element={<Error />} />
          </Routes>
        </Suspense>
      </Body>
      <Footer />
      <ToastContainer />
    </Container>
  );
}
