import styled from 'styled-components';
import { Modal } from '../styles';

export const NewModal = styled(Modal)`
  max-width: 328px;
  min-width: 300px;
  min-height: 50px;
  flex-direction: row;
  border-radius: 12px;
  :before {
    border-radius: 12px;
  }
`;

export const TooltipBlock = styled.div`
  margin: .75rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  p {
    margin: .25rem .75rem 0 .25rem;
    font-style: normal;
    font-weight: 300;
    font-size: .75rem;
    line-height: 1rem;
  }
  
`;

export const ModalIcon = styled.div`
  display: flex;
  align-self: flex-start;
  svg {
    width: 16px;
    height: 17px;
  }
`;

export const ApyBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: .25rem .75rem 0 .25rem;
  flex-grow: 1;
  font-style: normal;
  font-weight: 300;
  font-size: .75rem;
  line-height: 1rem;
  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;
