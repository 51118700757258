import { providers } from 'near-api-js';
import { t } from 'i18next';
import { config } from 'services/config';
import ToastService from 'components/Toast';
import Big from 'big.js';
import { isNotNullOrUndefined } from 'utils';
import { POOL, STAKING, SWAP } from 'utils/routes';
import { EMPTY_STRING } from 'utils/constants';
import {
  TRANSACTION_HASHES,
  ERROR_CODE,
  ERROR_MESSAGE,
  ASCII,
  BASE64,
  FUNCTION_CALL,
  SUCCESS_VALUE,
  methodsName,
} from './constants';
import { TransactionType, StatusType, IDetailsTransaction } from './interfaces';

export const clearHash = (queryParams: URLSearchParams) => {
  const url = new URL(window.location.href);
  if (queryParams.has(TRANSACTION_HASHES)) queryParams.delete(TRANSACTION_HASHES);
  if (queryParams.has(ERROR_CODE) || queryParams.has(ERROR_MESSAGE)) {
    queryParams.delete(ERROR_CODE);
    queryParams.delete(ERROR_MESSAGE);
  }
  window.history.replaceState({}, document.title, url.pathname);
};

const checkSuccessValue = (status: providers.FinalExecutionStatus): boolean => {
  const successValue = status.SuccessValue || EMPTY_STRING;
  const buff = Buffer.from(successValue, BASE64);
  const value = buff.toString(ASCII);
  return Big(value.replace(/"/g, EMPTY_STRING) || 0).gt(0);
};

const retrieveTypeTransaction = (methodName: string): TransactionType => {
  const path = window.location.pathname.split('/')[1];
  switch (methodName) {
    case methodsName.ft_transfer_call: {
      if (path === SWAP) return TransactionType.Swap;
      if (path === STAKING) return TransactionType.StakeStaking;
      return TransactionType.None;
    }
    case methodsName.add_simple_pool: {
      return TransactionType.CreatePool;
    }
    case methodsName.add_liquidity: {
      return TransactionType.AddLiquidity;
    }
    case methodsName.mft_transfer_call: {
      return TransactionType.StakeFarm;
    }
    case methodsName.near_deposit: {
      return TransactionType.NearDeposit;
    }
    case methodsName.near_withdraw: {
      return TransactionType.NearWithdraw;
    }
    case methodsName.remove_liquidity: {
      return TransactionType.RemoveLiquidity;
    }
    case methodsName.withdraw_seed: {
      if (path === POOL) return TransactionType.UnstakeFarm;
      if (path === STAKING) return TransactionType.UnstakeStaking;
      return TransactionType.None;
    }
    default: {
      return TransactionType.None;
    }
  }
};

const retrieveSuccessStatus = (
  type: TransactionType,
  status: providers.FinalExecutionStatus | providers.FinalExecutionStatusBasic,
): StatusType => {
  const successStatus = Object.prototype.hasOwnProperty.call(status, SUCCESS_VALUE);
  switch (type) {
    case TransactionType.Swap: {
      const depositStatus = checkSuccessValue(status as providers.FinalExecutionStatus);
      return depositStatus && successStatus ? StatusType.SuccessValue : StatusType.Failure;
    }
    default: {
      return successStatus ? StatusType.SuccessValue : StatusType.Failure;
    }
  }
};

const detailsTransaction = (
  transaction: providers.FinalExecutionOutcome,
): IDetailsTransaction | null => {
  const action = transaction.transaction.actions[0];
  const isTrxHasProperty = Object.prototype.hasOwnProperty.call(action, FUNCTION_CALL);
  if (!isTrxHasProperty) return null;
  const methodName: string = action[FUNCTION_CALL].method_name;
  const type = retrieveTypeTransaction(methodName);
  if (type === TransactionType.None) return null;
  const successStatus = retrieveSuccessStatus(type, transaction.status);
  const hash = transaction.transaction.hash as string;
  const firstLowerString = type.charAt(0).toLowerCase() + type.slice(1);
  const title = t(`toast.${firstLowerString}`);

  return {
    methodName,
    href: `${config.explorerUrl}/transactions/${hash}`,
    status: successStatus ? StatusType.SuccessValue : StatusType.Failure,
    type,
    title,
  };
};

export function parseTransactions(txs: providers.FinalExecutionOutcome[]) {
  const suitableTransactions = txs.map((tx) => detailsTransaction(tx)).filter(isNotNullOrUndefined);
  if (!suitableTransactions.length) return;
  suitableTransactions.forEach((transaction) => {
    if (transaction.type === TransactionType.None) return;
    if (transaction.status === StatusType.SuccessValue) {
      ToastService.success({ text: transaction.title, href: transaction.href });
    } else if (transaction.status === StatusType.Failure) {
      ToastService.error({ text: t('toast.somethingWentWrong'), href: transaction.href });
    }
  });
}
