import getConfig from 'services/config';
import {
  FarmOutput, StakingContractViewMethod, SeedInfo, ITransaction, StakingContractMethod,
} from 'services/interfaces';
import RPCProviderService from 'services/RPCProviderService';
import Big from 'big.js';
import {
  DEFAULT_FT_GAS,
  FT_GAS, MIN_DEPOSIT_PER_TOKEN_FARM, ONE_YOCTO_NEAR, STORAGE_TO_REGISTER_MFT, ZERO_AMOUNT,
} from 'utils/constants';
import { parseTokenAmount } from 'utils/calculations';
import FungibleTokenContract, { ACCOUNT_MIN_STORAGE_AMOUNT } from './FungibleToken';

const config = getConfig();

export default class StakingContract {
  readonly contractId = config.stakingContractId;

  private accountId: string;

  private provider: RPCProviderService;

  constructor(provider: RPCProviderService, accountId: string) {
    this.provider = provider;
    this.accountId = accountId;
  }

  async getListFarmsBySeed(seedId: string): Promise<Array<FarmOutput> | undefined> {
    return this.provider.viewFunction(StakingContractViewMethod.listFarmsBySeed, this.contractId, {
      seed_id: seedId,
    });
  }

  async getSeedInfo(seedId: string): Promise<SeedInfo | undefined> {
    return this.provider.viewFunction(StakingContractViewMethod.getSeedInfo, this.contractId, {
      seed_id: seedId,
    });
  }

  async getStakedListByAccountId(
    accountId = this.accountId,
  ): Promise<{ [key: string]: string } | undefined> {
    return this.provider.viewFunction(StakingContractViewMethod.listUserSeeds, this.contractId, {
      account_id: accountId,
    });
  }

  async getUnclaimedReward(
    farmId: string | number, accountId = this.accountId,
  ): Promise<string | undefined> {
    return this.provider.viewFunction(
      StakingContractViewMethod.getUnclaimedReward,
      this.contractId, {
        account_id: accountId, farm_id: farmId,
      },
    );
  }

  async getUserRps(farmId: string, accountId = this.accountId): Promise<string | undefined> {
    return this.provider.viewFunction(
      StakingContractViewMethod.getUserRps,
      this.contractId, {
        account_id: accountId, farm_id: farmId,
      },
    );
  }

  async getRewardByTokenId(
    tokenId: string, accountId = this.accountId,
  ): Promise<string | undefined> {
    return this.provider.viewFunction(StakingContractViewMethod.getReward, this.contractId, {
      account_id: accountId, token_id: tokenId,
    });
  }

  async currentStorageBalance(accountId = this.accountId) {
    return this.provider.viewFunction(StakingContractViewMethod.storageBalanceOf, this.contractId, {
      account_id: accountId,
    });
  }

  async checkFarmStorageBalance(accountId: string = this.accountId) {
    const transactions: ITransaction[] = [];

    let storageAmount = new Big(0);
    const balance = await this.currentStorageBalance(accountId);

    if (!balance) {
      storageAmount = new Big(ACCOUNT_MIN_STORAGE_AMOUNT);
    }

    if (new Big(balance?.available || ZERO_AMOUNT).lt(MIN_DEPOSIT_PER_TOKEN_FARM)) {
      storageAmount = storageAmount.plus(STORAGE_TO_REGISTER_MFT);
    }

    if (storageAmount.gt(0)) {
      transactions.push({
        receiverId: this.contractId,
        functionCalls: [{
          methodName: StakingContractMethod.storageDeposit,
          args: {
            registration_only: false,
            account_id: accountId,
          },
          amount: storageAmount.toFixed(),
        }],
      });
    }
    return transactions;
  }

  async stake(
    token: FungibleTokenContract,
    amount: string,
  ) {
    const transactions: ITransaction[] = [];
    const checkStorage = await this.checkFarmStorageBalance();
    transactions.push(...checkStorage);
    const transfer = await token.transfer({
      inputToken: token.contractId,
      amount: parseTokenAmount(amount, token.metadata.decimals),
      receiverId: this.contractId,
      accountId: this.contractId,
    });
    transactions.push(...transfer);
    return transactions;
  }

  claimRewardBySeed(seedId: string): ITransaction {
    return {
      receiverId: this.contractId,
      functionCalls: [{
        methodName: StakingContractMethod.claimRewardBySeed,
        args: { seed_id: seedId },
        gas: FT_GAS,
      }],
    };
  }

  async withdrawReward(
    token: FungibleTokenContract,
    value: string,
  ) {
    const transactions: ITransaction[] = [];
    const storageDeposits = await token.checkSwapStorageBalance({ accountId: this.accountId });
    if (storageDeposits.length) transactions.push(...storageDeposits);
    transactions.push({
      receiverId: this.contractId,
      functionCalls: [{
        methodName: StakingContractMethod.withdrawReward,
        args: {
          token_id: token.contractId,
          amount: value,
        },
        gas: DEFAULT_FT_GAS,
        amount: ONE_YOCTO_NEAR,
      }],
    });

    return transactions;
  }

  unstake(
    decimals: number,
    amount: string,
  ): ITransaction {
    return {
      receiverId: this.contractId,
      functionCalls: [{
        methodName: StakingContractMethod.withdrawSeed,
        args: {
          seed_id: config.stakingSeedId,
          amount: parseTokenAmount(amount, decimals),
        },
        amount: ONE_YOCTO_NEAR,
        gas: FT_GAS,
      }],
    };
  }
}
