import {
  useEffect, useMemo,
} from 'react';
import {
  toArray,
  getPoolsPath,
} from 'utils';

import getConfig from 'services/config';
import PoolContract from 'services/contracts/PoolContract';
import {
  NEAR_TOKEN_ID,
  SWAP_INPUT_KEY,
  SWAP_OUTPUT_KEY,
  URL_INPUT_TOKEN,
  URL_OUTPUT_TOKEN,
} from 'utils/constants';
import FarmContract from 'services/contracts/FarmContract';
import { useAppDispatch, useAppSelector } from 'hooks/redux-hooks';
import StakingContract from 'services/contracts/StakingContract';
import {
  JUMBO_INITIAL_DATA,
  NEAR_INITIAL_DATA,
  tryTokenByKey,
} from './helpers';
import { useWalletSelector } from '../providers/wallet-provider';
import {
  selectTokens,
  selectPools,
} from './slices';
import { initialLoading } from './actions/jumboRuntimeInit';
import {
  selectJumboRuntime,
  setCurrentPools, setInputToken, setOutputToken,
} from './slices/jumboRuntime';

const config = getConfig();

export const pricesInitialState = {
  [config.nearAddress]: NEAR_INITIAL_DATA,
  [config.jumboAddress]: JUMBO_INITIAL_DATA,
};

export const DataFetchHOC = (
  { children }:{ children: JSX.Element },
) => {
  const {
    accountId, isSignedIn, RPCProvider, lastTransaction,
  } = useWalletSelector();

  const tokens = useAppSelector(selectTokens);
  const pools = useAppSelector(selectPools);

  const {
    inputToken, outputToken,
  } = useAppSelector(selectJumboRuntime);

  const dispatch = useAppDispatch();

  const poolContract = useMemo(
    () => new PoolContract(RPCProvider, accountId),
    [RPCProvider, accountId],
  );
  const farmContract = useMemo(
    () => new FarmContract(RPCProvider, accountId),
    [RPCProvider, accountId],
  );
  const stakingContract = useMemo(
    () => new StakingContract(RPCProvider, accountId),
    [RPCProvider, accountId],
  );

  useEffect(() => {
    const isSigned = isSignedIn();
    dispatch(initialLoading({
      poolContract,
      farmContract,
      stakingContract,
      RPCProvider,
      accountId,
      isSigned,
    }));
  }, [
    poolContract,
    farmContract,
    stakingContract,
    RPCProvider,
    accountId,
    isSignedIn,
    lastTransaction,
    dispatch,
  ]);

  // Effect for handling initial loading and update after sign transactions through near wallet
  useEffect(() => {
    if (
      (inputToken?.contractId && outputToken?.contractId)
      || !(toArray(pools).length && toArray(tokens).length)
    ) return;

    const inputTokenData = tryTokenByKey(
      tokens, NEAR_TOKEN_ID, SWAP_INPUT_KEY, URL_INPUT_TOKEN,
    );
    const outputTokenData = tryTokenByKey(
      tokens, config.nearAddress, SWAP_OUTPUT_KEY, URL_OUTPUT_TOKEN,
    );
    if (!inputTokenData || !outputTokenData) {
      dispatch(setInputToken(null));
      dispatch(setOutputToken(null));
      return;
    }

    dispatch(setInputToken(inputTokenData));
    dispatch(setOutputToken(outputTokenData));

    const availablePools = getPoolsPath(
      inputTokenData.contractId,
      outputTokenData.contractId,
      toArray(pools),
      tokens,
    );
    dispatch(setCurrentPools(availablePools));
  }, [tokens, pools, dispatch, inputToken?.contractId, outputToken?.contractId]);

  return (
    <>
      {children}
    </>
  );
};
