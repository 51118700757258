import FungibleTokenContract from 'services/contracts/FungibleToken';
import { IPool } from '../store/interfaces';

export interface IBaseModal {
  closeModal: () => void;
}

export interface IAddLiquidityModal extends IBaseModal {
  pool: IPool | null
}

export interface ISearchModal extends IBaseModal {
  activeToken: FungibleTokenContract | null,
  setActiveToken: (activeToken: FungibleTokenContract) => void,
  closeAfterSelect?: boolean,
}

export interface IRemoveLiquidityModal extends IBaseModal {
  pool: IPool | null
}

export interface IStakeModal extends IBaseModal {
  pool: IPool | null
}

export interface IUnstakeModal extends IBaseModal {
  pool: IPool | null
}

export interface ITooltipModal extends IBaseModal {
  title: string | any
}

export interface ICreatePoolModal extends IBaseModal {
  fee?: string,
  inputToken?: FungibleTokenContract | null,
  outputToken?: FungibleTokenContract | null,
}

export enum ModalEnum {
  Search = 'Search',
  Account = 'Account',
  CreatePool = 'CreatePool',
  AddLiquidity = 'AddLiquidity',
  RemoveLiquidity = 'RemoveLiquidity',
  Stake = 'Stake',
  Unstake = 'Unstake',
}
