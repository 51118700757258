import { ONE_SECOND, TIMESTAMP } from './constants';

interface IParseDate {
  year: string,
  month: string,
  day: string,
  hours: string,
  minutes: string,
  seconds: string,
}

const formatDate = (number:number) => (number > 9 ? number.toString() : `0${number}`);

export const secondsToMilliseconds = (date: number): number => date * ONE_SECOND;
export const millisecondsToSeconds = (date: number): number => date / ONE_SECOND;

export const formatCountdown = (diffInMilliSeconds: number) => {
  const diffInSeconds = millisecondsToSeconds(diffInMilliSeconds);
  const days = Math.floor(diffInSeconds / TIMESTAMP.ONE_DAY);
  const hours = Math.floor((diffInSeconds - days * TIMESTAMP.ONE_DAY) / TIMESTAMP.ONE_HOUR);
  const minutes = Math.floor(
    (diffInSeconds - days * TIMESTAMP.ONE_DAY - hours * TIMESTAMP.ONE_HOUR) / TIMESTAMP.ONE_MINUTE,
  );
  const seconds = Math.floor(diffInSeconds - days * TIMESTAMP.ONE_DAY - hours
    * TIMESTAMP.ONE_HOUR - minutes * TIMESTAMP.ONE_MINUTE);
  return {
    days: formatDate(days),
    hours: formatDate(hours),
    minutes: formatDate(minutes),
    seconds: formatDate(seconds),
  };
};

export function getParseDate(date: number | string): IParseDate {
  const newDate = new Date(date);
  const dateObj = {
    year: newDate.getUTCFullYear().toString(),
    month: formatDate(newDate.getUTCMonth() + 1),
    day: formatDate(newDate.getUTCDate()),
    hours: formatDate(newDate.getUTCHours()),
    minutes: formatDate(newDate.getUTCMinutes()),
    seconds: formatDate(newDate.getUTCSeconds()),
  };
  return dateObj;
}

export const parseStaticDate = (date: number) => {
  const dateObj = getParseDate(date);
  const staticDate = `${dateObj.year}-${dateObj.month}-${dateObj.day} ${dateObj.hours}:${dateObj.minutes}:${dateObj.seconds}`;
  return staticDate;
};

export const parseDynamicDate = (date: number) => {
  const dynamicCounter = formatCountdown(date);
  const dynamicDate = `${dynamicCounter.days} days ${dynamicCounter.hours}: ${dynamicCounter.minutes} : ${dynamicCounter.seconds}`;
  return dynamicDate;
};
