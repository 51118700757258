import styled from 'styled-components';

export const Title = styled.h1`
  font-family: Rubik;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0em;
  text-align: center;
  margin-block-start: 1rem;
  margin-block-end: 1rem;
`;

export const Subtitle = styled.h3`
  font-family: Rubik;
  font-size: 0.875rem;
  font-weight: 300;
  color: ${({ theme }) => theme.globalGrey};
  line-height: 1.25rem;
  letter-spacing: 0em;
  text-align: center;
  margin: 0;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  max-width: 736px;
  width: 100%;
  margin-bottom: 7.5rem;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    max-width: 328px;
    margin-bottom: 14rem;
  `}

`;

export const DescriptionTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 4rem;
`;

export const DescriptionSubtitle = styled.p`
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.375rem;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ theme }) => theme.globalGrey};
`;

export const UpperRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const QuestionTitle = styled.h2`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
`;

export const LowerRow = styled.div<{isOpen:boolean}>`
  z-index: 2;
  transition: max-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition-duration: 240ms;
  max-height:  ${({ isOpen }) => (isOpen ? '100px' : '0px')};
  overflow: hidden;
  ${({ isOpen }) => !isOpen && 'visibility: hidden'};
`;

export const LowerRowContainer = styled.div`
  background-color: ${({ theme }) => theme.backgroundCard};
  z-index: 6;
    >h3{
      font-size: 0.875rem;
      font-weight: 300;
      line-height: 1.25rem;
      letter-spacing: 0em;
      text-align: left;
      margin: 0;
    }
`;

export const QuestionBlockWrapper = styled.div<{isOpen: boolean}>`
  background-color: ${({ isOpen, theme }) => (isOpen ? theme.backgroundCard : theme.backgroundCard)};
  width: 100%;
  border-radius: 12px;
  padding: 14px 16px;
  margin-bottom: .5rem;
  cursor: pointer;
`;
