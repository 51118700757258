import React, { useMemo, useState } from 'react';
import {
  CurrentButton,
} from 'store';
import { ReactComponent as Close } from 'assets/images-app/close.svg';
import FarmContract from 'services/contracts/FarmContract';
import RenderButton from 'components/Button/RenderButton';

import { useTranslation } from 'react-i18next';

import { formatTokenAmount } from 'utils/calculations';
import Big from 'big.js';
import { INITIAL_INPUT_PLACEHOLDER } from 'utils/constants';
import TokenPairDisplay from 'components/TokensDisplay/TokenPairDisplay';
import InputSharesContainer from 'components/CurrencyInputPanel/InputSharesContainer';
import { IUnstakeModal } from 'providers/modals.interfaces';
import { useWalletSelector } from 'providers/wallet-provider';
import { useAppSelector } from 'hooks/redux-hooks';
import { selectFarms } from 'store/slices';
import {
  Layout, ModalBlock, ModalIcon, ModalTitle,
} from '../styles';
import {
  UnstakeModalContainer,
  ModalBody,
  TokensBlock,
} from './styles';

const UnstakeModal: React.FC<IUnstakeModal> = ({ closeModal, pool }) => {
  const farms = useAppSelector(selectFarms);

  const { t } = useTranslation();
  const { RPCProvider, accountId, requestSignTransactions } = useWalletSelector();
  const farmContract = useMemo(
    () => new FarmContract(RPCProvider, accountId),
    [RPCProvider, accountId],
  );

  const [unstakeValue, setUnstakeValue] = useState<string>(INITIAL_INPUT_PLACEHOLDER);

  if (!pool || !pool.farms?.length) return null;
  const [farmsInPool] = pool.farms.map((el) => farms[el]);
  if (!farmsInPool) return null;

  const { userStaked, seedId } = farmsInPool;
  const formattedFarmShares = formatTokenAmount(userStaked ?? '0', pool.lpTokenDecimals);

  const buttonDisabled = unstakeValue
    ? (new Big(unstakeValue).lte(0)
  || new Big(unstakeValue).gt(formattedFarmShares))
    : true;

  const onSubmit = async () => {
    if (
      Big(unstakeValue).eq(0)
      || Big(unstakeValue).gt(formattedFarmShares)
      || !pool
    ) return;

    if (!pool) return;
    const transactions = await farmContract.unstake(
      seedId,
      unstakeValue,
      pool,
    );

    await requestSignTransactions(transactions);
    closeModal();
  };

  return (
    <>
      <Layout onClick={closeModal}>
        <UnstakeModalContainer onClick={(e) => e.stopPropagation()}>
          <ModalBlock>
            <ModalTitle>
              {t('unstakeModal.unstake')}
            </ModalTitle>
            <ModalIcon onClick={closeModal}>
              <Close />
            </ModalIcon>
          </ModalBlock>
          <ModalBody>
            <TokensBlock>
              <TokenPairDisplay pool={pool} />
            </TokensBlock>
            <InputSharesContainer
              shares={formattedFarmShares}
              value={unstakeValue}
              setValue={setUnstakeValue}
              isShowingButtonHalf={false}
            />
            <RenderButton
              typeButton={CurrentButton.Unstake}
              onSubmit={onSubmit}
              disabled={buttonDisabled}
              isFullHeight
            />
          </ModalBody>
        </UnstakeModalContainer>
      </Layout>
    </>
  );
};

export default UnstakeModal;
