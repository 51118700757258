import getConfig from 'services/config';
import FungibleTokenContract from 'services/contracts/FungibleToken';
import PoolContract from 'services/contracts/PoolContract';
import FarmContract from 'services/contracts/FarmContract';
import { calculatePriceForToken, isNotNullOrUndefined, toArray } from 'utils';
import { formatTokenAmount } from 'utils/calculations';
import { NEAR_TOKEN_ID, SECONDS_IN_A_DAY, ZERO_AMOUNT } from 'utils/constants';
import ApiService from 'services/helpers/apiService';
import RPCProviderService from 'services/RPCProviderService';
import { SeedInfo } from 'services/interfaces';
import Big from 'big.js';
import StakingContract from 'services/contracts/StakingContract';
import { FarmStatusEnum } from 'components/FarmStatus';
import {
  IFormattedStaking, IPool, IStaking, ITokenPrice, IUserStakingData,
} from './interfaces';

const config = getConfig();
const url = new URL(window.location.href);

export const DEFAULT_PAGE_LIMIT = 100;
export const JUMBO_INITIAL_DATA = {
  id: config.nearAddress,
  decimal: 18,
  symbol: 'JUMBO',
  price: '0',
};

export const NEAR_INITIAL_DATA = {
  id: config.nearAddress,
  decimal: 24,
  symbol: 'near',
  price: '0',
};

export function assertFulfilled<T>(
  item: PromiseSettledResult<T>,
): item is PromiseFulfilledResult<T> {
  return item.status === 'fulfilled';
}

export function validateTokens<T extends Object>(
  obj: any,
): obj is T {
  if (obj.token_account_ids) {
    return !config.blacklistedTokens.some((tokenId) => obj.token_account_ids.includes(tokenId));
  }
  return !config.blacklistedTokens.includes(obj.rewardTokenId);
}

export async function retrievePoolResult(pages: number, contract: PoolContract) {
  return (await Promise.allSettled(
    [...Array(pages)]
      .map((_, i) => contract.getPools(i * DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_LIMIT)),
  )).filter(assertFulfilled)
    .map(({ value }) => value)
    .flat()
    .filter(validateTokens);
}

export async function retrieveFarmsResult(farmsPages: number, farmContract: FarmContract) {
  return (await Promise.allSettled(
    [...Array(farmsPages)]
      .map((_, i) => farmContract.getListFarms(
        i * DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_LIMIT,
      )),
  )).filter(assertFulfilled)
    .map(({ value }) => value)
    .flat()
    .filter(validateTokens);
}

export function retrieveTokenAddresses(poolsResult: any, userTokens: any): string[] {
  return Array.from(
    new Set(
      [...poolsResult
        .flatMap((pool: any) => pool.token_account_ids),
      NEAR_TOKEN_ID,
      config.nearAddress,
      ...userTokens,
      ],
    ),
  );
}

export async function getPrices(): Promise<{[key: string]: ITokenPrice}> {
  const allPrices = await ApiService.getPriceData();
  const nearPrice = await ApiService.getNearPrice();

  if (nearPrice) {
    return {
      ...allPrices,
      [config.nearAddress]: { ...allPrices[config.nearAddress], price: nearPrice },
    };
  }
  return allPrices;
}

export async function retrieveFilteredTokenMetadata(
  tokenAddresses: string[],
  provider: RPCProviderService,
  accountId: string,
): Promise<FungibleTokenContract[]> {
  const tokensMetadata: (FungibleTokenContract | null)[] = await Promise.all(
    tokenAddresses.map(async (address: string) => {
      const ftTokenContract: FungibleTokenContract = new FungibleTokenContract(
        { provider, contractId: address, accountId },
      );
      const metadata = await ftTokenContract.getMetadata();
      if (!metadata) return null;
      return ftTokenContract;
    }),
  );
  const tokensMetadataFiltered = tokensMetadata.filter(isNotNullOrUndefined);
  return tokensMetadataFiltered;
}

export async function retrieveBalancesMap(
  tokensMetadataFiltered: FungibleTokenContract[],
  accountId: string,
): Promise<{ [key: string]: string; }> {
  const balancesArray: {contractId: string, balance: string}[] = await Promise.all(
    tokensMetadataFiltered.map(async (tokenContract: FungibleTokenContract) => {
      const balance: string = await tokenContract.getBalanceOf({ accountId }) || 0;
      return { contractId: tokenContract.contractId, balance };
    }),
  );

  return balancesArray.reduce((acc, curr) => (
    { ...acc, [curr.contractId]: curr.balance }
  ), {});
}

export async function retrieveNewPoolArray(
  poolArray: IPool[],
  poolContract: PoolContract,
): Promise<IPool[]> {
  return Promise.all(poolArray.map(async (pool: IPool) => {
    const [volumes, shares] = await Promise.all([
      poolContract.getPoolVolumes(pool),
      poolContract.getSharesInPool(pool.id),
    ]);

    return {
      ...pool,
      volumes,
      shares,
    };
  }));
}

export function retrievePricesData(
  pricesData: {[key: string]: ITokenPrice},
  newPoolMap: {[key: string]: IPool},
  metadataMap: {[key: string]: FungibleTokenContract},
): {[key: string]: ITokenPrice} {
  const jumboPool = newPoolMap[config.jumboPoolId];
  const [firstToken, secondToken] = jumboPool.tokenAccountIds;
  const [wrapNear, jumboToken] = firstToken === config.nearAddress
    ? [firstToken, secondToken] : [secondToken, firstToken];
  const nearPrice = pricesData[config.nearAddress].price || '0';
  const firstDecimals = metadataMap[wrapNear]?.metadata.decimals;
  const secondDecimals = metadataMap[jumboToken]?.metadata.decimals;

  const firstAmount = formatTokenAmount(
    jumboPool.supplies[wrapNear], firstDecimals,
  );
  const secondAmount = formatTokenAmount(
    jumboPool.supplies[jumboToken], secondDecimals,
  );
  const newJumboPrice = calculatePriceForToken(firstAmount, secondAmount, nearPrice);
  return {
    ...pricesData,
    [config.jumboAddress]: {
      ...JUMBO_INITIAL_DATA, price: newJumboPrice,
    },
  };
}

export const findTokenBySymbol = (
  symbol: string,
  tokens: {[key: string]: FungibleTokenContract},
) => toArray(tokens)
  .find((el) => el.metadata.symbol.toLowerCase() === symbol.toLowerCase());

export const tryTokenByKey = (
  tokensMap: { [key: string]: FungibleTokenContract},
  tokenId: string,
  localStorageKey: string,
  urlKey: string,
) => {
  const urlToken = url.searchParams.get(urlKey) || '';
  const tokenFromMap = findTokenBySymbol(urlToken, tokensMap);
  if (
    url.searchParams.has(urlKey) && tokenFromMap
  ) return tokensMap[tokenFromMap.contractId];

  const key = localStorage.getItem(localStorageKey) || '';
  if (key && tokensMap[key]) return tokensMap[key];
  return tokensMap[tokenId];
};

export const getToken = (
  tokenId: string,
  tokens: {[key: string]: FungibleTokenContract},
): FungibleTokenContract | null => (tokenId ? tokens[tokenId] ?? null : null);

export const retrieveStakingData = (
  listStakingArray: IFormattedStaking[],
  seedInfo: SeedInfo | undefined,
): IStaking => {
  const firstStaking = listStakingArray[0];

  const data = listStakingArray.reduce((acc, staking) => {
    if (staking.startAt < acc.startAt) acc.startAt = staking.startAt;
    if (staking.endAt > acc.endAt) acc.endAt = staking.endAt;
    if (staking.status !== FarmStatusEnum.Active) return acc;
    acc.rewardPerSecond = Big(acc.rewardPerSecond).plus(staking.rewardPerSecond).toFixed();
    acc.totalReward = Big(acc.totalReward).plus(staking.totalReward).toFixed();
    acc.apy = Big(acc.apy).plus(staking.apy).toFixed();
    return acc;
  }, {
    startAt: firstStaking.startAt,
    endAt: firstStaking.endAt,
    rewardPerSecond: ZERO_AMOUNT,
    totalReward: ZERO_AMOUNT,
    apy: ZERO_AMOUNT,
  });
  const {
    startAt,
    endAt,
    rewardPerSecond,
    apy,
  } = data;
  const totalStaked = seedInfo?.amount || ZERO_AMOUNT;

  return {
    rewardTokenId: firstStaking.rewardTokenId,
    startAt,
    endAt,
    rewardPerSecond,
    apy,
    totalStaked,
    minStaked: seedInfo?.min_deposit || ZERO_AMOUNT,
  };
};

export async function retrieveUserDataByStaking(
  stakingContract: StakingContract,
  listStakingArray: IFormattedStaking[],
  stakingSeedInfo: SeedInfo | undefined,
): Promise<IUserStakingData> {
  const { rewardTokenId } = listStakingArray[0];

  const [stakedListByUser, claimedReward, ...retrieveAdditionalData] = await Promise.all([
    stakingContract.getStakedListByAccountId(),
    stakingContract.getRewardByTokenId(rewardTokenId),
    ...listStakingArray.map(async (staking) => {
      const retrievedUnclaimedReward = await stakingContract.getUnclaimedReward(staking.stakeId);
      return { ...staking, retrievedUnclaimedReward };
    })]);

  const additionalData = retrieveAdditionalData.reduce((acc, staking) => {
    acc.userUnclaimedReward = Big(acc.userUnclaimedReward)
      .plus(staking.retrievedUnclaimedReward || ZERO_AMOUNT)
      .toFixed();
    acc.sumRewardPerSecond = Big(acc.sumRewardPerSecond).plus(staking.rewardPerSecond).toFixed();
    return acc;
  }, {
    userUnclaimedReward: ZERO_AMOUNT,
    sumRewardPerSecond: ZERO_AMOUNT,
  });

  const totalStaked = stakingSeedInfo?.amount || ZERO_AMOUNT;
  const userStaked = stakedListByUser?.[config.stakingSeedId] || ZERO_AMOUNT;
  const rewardPerSecond = userStaked === ZERO_AMOUNT || totalStaked === ZERO_AMOUNT
    ? ZERO_AMOUNT
    : Big(userStaked).times(additionalData.sumRewardPerSecond).div(totalStaked).toFixed();
  return {
    userUnclaimedReward: additionalData.userUnclaimedReward,
    yourStaked: userStaked,
    rewardPerSecond,
    claimedReward: claimedReward || ZERO_AMOUNT,
  };
}
