import RenderButton from 'components/Button/RenderButton';
import SwapInputPanel from 'components/SwapInputPanel';
import { useAppSelector } from 'hooks/redux-hooks';
import { t } from 'i18next';
import { useState, useMemo, useEffect } from 'react';
import { CurrentButton, TokenType } from 'store';
import { getToken } from 'store/helpers';
import { selectTokens, selectBalances } from 'store/slices';
import { selectStaking } from 'store/slices/staking';
import { EMPTY_STRING, ZERO_AMOUNT } from 'utils/constants';
import StakingContract from 'services/contracts/StakingContract';
import { useWalletSelector } from 'providers/wallet-provider';
import Big from 'big.js';
import { formatTokenAmount } from 'utils/calculations';

import styles from './styles';
import { getCurrentActionData } from '../utils';
import { EStakeType } from '../constants';

export default function ActionBlock({ type }: { type: EStakeType }) {
  const isStake = type === EStakeType.Stake;
  const [value, setValue] = useState<string>(EMPTY_STRING);
  const { data, user } = useAppSelector(selectStaking);
  const tokens = useAppSelector(selectTokens);
  const balances = useAppSelector(selectBalances);
  const token = useMemo(() => getToken(data.rewardTokenId, tokens), [data.rewardTokenId, tokens]);
  const {
    accountId, RPCProvider, isSignedIn, requestSignTransactions,
  } = useWalletSelector();
  const stakingContract = useMemo(
    () => new StakingContract(RPCProvider, accountId),
    [RPCProvider, accountId],
  );
  useEffect(() => {
    setValue(EMPTY_STRING);
  }, [type]);

  if (!token) return null;

  const stakeAction = async () => {
    const transactions = await stakingContract.stake(token, value);
    await requestSignTransactions(transactions);
  };
  const unstakeAction = async () => {
    const transaction = stakingContract.unstake(token.metadata.decimals, value);
    await requestSignTransactions([transaction]);
  };

  const {
    prefix, balance, button, handler, amountPerMonth,
  } = getCurrentActionData(
    type,
    value,
    token,
    balances,
    stakeAction,
    unstakeAction,
    data,
    user,
  );

  const readableBalance = formatTokenAmount(balance, token.metadata.decimals);
  const disabledButton = !isSignedIn()
    || !value
    || Big(value).gt(readableBalance)
    || Big(value).eq(ZERO_AMOUNT);
  return (
    <styles.Container>
      <SwapInputPanel
        token={token}
        tokenType={TokenType.Input}
        value={value}
        setValue={setValue}
        balance={balance}
        needOpenSearchModal={false}
        iconLeft={isStake ? <styles.LogoWallet /> : undefined}
      />
      <styles.MonthReward>
        {isSignedIn() && t('staking.getRewardPerMonth', { prefix, amount: amountPerMonth, symbol: token.metadata.symbol })}
      </styles.MonthReward>
      <RenderButton
        reversed={button === CurrentButton.Stake}
        typeButton={button}
        onSubmit={handler}
        disabled={disabledButton}
        isFullHeight
      />
    </styles.Container>
  );
}
