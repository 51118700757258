import {
  Wrapper,
  UpperRow,
  LowerRow,
  BlockTitle,
  LogoPool,
  TitlePool,
  BlockVolume,
  Column,
  TitleVolume,
  LabelVolume,
  Button,
} from './styles';

const PLACEHOLDERS_NUMBER = 3;
const numberVolume = Array.from(Array(PLACEHOLDERS_NUMBER).keys());

export default function PoolCardPlaceholder({ isFarming = false }: {isFarming?: boolean}) {
  return (
    <Wrapper isFarming={isFarming}>
      <UpperRow>
        <BlockTitle>
          <LogoPool>
            <div />
            <div />
          </LogoPool>
          <TitlePool />
        </BlockTitle>
      </UpperRow>
      <LowerRow>
        <BlockVolume>
          {numberVolume.map((el) => (
            <Column key={el}>
              <TitleVolume />
              <LabelVolume />
            </Column>
          ))}
        </BlockVolume>
        <Button />
      </LowerRow>
    </Wrapper>
  );
}
