import styled from 'styled-components';

import { ReactComponent as LogoWallet } from 'assets/images-app/wallet.svg';

const Container = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
`;

const MonthReward = styled.p`
  margin-top: 1rem;
  margin-bottom: 2.25rem;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 0.875rem;
  text-align: center;
  color: ${({ theme }) => theme.globalGrey};
`;

export default {
  Container,
  MonthReward,
  LogoWallet,
};
