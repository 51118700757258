import { t } from 'i18next';
import stakingStyles from 'pages/Staking/styles';
import { DASH, EMPTY_STRING, ZERO } from 'utils/constants';
import Tooltip from 'components/Tooltip';
import { ReactComponent as TooltipIcon } from 'assets/images-app/tooltip-type-2.svg';
import { ReactComponent as LogoWallet } from 'assets/images-app/wallet.svg';
import { colors } from 'theme';
import styles from './styles';

const stakedInfo = [
  {
    title: t('staking.totalStaked'),
    value: DASH,
  },
  {
    title: t('staking.apy.title'),
    value: t('staking.apy.value', { apy: ZERO }),
    tooltip: t('tooltipTitle.APY'),
    color: colors.greenText,
  },
  {
    title: t('staking.yourStake'),
    value: t('token', { amount: DASH, symbol: EMPTY_STRING }),
  },
];

export default function StakingCardPlaceholder() {
  return (
    <styles.Container>
      <styles.TitleDescription>
        <styles.Title />
        <styles.Description>
          <div />
          <div />
        </styles.Description>
      </styles.TitleDescription>
      <styles.Controls>
        <div />
        <div />
      </styles.Controls>
      <stakingStyles.CardWrapper>
        <stakingStyles.Row sideIndents>
          {stakedInfo.map(({
            title, value, color, tooltip,
          }) => (
            <div key={title}>
              <stakingStyles.ElementTitle>
                {title}
                {tooltip && (
                  <Tooltip title={tooltip}>
                    <TooltipIcon />
                  </Tooltip>
                )}
              </stakingStyles.ElementTitle>
              <stakingStyles.ElementValue color={color} smallFont>
                {value}
              </stakingStyles.ElementValue>
            </div>
          ))}
        </stakingStyles.Row>
        <styles.Reward />
        <div>
          <styles.InputLabel>
            <styles.LabelWallet>
              <LogoWallet />
              {DASH}
            </styles.LabelWallet>
            <styles.LabelControls />
          </styles.InputLabel>
          <styles.Input>
            <styles.InputIcon />
            <styles.InputCurrency />
          </styles.Input>
          <styles.InputDescription />
        </div>
        <styles.Button />
      </stakingStyles.CardWrapper>
    </styles.Container>
  );
}
