import React, { useEffect, useState, useMemo } from 'react';
import Big from 'big.js';
import PoolContract from 'services/contracts/PoolContract';
import RenderButton from 'components/Button/RenderButton';
import {
  CurrentButton,
} from 'store';
import { ReactComponent as Close } from 'assets/images-app/close.svg';
import { useTranslation } from 'react-i18next';
import {
  MIN_FEE_CREATE_POOL,
  MAX_FEE_CREATE_POOL,
  TOTAL_FEE_DEFAULT,
  NEAR_TOKEN_ID,
} from 'utils/constants';
import FungibleTokenContract from 'services/contracts/FungibleToken';
import getConfig from 'services/config';
import { getToken } from 'store/helpers';
import { useWalletSelector } from 'providers/wallet-provider';
import { ICreatePoolModal } from 'providers/modals.interfaces';
import { EModals, useModalContext } from 'providers/modals-provider';
import { useAppSelector } from 'hooks/redux-hooks';
import { selectTokens } from 'store/slices';
import {
  Layout, ModalBlock, ModalIcon, ModalTitle,
} from '../styles';
import {
  LiquidityModalContainer,
  ModalBody,
} from './styles';
import TokenBlock from './TokenBlock';
import CreatePoolSettings from './CreatePoolSetting';

const config = getConfig();

interface ICreatePoolState {
  fee: string,
  inputToken: FungibleTokenContract | null,
  outputToken: FungibleTokenContract | null,
}

const initialModalState: ICreatePoolState = {
  fee: TOTAL_FEE_DEFAULT,
  inputToken: null,
  outputToken: null,
};

const CreatePoolModal: React.FC<ICreatePoolModal> = ({ closeModal, ...stateFromProps }) => {
  const { showModal } = useModalContext();
  const {
    RPCProvider, accountId, isSignedIn, requestSignTransactions,
  } = useWalletSelector();
  const isConnected = isSignedIn();
  const tokens = useAppSelector(selectTokens);
  const { t } = useTranslation();

  const [{ fee, inputToken, outputToken }, setState] = useState<ICreatePoolState>({
    ...initialModalState,
    ...stateFromProps,
  });

  const near = useMemo(() => getToken(NEAR_TOKEN_ID, tokens), [tokens]);
  const jumbo = useMemo(() => getToken(config.jumboAddress, tokens), [tokens]);
  const wNear = useMemo(() => getToken(config.nearAddress, tokens), [tokens]);

  useEffect(() => {
    if (!jumbo || !wNear) return;
    setState((state) => ({
      ...state,
      inputToken: inputToken ?? jumbo,
      outputToken: outputToken ?? wNear,
    }));
  }, [jumbo, wNear, inputToken, outputToken]);

  const canCreatePool = isConnected
  && !!fee
  && new Big(fee).gt(MIN_FEE_CREATE_POOL)
  && new Big(fee).lt(MAX_FEE_CREATE_POOL)
  && !!inputToken
  && !!outputToken
  && inputToken !== near
  && outputToken !== near
  && inputToken !== outputToken;

  const createPool = async () => {
    if (!inputToken || !outputToken) return;
    const poolContract = new PoolContract(RPCProvider, accountId);
    const transactions = await poolContract.createPool(
      { tokens: [inputToken, outputToken], fee },
    );

    await requestSignTransactions(transactions);
    closeModal();
  };

  const setInputToken = (token: FungibleTokenContract | null) => {
    showModal(EModals.CREATE_POOL_MODAL, { fee, inputToken: token, outputToken });
  };

  const setOutputToken = (token: FungibleTokenContract | null) => {
    showModal(EModals.CREATE_POOL_MODAL, { fee, inputToken, outputToken: token });
  };

  const setFee = (newFee: string) => {
    setState((state) => ({ ...state, fee: newFee }));
  };

  return (
    <>
      <Layout onClick={closeModal}>
        <LiquidityModalContainer onClick={(e) => e.stopPropagation()}>
          <ModalBlock>
            <ModalTitle>
              {t('createPoolModal.createPool')}
            </ModalTitle>
            <ModalIcon onClick={closeModal}>
              <Close />
            </ModalIcon>
          </ModalBlock>
          <ModalBody>
            <TokenBlock
              token={inputToken}
              setToken={setInputToken}
              closeAfterSelect={false}
            />
            <TokenBlock
              token={outputToken}
              setToken={setOutputToken}
              closeAfterSelect={false}
            />
            <CreatePoolSettings
              fee={fee}
              setFee={setFee}
            />
            <RenderButton
              typeButton={CurrentButton.CreatePool}
              onSubmit={createPool}
              disabled={!canCreatePool}
              isFullHeight
            />
          </ModalBody>
        </LiquidityModalContainer>
      </Layout>
    </>
  );
};

export default CreatePoolModal;
