import { RetrodropCardPlaceholder } from 'components/Placeholders';
import { useAppSelector } from 'hooks/redux-hooks';
import { t } from 'i18next';
import { useState, useEffect, useMemo } from 'react';
import ClaimingContract from 'services/contracts/ClaimingContract';
import { selectTokens } from 'store/slices';
import { useWalletSelector } from 'providers/wallet-provider';

import RetrodropCard from './Card';
import { Arrow } from './Card/styles';
import { IRetrodropPool, questionsArray } from './constants';
import {
  Title,
  Subtitle,
  Wrapper,
  DescriptionTitle,
  DescriptionSubtitle,
  UpperRow,
  QuestionTitle,
  LowerRow,
  QuestionBlockWrapper,
  LowerRowContainer,
} from './styles';

export const QuestionBlock = (
  { questions }: {questions: { title:string, subtitle:string }[]},
) => {
  const [questionIndex, setQuestionIndex] = useState<number>(0);

  return (
    <>
      {questions.map((question, index) => {
        const isOpen = index === questionIndex;
        return (
          <QuestionBlockWrapper
            key={question.title}
            isOpen={isOpen}
            onClick={() => setQuestionIndex(index)}
          >
            <UpperRow>
              <div>
                <QuestionTitle>
                  {question.title}
                </QuestionTitle>
              </div>
              <div>
                <Arrow reversed={isOpen} />
              </div>
            </UpperRow>
            <LowerRow isOpen={isOpen}>
              <LowerRowContainer>
                <h3>{question.subtitle}</h3>
              </LowerRowContainer>
            </LowerRow>
          </QuestionBlockWrapper>
        );
      })}
    </>
  );
};

export default function Retrodrop() {
  const [pools, setPools] = useState<IRetrodropPool[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const tokens = useAppSelector(selectTokens);

  const { RPCProvider, accountId } = useWalletSelector();
  const claimingContract = useMemo(
    () => new ClaimingContract(RPCProvider, accountId),
    [RPCProvider, accountId],
  );
  const tokensLength = useMemo(() => Object.values(tokens).length, [tokens]);

  useEffect(() => {
    const getPools = async () => {
      try {
        setLoading(true);
        const poolsRes = await claimingContract.getPoolsInformation();
        setPools(poolsRes);
      } finally {
        setLoading(false);
      }
    };

    getPools();
  }, [claimingContract]);

  return (
    <Wrapper>
      <Title>{t('retrodropPage.title')}</Title>
      <Subtitle>
        {t('retrodropPage.subtitle')}
      </Subtitle>
      {loading || !tokensLength
        ? <RetrodropCardPlaceholder />
        : pools.map((pool) => (
          <RetrodropCard key={pool.id + pool.title} pool={pool} tokens={tokens} />
        ))}
      <DescriptionTitle>
        {t('retrodropPage.mainQuestion')}
      </DescriptionTitle>
      <DescriptionSubtitle>
        {t('retrodropPage.mainAnswer')}
      </DescriptionSubtitle>
      <DescriptionTitle>
        {t('retrodropPage.questionAndAnswers')}
      </DescriptionTitle>
      <QuestionBlock questions={questionsArray} />
    </Wrapper>
  );
}
