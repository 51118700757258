import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/redux-store';

interface IUserRewardsState {
  value: {[key:string]: string}
}

const initialState: IUserRewardsState = { value: {} };

export const userRewardsSlice = createSlice({
  name: 'userRewards',
  initialState,
  reducers: {
    setUserRewards: (state, action: PayloadAction<{[key:string]: string}>) => {
      // eslint-disable-next-line no-param-reassign
      state.value = action.payload;
    },
  },
});

export const { setUserRewards } = userRewardsSlice.actions;
export const selectUserRewards = (state: RootState) => state.userRewards.value;

export default userRewardsSlice.reducer;
