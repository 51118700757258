import * as nearApiJs from 'near-api-js';
import nearIcon from 'assets/images-app/near.svg';
import wrapNearIcon from 'assets/images-app/wNEAR.svg';
import defaultToken from 'assets/images-app/defaultToken.svg';
import { ITokenMetadata } from 'store';
import {
  NEAR_TOKEN_ID,
  FT_TRANSFER_GAS,
  ONE_YOCTO_NEAR,
  FT_GAS,
} from 'utils/constants';

import Big from 'big.js';
import getConfig from 'services/config';
import { FTTokenContractMethod, FTViewMethod, ITransaction } from 'services/interfaces';
import RPCProviderService from 'services/RPCProviderService';

const {
  utils: {
    format: {
      formatNearAmount,
    },
  },
} = nearApiJs;

const config = getConfig();
const DECIMALS_DEFAULT_VALUE = 0;
const ICON_DEFAULT_VALUE = '';
const CONTRACT_ID = config.contractId;
export const ACCOUNT_MIN_STORAGE_AMOUNT = '0.005';
export const MIN_DEPOSIT_PER_TOKEN = new Big('5000000000000000000000');
export const STORAGE_PER_TOKEN = '0.005';
export const STORAGE_TO_REGISTER_FT = '0.1';
export const STORAGE_TO_REGISTER_WNEAR = '0.00125';
export const ONE_MORE_DEPOSIT_AMOUNT = '0.01';

const NEAR_TOKEN = {
  decimals: 24,
  icon: nearIcon,
  name: 'Near token',
  version: '0',
  symbol: 'NEAR',
  reference: '',
};

interface FungibleTokenContractInterface {
  contractId: string;
  provider: RPCProviderService,
  accountId: string,
}
const defaultMetadata = {
  decimals: DECIMALS_DEFAULT_VALUE,
  icon: ICON_DEFAULT_VALUE,
  name: 'Token',
  version: '0',
  symbol: 'TKN',
  reference: '',
};

export enum StorageType {'Swap' = 'Swap', 'Liquidity' = 'Liquidity'}

export default class FungibleTokenContract {
  private accountId: string;

  private provider: RPCProviderService;

  constructor(props: FungibleTokenContractInterface) {
    this.contractId = props.contractId;
    this.provider = props.provider;
    this.accountId = props.accountId;
  }

  contractId = CONTRACT_ID;

  metadata: ITokenMetadata = defaultMetadata;

  async getStorageBalance({ accountId } : { accountId: string }) {
    return this.provider
      .viewFunction(FTViewMethod.storageBalanceOf, this.contractId, { account_id: accountId });
  }

  async getMetadata() {
    try {
      if (this.contractId === NEAR_TOKEN_ID) {
        this.metadata = { ...defaultMetadata, ...NEAR_TOKEN };
        return NEAR_TOKEN;
      }

      if (
        this.metadata.decimals !== DECIMALS_DEFAULT_VALUE
      && this.metadata.icon !== ICON_DEFAULT_VALUE
      ) return this.metadata;

      const metadata = await this.provider.viewFunction(FTViewMethod.FTMetadata, this.contractId);

      if (this.contractId === config.nearAddress) metadata.icon = wrapNearIcon;
      if (!metadata.icon) metadata.icon = defaultToken;

      this.metadata = { ...defaultMetadata, ...metadata };
      return metadata;
    } catch (e) {
      console.warn(`Error while loading ${this.contractId}`);
    }
    return null;
  }

  async getBalanceOf({ accountId }: { accountId: string }) {
    if (this.contractId === NEAR_TOKEN_ID) {
      const account = await this.provider.viewAccount(
        this.accountId,
      );
      return account?.amount;
    }
    return this.provider
      .viewFunction(FTViewMethod.FTBalanceOf, this.contractId, { account_id: accountId });
  }

  async checkSwapStorageBalance({ accountId }: { accountId: string }) {
    const transactions: ITransaction[] = [];
    try {
      if (this.contractId === NEAR_TOKEN_ID) return [];
      const storageAvailable = await this.getStorageBalance({ accountId });

      if (storageAvailable === null || storageAvailable.total === '0') {
        transactions.push(
          {
            receiverId: this.contractId,
            functionCalls: [{
              methodName: FTTokenContractMethod.storageDeposit,
              args: {
                registration_only: true,
                account_id: accountId,
              },
              amount: this.contractId === config.nearAddress
                ? STORAGE_TO_REGISTER_WNEAR
                : STORAGE_TO_REGISTER_FT,
            }],
          },
        );
      }
      return transactions;
    } catch (e) {
      return [];
    }
  }

  async transfer({
    accountId,
    inputToken,
    amount,
    message = '',
    receiverId = CONTRACT_ID,
    gas = FT_GAS,
  }:
  {
    accountId: string,
    inputToken: string,
    amount: string,
    message?: string,
    receiverId?: string,
    gas?: string,
  }): Promise<ITransaction[]> {
    const transactions: ITransaction[] = [];
    const checkStorage = await this.checkSwapStorageBalance({ accountId });
    transactions.push(...checkStorage);
    transactions.push({
      receiverId: inputToken,
      functionCalls: [{
        methodName: FTTokenContractMethod.ftTransferCall,
        args: {
          receiver_id: receiverId,
          amount,
          msg: message,
        },
        amount: ONE_YOCTO_NEAR,
        gas,
      }],
    });
    return transactions;
  }

  wrap({ amount }:{ amount: string, }) {
    if (this.contractId === NEAR_TOKEN_ID) throw Error('Can\'t wrap from NEAR token');
    const transactions: ITransaction[] = [];

    transactions.push({
      receiverId: this.contractId,
      functionCalls: [{
        methodName: FTTokenContractMethod.nearDeposit,
        amount: formatNearAmount(amount) as string,
        args: {},
        gas: FT_TRANSFER_GAS as string,
      }],
    });
    return transactions;
  }

  unwrap({ amount }:{ amount: string}) {
    if (this.contractId === NEAR_TOKEN_ID) throw Error('Can\'t wrap from NEAR token');
    const transactions: ITransaction[] = [];

    transactions.push({
      receiverId: this.contractId,
      functionCalls: [{
        methodName: FTTokenContractMethod.nearWithdraw,
        args: { amount },
        gas: FT_TRANSFER_GAS as string,
        amount: ONE_YOCTO_NEAR,
      }],
    });

    return transactions;
  }
}
