import { useEffect } from 'react';
import { providers } from 'near-api-js';
import { t } from 'i18next';
import getConfig from 'services/config';
import ToastService from 'components/Toast';
import { TRANSACTION_HASHES, ERROR_CODE, ERROR_MESSAGE } from './constants';
import { clearHash, parseTransactions } from './helpers';

const config = getConfig();

export default function useTransactionHash(
  query: string | undefined,
  accountId: string,
) {
  return useEffect(() => {
    if (accountId) {
      const queryParams = new URLSearchParams(query);
      const transactions = queryParams?.get(TRANSACTION_HASHES);
      const errorCode = queryParams?.get(ERROR_CODE);
      const errorMessage = queryParams?.get(ERROR_MESSAGE);
      if (errorCode || errorMessage) ToastService.error({ text: t('toast.transactionFailed') });

      if (transactions) {
        const provider = new providers.JsonRpcProvider(config.nodeUrl);
        try {
          Promise
            .all(transactions.split(',').map((txHash) => provider.txStatus(txHash, accountId)))
            .then((res) => parseTransactions(res))
            .catch((e) => console.warn(`${e} error while parse transactions`));
        } catch (e) {
          console.warn(`${e} error while loading tx`);
        }
      }
      clearHash(queryParams);
    }
  }, [query, accountId]);
}
