import { CurrentButton } from 'store';
import ClaimArrow from 'assets/images-app/claim-arrow';
import SwapIcon from 'assets/images-app/swap-icon';

import { t } from 'i18next';
import { useWalletSelector } from 'providers/wallet-provider';
import {
  AddIconLogo, ButtonPrimary, ButtonSecondary,
  IconSwap, Wallet, ClaimLogo,
} from './styles';

export interface IButtons {
  toPageAdd: () => void,
  titleAdd: string,
  toPageRemove: () => void,
  titleRemove: string,
  showRemoveButton: boolean
  showAddButton?: boolean
}

function CurrentTitle(variant: CurrentButton) {
  switch (variant) {
    case CurrentButton.AddLiquidity:
      return t('action.addLiquidity');
    case CurrentButton.CreatePool:
      return t('action.createPool');
    case CurrentButton.Swap:
      return t('action.swap');
    case CurrentButton.Withdraw:
      return t('action.removeLiquidity');
    case CurrentButton.Stake:
      return t('action.stake');
    case CurrentButton.Unstake:
      return t('action.unstake');
    case CurrentButton.ClaimRetrodrop:
      return t('action.claimRetrodrop');
    default:
      return '';
  }
}

function CurrentIcon({ variant, reversed }:{ variant: CurrentButton, reversed: boolean }) {
  switch (variant) {
    case CurrentButton.AddLiquidity:
      return <AddIconLogo reversed={reversed} />;
    case CurrentButton.CreatePool:
      return <AddIconLogo reversed={reversed} />;
    case CurrentButton.Swap:
      return (
        <IconSwap>
          <SwapIcon />
        </IconSwap>
      );
    case CurrentButton.Stake:
      return <AddIconLogo reversed={reversed} />;
    case CurrentButton.ClaimRetrodrop:
      return (
        <ClaimLogo>
          <ClaimArrow />
        </ClaimLogo>
      );
    default:
      return null;
  }
}

export default function RenderButton({
  typeButton,
  onSubmit,
  disabled = false,
  reversed = false,
  isFullHeight = false,
}: {
  typeButton: CurrentButton,
  onSubmit:() => void,
  disabled?: boolean,
  reversed?: boolean,
  isFullHeight?: boolean
}) {
  const { isSignedIn, openModal } = useWalletSelector();
  const isConnected = isSignedIn();

  const title = isConnected ? CurrentTitle(typeButton) : t('action.connectWallet');

  if (!isConnected) {
    return (
      <ButtonSecondary
        onClick={openModal}
      >
        <Wallet />
        {title}
      </ButtonSecondary>
    );
  }

  const currentIcon = <CurrentIcon variant={typeButton} reversed={reversed} />;

  return (
    <ButtonPrimary
      onClick={onSubmit}
      disabled={disabled}
      isFullHeight={isFullHeight}
    >
      {reversed
        ? <>{currentIcon}{title}</>
        : <>{title}{currentIcon}</>}
    </ButtonPrimary>
  );
}
