import getConfig from 'services/config';
import { IRetrodropPool } from 'pages/Retrodrop/constants';

import { assertFulfilled, DEFAULT_PAGE_LIMIT } from 'store/helpers';
import {
  ClaimContractMethod, ClaimContractViewMethod, IClaimingAccount, IClaimingPool, ITransaction,
} from 'services/interfaces';
import { FT_GAS, ONE_YOCTO_NEAR } from 'utils/constants';
import RPCProviderService from 'services/RPCProviderService';
import FungibleTokenContract from './FungibleToken';

const config = getConfig();
const CONTRACT_ID = config.claimContract;
const MAX_POOLS_LIMIT = 100;
const START_POOLS_INDEX = 0;

export default class ClaimingContract {
  contractId = CONTRACT_ID;

  private accountId: string;

  private provider: RPCProviderService;

  constructor(provider: RPCProviderService, accountId: string) {
    this.provider = provider;
    this.accountId = accountId;
  }

  async getPoolsInformation(): Promise<IRetrodropPool[]> {
    try {
      const poolsCount = await this.getPoolsCount();
      const pages = Math.ceil(poolsCount / DEFAULT_PAGE_LIMIT);
      const pools: IClaimingPool[] = (await Promise.allSettled(
        [...Array(pages)]
          .map((_, i) => this.getPools(i * DEFAULT_PAGE_LIMIT, DEFAULT_PAGE_LIMIT)),
      )).filter(assertFulfilled)
        .map(({ value }) => value)
        .flat();
      const accounts = this.accountId ? await Promise.all(
        pools.map((pool) => this.getClaimingAccount(pool.pool_id, this.accountId)),
      ) : [];
      return pools.map((basicPool) => {
        const claimAccount = accounts.find((account) => account.poolId === basicPool.pool_id);
        return ClaimingContract.formatPool(basicPool, claimAccount?.data);
      });
    } catch (e) {
      console.warn(`${e} while loading claiming pools`);
      return [];
    }
  }

  async getPoolsCount(): Promise<number> {
    try {
      return this.provider.viewFunction(ClaimContractViewMethod.getPoolsCount, CONTRACT_ID);
    } catch (e) {
      return 0;
    }
  }

  async getPools(from = START_POOLS_INDEX, limit = MAX_POOLS_LIMIT) {
    return this.provider
      .viewFunction(ClaimContractViewMethod.getPools, CONTRACT_ID, { from, limit });
  }

  async getClaimingAccount(poolId: number, accountId: string) {
    try {
      const account = await this.provider
        .viewFunction(ClaimContractViewMethod.getAccount, CONTRACT_ID, {
          account_id: accountId, pool_id: poolId,
        });
      return { poolId, data: account };
    } catch (e) {
      return {};
    }
  }

  static formatPool(claimingPool: IClaimingPool, claimAccount?: IClaimingAccount): IRetrodropPool {
    const {
      title = '',
      icon = '',
      description = '',
      conditions = [],
    } = claimingPool.metadata;

    return {
      id: claimingPool.pool_id,
      title,
      icon,
      description,
      totalAmount: claimingPool.total_amount,
      totalAmountClaimed: claimingPool.total_amount_claimed,
      rewardToken: claimingPool.reward_token,
      stage: claimingPool.stage,
      claimAvailable: claimingPool.claim_available,
      startDate: claimingPool.start_date,
      totalAccounts: claimingPool.total_accounts,
      amountToClaim: claimAccount?.total_amount || '0',
      claimed: claimAccount?.claimed || '0',
      vesting: claimingPool.vesting,
      conditions,
    };
  }

  async claim(poolId: number, token: FungibleTokenContract, accountId = this.accountId) {
    const transactions: ITransaction[] = [];
    const checkStorage = await token.checkSwapStorageBalance({ accountId });
    transactions.push(...checkStorage);
    transactions.push({
      receiverId: this.contractId,
      functionCalls: [{
        methodName: ClaimContractMethod.claim,
        args: { pool_id: poolId },
        amount: ONE_YOCTO_NEAR,
        gas: FT_GAS,
      }],
    });

    return transactions;
  }
}
