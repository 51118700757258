import { isMobile } from 'utils/userAgent';
import Tooltip from 'components/Tooltip';
import { secondsToMilliseconds, parseStaticDate } from 'utils/calculationDate';
import Big from 'big.js';
import RenderButton from 'components/Button/RenderButton';
import { CurrentButton } from 'store';
import { t } from 'i18next';
import ClaimArrow from 'assets/images-app/claim-arrow';
import {
  ButtonIcon,
  ParticipationTitle,
  RewardColumn,
  InfoValue,
  InfoTitle,
  ClaimAvailableTitle,
  ClaimButtonSoon,
  ClaimedBlock,
  ClaimedLogo,
  ClaimedText,
  ClaimingSoonRow,
} from './styles';
import { IRetrodropPool } from '../constants';

export enum RetrodropStatuses {
  NotConnected,
  Claimed,
  AvailableToClaim,
  NotParticipated,
  ClaimingSoon
}

export const ActionRow = ({
  status,
  amountToClaim,
  startDate,
  onClick,
  claimAvailable,
}:
{
  status: RetrodropStatuses,
  amountToClaim: string,
  startDate: number,
  onClick: () => void,
  claimAvailable: boolean
}) => {
  const formattedTime = parseStaticDate(secondsToMilliseconds(startDate));
  switch (status) {
    case (RetrodropStatuses.NotConnected):
    case (RetrodropStatuses.AvailableToClaim):
      return (
        <>
          <RewardColumn>
            <InfoTitle>
              <span>{t('retrodropPage.card.yourReward')}</span>
              <Tooltip title={t('tooltipTitle.yourReward')} />
            </InfoTitle>
            <InfoValue>{amountToClaim}</InfoValue>
          </RewardColumn>
          <RenderButton
            reversed
            onSubmit={onClick}
            disabled={!claimAvailable}
            typeButton={CurrentButton.ClaimRetrodrop}
          />
        </>
      );
    case (RetrodropStatuses.Claimed):
      return (
        <>
          <RewardColumn>
            <InfoTitle>
              <span>{t('retrodropPage.card.yourReward')}</span>
              <Tooltip title={t('tooltipTitle.yourReward')} />
            </InfoTitle>
            <InfoValue>{amountToClaim}</InfoValue>
          </RewardColumn>
          <ClaimedBlock>
            <ClaimedLogo />
            <ClaimedText>
              {t('retrodropPage.card.rewardClaimed')}
            </ClaimedText>
          </ClaimedBlock>
        </>
      );
    case (RetrodropStatuses.NotParticipated):
      return (
        <>
          <ParticipationTitle>
            {t('retrodropPage.card.notReceived')}
          </ParticipationTitle>
        </>
      );
    case (RetrodropStatuses.ClaimingSoon):
      return (
        <>
          <RewardColumn>
            <InfoTitle>
              <span>{t('retrodropPage.card.yourReward')}</span>
              <Tooltip title={t('tooltipTitle.yourReward')} />
            </InfoTitle>
            <InfoValue>{amountToClaim}</InfoValue>
          </RewardColumn>
          <ClaimingSoonRow>
            <ClaimAvailableTitle>
              {t('retrodropPage.card.willBeAvailable')}
              {isMobile ? <>&nbsp;</> : <br /> }
              {formattedTime}
            </ClaimAvailableTitle>
            <ClaimButtonSoon disabled>
              <ButtonIcon>
                <ClaimArrow />
              </ButtonIcon>
              {t('retrodropPage.card.claimButton')}
            </ClaimButtonSoon>
          </ClaimingSoonRow>
        </>
      );
    default: return <></>;
  }
};

export function getRetrodropCardStatus(pool: IRetrodropPool, isConnected: boolean) {
  const isParticipated = Big(pool.amountToClaim).gt(0);
  const isClaimed = Big(pool.amountToClaim).eq(pool.claimed);
  const now = Date.now();

  if (!isConnected) return RetrodropStatuses.NotConnected;
  if (!isParticipated) return RetrodropStatuses.NotParticipated;
  if (isClaimed) return RetrodropStatuses.Claimed;
  if (secondsToMilliseconds(pool.startDate) > now) return RetrodropStatuses.ClaimingSoon;
  if (!isClaimed) return RetrodropStatuses.AvailableToClaim;
  return RetrodropStatuses.AvailableToClaim;
}
