import styles from './styles';

export const successContent = (text: string, href?: string | null) => (
  <styles.Container>
    <styles.SuccessIcon />
    <styles.Description>
      {text}
      {href && (
      <styles.Link href={href} target="_blank" rel="noreferrer">
        <styles.ExternalLink />
      </styles.Link>
      )}
    </styles.Description>
  </styles.Container>
);

export const errorContent = (text: string, href?: string) => (
  <styles.Container>
    <styles.ErrorIcon />
    <styles.Description>
      {text}
      {href && (
      <styles.Link href={href} target="_blank" rel="noreferrer">
        <styles.ExternalLink />
      </styles.Link>
      )}
    </styles.Description>
  </styles.Container>
);
