/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/redux-store';

interface IBalancesState {
  value: { [key:string]: string }
}

const initialState: IBalancesState = { value: {} };

export const balancesSlice = createSlice({
  name: 'balances',
  initialState,
  reducers: {
    setBalances: (state, action: PayloadAction<{ [key:string]: string }>) => {
      state.value = action.payload;
    },
    updateTokensBalances: (state, action: PayloadAction<{ [key:string]: string }>) => {
      const newBalances: {[key: string]: string} = action.payload;
      const newBalancesSet = Object.entries(newBalances).reduce((acc, [key, value]) => (
        { ...acc, [key]: value }
      ), state.value);
      state.value = newBalancesSet;
    },
  },
});

export const { setBalances, updateTokensBalances } = balancesSlice.actions;
export const selectBalances = (state: RootState) => state.balances.value;

export default balancesSlice.reducer;
