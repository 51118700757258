import { toast, ToastOptions, Slide } from 'react-toastify';

import { colors } from 'theme';
import { errorContent, successContent } from './Content';

export const toastOptions: ToastOptions = {
  transition: Slide,
  hideProgressBar: true,
  closeButton: false,
  position: 'top-center',
  style: {
    background: colors.toastBg,
    boxShadow: `0px 16px 36px -12px ${colors.boxShadowCard}`,
    borderRadius: '0.75rem',
    color: colors.white,
    width: '100%',
  },
};

export default class ToastService {
  static success({ text, href }: { text: string; href?: string }): void {
    const content = successContent(text, href);
    toast(content, toastOptions);
  }

  static error({ text, href }: { text: string; href?: string }): void {
    const content = errorContent(text, href);
    toast(content, toastOptions);
  }
}
