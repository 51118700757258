import { colors } from 'theme';

const SwapIcon = ({ color = colors.globalWhite }: { color?: string }) => (
  <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M23.5016 1.87451C24.1229 1.87451 24.6266 2.37819 24.6266 2.99951V9.00014C24.6266 9.62147 24.1229 10.1251 23.5016 10.1251H17.501C16.8797 10.1251 16.376 9.62147 16.376 9.00014C16.376 8.37882 16.8797 7.87514 17.501 7.87514H22.3766V2.99951C22.3766 2.37819 22.8803 1.87451 23.5016 1.87451Z" fill={color} />
    <path fillRule="evenodd" clipRule="evenodd" d="M0.374023 13C0.374023 12.3787 0.877703 11.875 1.49902 11.875H7.49966C8.12098 11.875 8.62466 12.3787 8.62466 13C8.62466 13.6213 8.12098 14.125 7.49966 14.125H2.62402V19.0006C2.62402 19.622 2.12034 20.1256 1.49902 20.1256C0.877703 20.1256 0.374023 19.622 0.374023 19.0006V13Z" fill={color} />
    <path fillRule="evenodd" clipRule="evenodd" d="M18.0653 5.43487C16.1536 3.52219 13.3943 2.72405 10.7567 3.32084C8.11912 3.91762 5.97215 5.82587 5.07002 8.37522C4.86275 8.96095 4.2199 9.26775 3.63417 9.06048C3.04844 8.85321 2.74164 8.21036 2.94891 7.62463C4.10876 4.34698 6.86908 1.89359 10.2602 1.12631C13.6454 0.360366 17.1865 1.38162 19.6439 3.83146L24.2719 8.18018C24.7247 8.60565 24.7468 9.31762 24.3214 9.77041C23.8959 10.2232 23.1839 10.2453 22.7311 9.81988L18.0907 5.45942C18.0821 5.45137 18.0736 5.44318 18.0653 5.43487ZM0.67918 12.2296C1.10465 11.7768 1.81661 11.7546 2.2694 12.1801L6.90989 16.5405C6.91846 16.5486 6.9269 16.5568 6.93521 16.5651C8.84693 18.4778 11.6063 19.2759 14.2438 18.6791C16.8814 18.0823 19.0284 16.1741 19.9305 13.6247C20.1378 13.039 20.7807 12.7322 21.3664 12.9395C21.9521 13.1468 22.2589 13.7896 22.0516 14.3753C20.8918 17.653 18.1315 20.1064 14.7404 20.8737C11.3552 21.6396 7.81404 20.6184 5.35668 18.1685L0.728653 13.8198C0.275864 13.3943 0.253714 12.6823 0.67918 12.2296Z" fill={color} />
  </svg>

);

export default SwapIcon;
