import { PropsWithChildren } from 'react';
import styled from 'styled-components';

interface IBottom {
  bottom?: string
}

export const Container = styled.div`
    position: relative;
    margin: 0 0 0 .5rem;
    display: flex;
    z-index: 3;

    svg {
      pointer-events: none;
    }

    @keyframes fadeInTop {
        from {
            opacity: 0.2;
            transform: translate(-50%, 10px);
        }
        to {
            opacity: 1;
            transform: translate(-50%, 0);
        }
    }

    :hover {
      cursor: pointer;
    }
`;

export const HoverContent = styled.div<PropsWithChildren<IBottom>>`
  position: absolute;
  left: 50%;
  bottom: ${({ bottom }) => (bottom || '25px')};
  background-color: ${({ theme }) => theme.tooltip};
  color: white;
  border-radius: 8px;
  padding: 8px;
  font-style: normal;
  font-weight: normal;
  font-size: .75rem;
  line-height: 1.063;
  width: max-content;
  max-width: 250px;
  z-index: 1;
  box-shadow: 0px 3px 12px 0px rgb(0 0 0 / 15%);

  &.show {
    animation-name: fadeInTop;
    animation-duration: 200ms;
    animation-fill-mode: forwards;
  }
`;
export const ApyContainer = styled.div`
  > div{
    display: flex;
    justify-content: space-between;
    > div{
      padding: 0 10px;
    }
  }
`;
