import { Dispatch } from '@reduxjs/toolkit';
import FungibleTokenContract from 'services/contracts/FungibleToken';
import { TokenType } from 'store/interfaces';
import { RootState } from 'store/redux-store';
import { setCurrentToken } from 'store/slices/jumboRuntime';
import { toArray, getPoolsPath } from 'utils';

// eslint-disable-next-line import/prefer-default-export
export const setCurrentTokenAndPools = (
  activeToken: FungibleTokenContract, tokenType: TokenType,
) => (
  dispatch: Dispatch, getState: () => RootState,
) => {
  const {
    pools,
    tokens,
    jumboRuntime: { inputToken, outputToken },
  } = getState();

  const poolArray = toArray(pools.value);
  if (tokenType === TokenType.Output) {
    if (!inputToken) return;
    const availablePools = getPoolsPath(
      inputToken.contractId, activeToken.contractId, poolArray, tokens.value,
    );
    dispatch(setCurrentToken({ activeToken, availablePools, type: TokenType.Output }));
  } else {
    if (!outputToken) return;
    const availablePools = getPoolsPath(
      activeToken.contractId, outputToken.contractId, poolArray, tokens.value,
    );
    dispatch(setCurrentToken({ activeToken, availablePools, type: TokenType.Input }));
  }
};
