import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import RenderButton from 'components/Button/RenderButton';
import { getUpperCase, formatFuturesPool } from 'utils';
import {
  TokenType, CurrentButton, IFuturesPool, FuturesPoolEnum,
} from 'store';
import { useTranslation } from 'react-i18next';
import FungibleTokenContract from 'services/contracts/FungibleToken';
import { getToken } from 'store/helpers';
import SwapFuturesContract from 'services/contracts/SwapFuturesContract';
import Big from 'big.js';
import { checkInvalidAmount } from 'utils/calculations';
import getConfig from 'services/config';
import SwapInputPanel from 'components/SwapInputPanel';
import { useWalletSelector } from 'providers/wallet-provider';
import { useAppSelector } from 'hooks/redux-hooks';
import { selectTokens, selectBalances } from 'store/slices';
import { selectJumboRuntime } from 'store/slices/jumboRuntime';
import { ReactComponent as LogoWallet } from 'assets/images-app/wallet.svg';
import {
  Container,
  Title,
  ContainerCard,
  ActionContainer,
  ChangeTokenContainer,
  ExchangeBlock,
  BlockButton,
} from './styles';

const config = getConfig();

export default function SwapFutures() {
  const {
    loading,
  } = useAppSelector(selectJumboRuntime);
  const tokens = useAppSelector(selectTokens);
  const balances = useAppSelector(selectBalances);
  const { RPCProvider, accountId, requestSignTransactions } = useWalletSelector();
  const { t } = useTranslation();
  const swapFuturesContract = useMemo(
    () => new SwapFuturesContract(RPCProvider, accountId),
    [RPCProvider, accountId],
  );
  const [inputToken, setInputToken] = useState<FungibleTokenContract | null>(null);
  const [outputToken, setOutputToken] = useState<FungibleTokenContract | null>(null);
  const [futuresPool, setFuturesPool] = useState<IFuturesPool | null>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const invalidInput = checkInvalidAmount(balances, inputToken, inputValue);

  const depositToken = useMemo(() => {
    if (!futuresPool) return null;
    return getToken(futuresPool.tokenIn.id, tokens);
  }, [futuresPool, tokens]);

  const distributeToken = useMemo(() => {
    if (!futuresPool) return null;
    return getToken(futuresPool.tokenOut.id, tokens);
  }, [futuresPool, tokens]);

  const getClaimPool = useCallback(async () => {
    try {
      const retrievedPool = await swapFuturesContract.getPool(config.futuresPoolId);
      const newRetrievedPool = formatFuturesPool(retrievedPool);
      setFuturesPool(newRetrievedPool);
    } catch (e) {
      console.warn(`Error: ${e} while get data about swap futures`);
    }
  }, [swapFuturesContract]);

  useEffect(() => {
    getClaimPool();
    if (!depositToken || !distributeToken) return;
    setInputToken(depositToken);
    setOutputToken(distributeToken);
  }, [depositToken, getClaimPool, distributeToken]);

  const exchangeLabel = (inputToken && outputToken)
    ? `
      1 ${getUpperCase(inputToken.metadata.symbol)} 
      ≈ 1 ${getUpperCase(outputToken.metadata.symbol)}
      `
    : 'Loading...';

  const swapToken = useCallback(async () => {
    if (!inputToken || !outputToken || !futuresPool) return;
    const transactions = await swapFuturesContract
      .swapFutures(config.futuresPoolId, inputToken, outputToken, inputValue);
    await requestSignTransactions(transactions);
  }, [
    futuresPool, inputToken, inputValue, outputToken, swapFuturesContract, requestSignTransactions,
  ]);

  const canSwap = Big(inputValue || 0).gt(0)
    && (inputToken || outputToken)
    && futuresPool?.stage === FuturesPoolEnum.Running;

  return (
    <Container>
      <Title>{t('action.swapFutures')}</Title>
      <ContainerCard>
        <ActionContainer>
          <SwapInputPanel
            token={inputToken}
            tokenType={TokenType.Input}
            value={inputValue}
            setValue={setInputValue}
            balance={balances[inputToken?.contractId ?? '']}
            needOpenSearchModal={false}
            iconLeft={<LogoWallet />}
          />
          <ChangeTokenContainer />
          <SwapInputPanel
            token={outputToken}
            tokenType={TokenType.Output}
            value={inputValue}
            setValue={setInputValue}
            balance={balances[outputToken?.contractId ?? '']}
            needOpenSearchModal={false}
            iconLeft={<LogoWallet />}
          />
        </ActionContainer>
        <ExchangeBlock>
          {loading ? `${t('common.loading')}` : <div>{exchangeLabel}</div>}
        </ExchangeBlock>

        <BlockButton>
          <RenderButton
            typeButton={CurrentButton.Swap}
            onSubmit={swapToken}
            disabled={!canSwap || invalidInput}
            isFullHeight
          />
        </BlockButton>

      </ContainerCard>
    </Container>
  );
}
