import { SpecialContainer } from 'components/SpecialContainer';

import { ReactComponent as GreenCheckmark } from 'assets/images-app/green-checkmark.svg';
import { ReactComponent as ArrowDown } from 'assets/images-app/icon-arrow-down.svg';

import { ButtonPrimary } from 'components/Button';
import styled from 'styled-components';

export const PoolCardWrapper = styled(SpecialContainer)`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 0;
  & > div:first-child {
    margin-bottom: 1.5rem;
  }
`;

export const Wrapper = styled.div`
  margin-top: 1rem;
  background-color: ${({ theme }) => theme.globalGreyOp01};
  max-width: 736px;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    max-width: 328px;
  `}
  border-radius: 36px;
  display: flex;
  flex-direction: column;
  transition: all .5s ease-in-out;
`;

export const ConditionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ConditionsTitle = styled.p`
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.063rem;
    letter-spacing: 0em;
    margin-block-start: .5em;
    margin-block-end: .5em;
`;

export const Arrow = styled(ArrowDown)<{reversed: boolean}>`
  width: 12.5px;
  height: 10.5px;
  margin-left: 12.5px;
  ${(props) => props.reversed && `
     transform: rotate(180deg);
     transition: all .5s ease-in-out;
  `};
`;

export const InfoTitle = styled.div`
  display: flex;
  font-style: normal;
  font-weight: 300;
  font-size: .75rem;
  line-height: .875rem;
  color: ${({ theme }) => theme.globalGrey};
  margin-bottom: .75rem;
  & > span {
    white-space: nowrap;
  }
`;

export const InfoValue = styled.div<{isColor?: boolean}>`
  display: flex;
  font-style: normal;
  font-weight: 300;
  font-size: .75rem;
  line-height: .875rem;
  color: ${({ theme, isColor }) => (isColor ? theme.globalGreen : theme.globalWhite)};
  white-space: nowrap;
`;

export const RowItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

export const RewardColumn = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const Row = styled(RowItem)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    flex-direction: column;
    align-items: flex-start;
    >button {
      width: 100%;
      margin-top: 1.5rem;
    }
  `}
  >button {
    height: 40px;
  }
`;

export const CardSubtitle = styled.h2`
  font-family: Rubik;
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 0.875rem;
  letter-spacing: 0em;
  text-align: right;
`;

export const CardTitle = styled.h1`
  font-family: Rubik;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.188rem;
  letter-spacing: 0em;
  text-align: left;
  margin-left: .5rem;
`;

export const ButtonIcon = styled.div`
  display: flex;
  align-items: center;
  width: 12px;
  height: 12px;
  margin-right: .625rem;
`;

export const ParticipationTitle = styled.h3`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.063rem;
  letter-spacing: 0em;
  text-align: center;
  flex: 1; 
 
`;

export const ClaimedBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
 
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 100%;
  `}
`;

export const ClaimedLogo = styled(GreenCheckmark)`
  margin-right: .4rem;
`;

export const ClaimedText = styled.h3`
  font-family: Rubik;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.063rem;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ theme }) => theme.globalGreen};
`;

export const ClaimAvailableTitle = styled.h4`
  font-family: Rubik;
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 1.063rem;
  letter-spacing: 0em;
  text-align: right;
  margin: 0 1.5rem 0;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    text-align: center;
    width: 100%;
    font-weight: 300;
    line-height: 0.875rem;
    margin-block-start: 1rem;
    margin-block-end: 1rem;
    margin-right: 0;
  `};
`;

export const ClaimingSoonRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  `}
`;

export const ClaimButtonSoon = styled(ButtonPrimary)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 100%;
  `}
`;

export const ConditionsList = styled.div<{isOpen:boolean}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: center;
  
  z-index: 2;
  transition: max-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition-duration: 240ms;
  max-height:  ${({ isOpen }) => (isOpen ? '300px' : '0px')};
  overflow: hidden;
  ${({ isOpen }) => !isOpen && 'visibility: hidden'};
`;

export const ConditionListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: center;
  
  max-width: 100%;
  z-index: 6;
  margin: 0 1.5rem 1.125rem 1.5rem;
`;

export const ConditionBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  flex-grow: 1;
  >p {
    margin-block-start: .5em;
    margin-block-end: .5em;
    margin-left: 9px;
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.063rem;
    letter-spacing: 0em;
    text-align: left;
  }
  >svg{
    overflow: visible;
  }
`;
