import React, { useMemo, useState } from 'react';
import { CurrentButton } from 'store';
import { ReactComponent as Close } from 'assets/images-app/close.svg';
import RenderButton from 'components/Button/RenderButton';

import { useTranslation } from 'react-i18next';

import { formatTokenAmount } from 'utils/calculations';
import { INITIAL_INPUT_PLACEHOLDER, MIN_DEPOSIT_SHARES } from 'utils/constants';
import FarmContract from 'services/contracts/FarmContract';
import Big from 'big.js';

import TokenPairDisplay from 'components/TokensDisplay/TokenPairDisplay';
import InputSharesContainer from 'components/CurrencyInputPanel/InputSharesContainer';
import { IStakeModal } from 'providers/modals.interfaces';
import { EModals, useModalContext } from 'providers/modals-provider';
import { useWalletSelector } from 'providers/wallet-provider';
import {
  Layout, ModalBlock, ModalIcon, ModalTitle,
} from '../styles';
import {
  StakeModalContainer, ModalBody, GetShareBtn, TokensBlock, Warning,
} from './styles';

const StakeModal: React.FC<IStakeModal> = ({ closeModal, pool }) => {
  const { t } = useTranslation();
  const { RPCProvider, accountId, requestSignTransactions } = useWalletSelector();
  const farmContract = useMemo(
    () => new FarmContract(RPCProvider, accountId),
    [RPCProvider, accountId],
  );

  const { showModal } = useModalContext();

  const [stakeValue, setStakeValue] = useState<string>(INITIAL_INPUT_PLACEHOLDER);
  const [warning, setWarning] = useState(false);

  if (!pool) return null;

  const formattedPoolShares = formatTokenAmount(pool.shares ?? '0', pool.lpTokenDecimals);
  const formattedMinDepositShares = formatTokenAmount(MIN_DEPOSIT_SHARES, pool.lpTokenDecimals);

  const buttonDisabled = stakeValue
    ? (
      new Big(stakeValue).lte(0)
      || new Big(stakeValue).gt(formattedPoolShares)
      || new Big(stakeValue).lte(formattedMinDepositShares)
    )
    : true;

  const onSubmit = async () => {
    if (
      Big(stakeValue).eq(0)
      || Big(stakeValue).gt(formattedPoolShares)
      || !pool
    ) return;

    if (!pool) return;
    const transactions = await farmContract.stake(
      pool.lpTokenId,
      stakeValue,
      pool,
    );

    await requestSignTransactions(transactions);
    closeModal();
  };

  const handleChange = (value: string) => {
    setStakeValue(value);
    if (!value) return;
    if (Big(value).lte(formattedMinDepositShares) && !Big(value).eq(0)) {
      setWarning(true);
      return;
    }
    setWarning(false);
  };

  return (
    <>
      <Layout onClick={closeModal}>
        <StakeModalContainer onClick={(e) => e.stopPropagation()}>
          <ModalBlock>
            <ModalTitle>
              {t('stakeModal.stake')}
            </ModalTitle>
            <ModalIcon onClick={closeModal}>
              <Close />
            </ModalIcon>
          </ModalBlock>
          <ModalBody>
            <TokensBlock>
              <TokenPairDisplay pool={pool} />
            </TokensBlock>
            <InputSharesContainer
              shares={formattedPoolShares}
              value={stakeValue}
              setValue={handleChange}
              isShowingButtonHalf={false}
            />
            {warning && (
            <Warning>
              {t('warningMessage.stakeMinDeposit')}
            </Warning>
            )}
            <RenderButton
              typeButton={CurrentButton.Stake}
              onSubmit={onSubmit}
              disabled={buttonDisabled}
              isFullHeight
            />
            <GetShareBtn
              isFullHeight
              onClick={() => showModal(EModals.ADD_LIQUIDITY_MODAL, { pool })}
            >
              Get share
            </GetShareBtn>
          </ModalBody>
        </StakeModalContainer>
      </Layout>
    </>
  );
};

export default StakeModal;
