import FarmContract from 'services/contracts/FarmContract';
import PoolContract from 'services/contracts/PoolContract';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  DEFAULT_PAGE_LIMIT, getPrices, retrieveBalancesMap, retrieveFarmsResult,
  retrieveFilteredTokenMetadata, retrieveNewPoolArray, retrievePoolResult,
  retrievePricesData, retrieveStakingData, retrieveTokenAddresses, retrieveUserDataByStaking,
} from 'store/helpers';
import {
  setLoading,
} from 'store/slices/jumboRuntime';
import ApiService from 'services/helpers/apiService';
import {
  IPool, PoolType, IFarm,
} from 'store/interfaces';
import {
  setBalances, setTokens, setPools, setPrices, setFarms,
} from 'store/slices';
import { setUserRewards } from 'store/slices/userRewards';
import {
  formatPool, formatFarm, isNotNullOrUndefined, onlyUniqueValues, toMap,
  calculateTotalAmountAndDayVolume, calcAprAndStakedAmount, formatStaking,
} from 'utils';
import getConfig from 'services/config';
import { IRPCProviderService } from 'services/RPCProviderService';
import { RootState } from 'store/redux-store';
import StakingContract from 'services/contracts/StakingContract';
import { setStakingData, setStakingUserData } from 'store/slices/staking';

const config = getConfig();

interface IInitialLoadingArgs {
  poolContract: PoolContract,
  farmContract: FarmContract,
  stakingContract: StakingContract,
  isSigned: boolean,
  accountId: string,
  RPCProvider: IRPCProviderService,
}

// eslint-disable-next-line import/prefer-default-export
export const initialLoading = createAsyncThunk<void, IInitialLoadingArgs, { state: RootState}>(
  'jumboRuntime/init',
  async (
    {
      poolContract, farmContract, stakingContract, isSigned, accountId, RPCProvider,
    },
    { dispatch },
  ) => {
    try {
      dispatch(setLoading(true));
      const [poolsLength, farmsLength] = await Promise.all(
        [poolContract.getNumberOfPools(), farmContract.getNumberOfFarms()],
      );
      const pages = Math.ceil(poolsLength / DEFAULT_PAGE_LIMIT);
      const farmsPages = Math.ceil(farmsLength / DEFAULT_PAGE_LIMIT);

      const [
        poolsResult,
        farmsResult,
        pricesData,
        seeds,
        dayVolumesData,
        listStaking,
        stakingSeedInfo,
      ] = await Promise.all([
        retrievePoolResult(pages, poolContract),
        retrieveFarmsResult(farmsPages, farmContract),
        getPrices(),
        farmContract.getSeeds(0, DEFAULT_PAGE_LIMIT),
        ApiService.getDayVolumeData(),
        stakingContract.getListFarmsBySeed(config.stakingSeedId),
        stakingContract.getSeedInfo(config.stakingSeedId),
      ]);
      const userTokens = await ApiService.getUserWalletTokens(accountId);

      const tokenAddresses = retrieveTokenAddresses(poolsResult, userTokens);
      const poolArray = poolsResult
        .map((pool: any) => formatPool(pool))
        .filter((pool: IPool) => (
          pool.type === PoolType.SIMPLE_POOL
            || (pool.type === PoolType.STABLE_SWAP && pool.id === config.stablePoolId)
        ));

      let newPoolArray = poolArray;

      const farmArray = farmsResult.map((farm: any) => formatFarm(farm, poolArray, seeds));

      let newFarmArray = farmArray.filter(isNotNullOrUndefined);

      const farmingTokens = newFarmArray.map((farm) => farm.rewardTokenId);
      const listStakingArray = listStaking
        ? listStaking.map((staking) => formatStaking(staking, stakingSeedInfo?.amount))
        : [];
      const stakingTokens = listStakingArray.map(({ rewardTokenId }) => rewardTokenId);

      const tokensMetadataFiltered = await retrieveFilteredTokenMetadata(
        onlyUniqueValues([...tokenAddresses, ...farmingTokens, ...stakingTokens]),
        RPCProvider,
        accountId,
      );

      const metadataMap = tokensMetadataFiltered.reduce(
        (acc, curr) => ({ ...acc, [curr.contractId]: curr }), {},
      );

      if (isSigned && accountId) {
        try {
          const farmStakedListByUser = await farmContract.getStakedListByAccountId();
          const [
            balancesMap,
            poolArrayWithShares,
            userRewardsFormFarms,
            ...farmsDataByUser
          ] = await Promise.all([
            retrieveBalancesMap(tokensMetadataFiltered, accountId),
            retrieveNewPoolArray(poolArray, poolContract),
            farmContract.getRewards(),
            ...newFarmArray.map(async (farm: IFarm) => {
              const userUnclaimedReward = await farmContract.getUnclaimedReward(farm.id);
              return {
                ...farm,
                userStaked: farmStakedListByUser[farm.seedId] || null,
                userUnclaimedReward,
              };
            }),
          ]);
          const userDataByStaking = await retrieveUserDataByStaking(
            stakingContract, listStakingArray, stakingSeedInfo,
          );

          dispatch(setBalances(balancesMap));
          newPoolArray = poolArrayWithShares;
          newFarmArray = farmsDataByUser;
          dispatch(setUserRewards(userRewardsFormFarms));
          dispatch(setStakingUserData(userDataByStaking));
        } catch (e) {
          console.warn(`Error: ${e} while initial loading user specific data`);
        }
      }

      const newPoolMap: {[key: number]: IPool} = newPoolArray.reduce((
        acc, pool,
      ) => {
        const poolWithFarm = newFarmArray
          .filter((farm) => farm.poolId === pool.id)
          .map((farm) => farm.id);

        const farmIds = poolWithFarm.length ? poolWithFarm : null;

        return {
          ...acc,
          [pool.id]: {
            ...pool,
            farms: farmIds,
          },
        };
      }, {});

      const newFarmMap = toMap(newFarmArray);
      if (
        newPoolMap[config.jumboPoolId]
        && pricesData
        && pricesData[config.nearAddress]
      ) {
        const pricesDataWithJumbo = retrievePricesData(pricesData, newPoolMap, metadataMap);
        const resultedPoolsArray = calculateTotalAmountAndDayVolume(
          pricesDataWithJumbo,
          metadataMap,
          newPoolMap,
          dayVolumesData,
        );
        const resultedFarmsArray = calcAprAndStakedAmount(
          pricesDataWithJumbo,
          metadataMap,
          resultedPoolsArray,
          newFarmMap,
        );
        const retrievedStakingData = retrieveStakingData(listStakingArray, stakingSeedInfo);
        dispatch(setTokens(metadataMap));
        dispatch(setPools(resultedPoolsArray));
        dispatch(setPrices(pricesDataWithJumbo));
        dispatch(setFarms(resultedFarmsArray));
        dispatch(setStakingData(retrievedStakingData));
      } else {
        dispatch(setTokens(metadataMap));
        dispatch(setPrices(pricesData));
        dispatch(setFarms(newFarmMap));
      }
    } catch (e) {
      console.warn(e);
    } finally {
      dispatch(setLoading(false));
    }
  },
);
