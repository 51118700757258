import { t } from 'i18next';

export type PoolStage = 'Created' | 'Running' | 'Ended'

export interface IRetrodropPool {
    id: number;
    icon: string;
    title: string;
    description: string;
    totalAmount: string;
    totalAmountClaimed: string;
    rewardToken: string;
    stage: PoolStage,
    claimAvailable: boolean,
    startDate: number,
    totalAccounts: number,
    amountToClaim: string,
    claimed: string;
    vesting: {steps:number, quota: number, date: number}[],
    conditions: string[]
}

export const questionsArray = [
  { title: t('retrodropPage.questions.questionTitle1'), subtitle: t('retrodropPage.questions.questionAnswer1') },
  { title: t('retrodropPage.questions.questionTitle2'), subtitle: t('retrodropPage.questions.questionAnswer2') },
  { title: t('retrodropPage.questions.questionTitle3'), subtitle: t('retrodropPage.questions.questionAnswer3') },
];
