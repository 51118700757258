import { t } from 'i18next';
import { ITabs } from 'pages/Pool/constants';
import { STAKING_ROUTES } from 'utils/routes';

export const stakingTabs: ITabs[] = [
  {
    title: t('staking.slider.stake'),
    value: STAKING_ROUTES.Stake,
  },
  {
    title: t('staking.slider.unstake'),
    value: STAKING_ROUTES.Unstake,
  },
];

export enum EStakeType {
  Stake,
  Unstake,
}
