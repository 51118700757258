import { createSearchParams, useSearchParams } from 'react-router-dom';

const useNavigateSwapParams = () => {
  const [, setSearchParams] = useSearchParams();

  return (inputToken: string, outputToken: string) => {
    const search = createSearchParams({
      inputToken,
      outputToken,
    });
    setSearchParams(search);
  };
};

export default useNavigateSwapParams;
