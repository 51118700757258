import styled from 'styled-components';
import { ReactComponent as LogoWallet } from 'assets/images-app/wallet.svg';
import { ReactComponent as AddIcon } from 'assets/images-app/icon-add.svg';
import { PropsWithChildren } from 'react';

export interface IFilterButton {
  isActive?: boolean
}

export const ButtonPrimary = styled.button<{ isFullHeight?: boolean}>`
  max-height: 3.5rem;
  height: ${({ isFullHeight }) => (isFullHeight ? '3.5rem' : 'auto')};
  outline: none;
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12.5px 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.188rem;
  border: 2px solid ${({ theme }) => theme.pink};
  background: ${({ theme }) => theme.pink};
  color: ${({ theme }) => theme.globalWhite};
  border-radius: 12px;
  :hover {
    cursor: pointer;
    border: 2px solid ${({ theme }) => theme.pinkHover};
    background: ${({ theme }) => theme.pinkHover};
  }
  :active {
    border: 2px solid ${({ theme }) => theme.pinkActive};
    background: ${({ theme }) => theme.pinkActive};
  }
  :disabled {
    cursor: default;
    background: ${({ theme }) => theme.globalGreyOp01};
    border: 1px solid ${({ theme }) => theme.globalGreyOp01};
    color: ${({ theme }) => theme.globalGrey};
    & svg > {
      path {
        fill: ${({ theme }) => theme.globalGrey};
      }
    }
  }

  ${({ theme, isFullHeight }) => theme.mediaWidth.upToExtraSmall`
    max-height: 2.75rem;
    height: ${isFullHeight ? '2.75rem' : 'auto'};
  `}
`;

export const ButtonSecondary = styled(ButtonPrimary)`
  background: transparent;
  border-color: ${({ theme }) => theme.darkPink};
  :hover {
    cursor: pointer;
    background: transparent;
    border-color: ${({ theme }) => theme.pinkHover};
  }
  :active {
    background: transparent;
    border-color: ${({ theme }) => theme.pinkActive};
  }
  :disabled{
    cursor: default;
    background: transparent;
    border: 1px solid ${({ theme }) => theme.globalGreyOp01};
    color: ${({ theme }) => theme.globalGrey};
    & svg > {
      path {
        fill: ${({ theme }) => theme.globalGrey};
      }
    }
  }
`;

export const ButtonThird = styled(ButtonPrimary)`
  background: ${({ theme }) => theme.greyButton};
  border: 2px solid ${({ theme }) => theme.greyButton};
  border-radius: 28px;
  padding: 9px 5px;
  :hover {
    cursor: pointer;
    background: ${({ theme }) => theme.greyButton};
    border: 2px solid ${({ theme }) => theme.greyButton};
  }
  :active {
    background: ${({ theme }) => theme.greyButton};
    border: 2px solid ${({ theme }) => theme.greyButton};
  }
`;

export const ButtonFourth = styled(ButtonPrimary)`
  border: none;
  background: ${({ theme }) => theme.globalGreyOp02};
  border-radius: 12px;
  :hover {
    cursor: pointer;
    background: ${({ theme }) => theme.globalGreyOp04};
    border: none;
  }
  :active {
    background: ${({ theme }) => theme.globalGreyOp04};
    border: none;
  }
`;

export const FilterButton = styled.button<PropsWithChildren<IFilterButton>>`
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.063rem;
  color: ${({ theme, isActive }) => (isActive ? theme.globalWhite : theme.globalGrey)};
  border: none;
  background: ${({ theme, isActive }) => (isActive ? theme.globalGreyOp02 : 'none')};
  border-radius: 4px;
  padding: 0.125rem 0.25rem;
  :hover {
    cursor: ${({ isActive }) => (isActive ? 'default' : 'pointer')};
  }
  :disabled {
    cursor: default;
    color: ${({ theme }) => theme.globalGreyOp02};
  }
`;

export const BtnPrimary = styled(ButtonPrimary)`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin: .75rem 0;
  `}
`;

export const BtnSecondary = styled(ButtonSecondary)`
  margin-right: .75rem;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin: 0;
  `}
`;

export const LogoButton = styled(AddIcon)`
  width: 12px;
  height: 12px;
  margin-right: .625rem;
`;

export const Wallet = styled(LogoWallet)`
  margin-right: .625rem;
  width: 16px;
  height: 12px;
  path {
    fill: ${({ theme }) => theme.globalWhite};
  }
`;

export const IconSwap = styled.div`
  display: flex;
  align-items: center;
  margin-left: .75rem;
`;

export const AddIconLogo = styled(AddIcon)<{ reversed: boolean }>`
  width: 12px;
  height: 12px;
  ${({ reversed }) => (reversed ? 'margin-right: .625rem;' : 'margin-left: .625rem;')}
`;

export const ClaimLogo = styled.div`
  display: flex;
  align-items: center;
  width: 12px;
  height: 12px;
  margin-right: .625rem;
`;
