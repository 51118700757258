import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFarm } from 'store/interfaces';
import { RootState } from 'store/redux-store';

interface IFarmsState {
  value: {[key: string]: IFarm}
}

const initialState: IFarmsState = { value: {} };

export const farmsSlice = createSlice({
  name: 'farms',
  initialState,
  reducers: {
    setFarms: (state, action: PayloadAction<{ [key:string]: IFarm }>) => {
      // eslint-disable-next-line no-param-reassign
      state.value = action.payload;
    },
  },
});

export const { setFarms } = farmsSlice.actions;
export const selectFarms = (state: RootState) => state.farms.value;

export default farmsSlice.reducer;
