import Big from 'big.js';
import { IPool, PoolType } from 'store';
import { formatTokenAmount } from 'utils/calculations';
import { FEE_DIVISOR } from 'utils/constants';
import FungibleTokenContract from 'services/contracts/FungibleToken';

const calculateTradeFee = (amount: number, tradeFee: number) => (amount * tradeFee) / FEE_DIVISOR;

export const calculateMarketPrice = (
  pool: IPool,
  tokenIn: FungibleTokenContract,
  tokenOut: FungibleTokenContract,
) => {
  if (pool.type === PoolType.STABLE_SWAP) {
    return '1';
  }

  if (!pool.supplies[tokenIn.contractId] || !pool.supplies[tokenOut.contractId]) return 0;
  const tokenInBalance = formatTokenAmount(
    pool.supplies[tokenIn.contractId],
    tokenIn.metadata.decimals,
  );

  const tokenOutBalance = formatTokenAmount(
    pool.supplies[tokenOut.contractId],
    tokenOut.metadata.decimals,
  );
  return Big(tokenInBalance).div(tokenOutBalance).toFixed();
};

export const calculateAmountReceived = (
  pool: IPool,
  amountIn: string,
  tokenIn: FungibleTokenContract,
  tokenOut: FungibleTokenContract,
) => {
  const partialAmountIn = amountIn;

  if (!pool.supplies[tokenIn.contractId] || !pool.supplies[tokenOut.contractId]) return Big(0);

  const inBalance = formatTokenAmount(
    pool.supplies[tokenIn.contractId],
    tokenIn.metadata.decimals,
  );
  const outBalance = formatTokenAmount(
    pool.supplies[tokenOut.contractId],
    tokenOut.metadata.decimals,
  );
  const bigInBalance = Big(inBalance);
  const bigOutBalance = Big(outBalance);

  const constantProduct = bigInBalance.mul(bigOutBalance);

  const newInBalance = bigInBalance.plus(partialAmountIn);

  const newOutBalance = constantProduct.div(newInBalance);

  const tokenOutReceived = bigOutBalance.minus(newOutBalance);

  return tokenOutReceived;
};

export const calcD = (amp: number, comparableAmountsArray: number[]) => {
  const tokenNumber = comparableAmountsArray.length;
  const sumAmounts = comparableAmountsArray.reduce((acc, item) => acc + item, 0);
  let dPrev = 0;
  let d = sumAmounts;
  for (let i = 0; i < 256; i += 1) {
    let dProd = d;
    for (let k = 0; k < comparableAmountsArray.length; k += 1) {
      dProd = (dProd * d) / (comparableAmountsArray[k] * tokenNumber);
    }
    dPrev = d;
    const ann = amp * tokenNumber ** tokenNumber;
    const numerator = dPrev * (dProd * tokenNumber + ann * sumAmounts);
    const denominator = dPrev * (ann - 1) + dProd * (tokenNumber + 1);
    d = numerator / denominator;
    if (Math.abs(d - dPrev) <= 1) break;
  }
  return d;
};

export const calcY = (
  amp: number,
  xcamount: number,
  currentComparableAmounts: {[key: string]: number},
  indexX: FungibleTokenContract,
  indexY: FungibleTokenContract,
) => {
  const cArray = Object.entries(currentComparableAmounts);
  const tokenNumber = cArray.length;
  const ann = amp * tokenNumber ** tokenNumber;
  const d = calcD(amp, Object.values(currentComparableAmounts));
  let s = xcamount;
  let c = (d * d) / xcamount;
  for (let i = 0; i < tokenNumber; i += 1) {
    const [tokenName] = cArray[i];
    if (tokenName !== indexX.contractId && tokenName !== indexY.contractId) {
      s += currentComparableAmounts[tokenName];
      c = (c * d) / currentComparableAmounts[tokenName];
    }
  }
  c = (c * d) / (ann * tokenNumber ** tokenNumber);
  const b = d / ann + s;
  let yPrev = 0;
  let y = d;
  for (let i = 0; i < 256; i += 1) {
    yPrev = y;
    const yNumerator = y ** 2 + c;
    const yDenominator = 2 * y + b - d;
    y = yNumerator / yDenominator;
    if (Math.abs(y - yPrev) <= 1) break;
  }

  return y;
};

export const calcSwap = (
  tokenInIdx: FungibleTokenContract,
  amountInComparable: number,
  tokenOutIdx: FungibleTokenContract,
  amountsAllComparable: {[key: string]: number},
  pool: IPool,
) => {
  const y = calcY(
    Number(pool.amp),
    amountInComparable + amountsAllComparable[tokenInIdx.contractId],
    amountsAllComparable,
    tokenInIdx,
    tokenOutIdx,
  );

  const dy = amountsAllComparable[tokenOutIdx.contractId] - y;
  const fee = calculateTradeFee(dy, pool.totalFee);
  const amountSwapped = Big(dy).minus(fee).toNumber();
  const amountSwappedOut = amountSwapped > 0 ? amountSwapped : 0;
  const noFeeAmount = dy > 0 ? dy : 0;

  return [amountSwappedOut, noFeeAmount];
};
