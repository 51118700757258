/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FungibleTokenContract from 'services/contracts/FungibleToken';
import { IPool, TokenType } from 'store/interfaces';
import { RootState } from 'store/redux-store';
import { SWAP_INPUT_KEY, SWAP_OUTPUT_KEY } from 'utils/constants';

interface IJumboRuntimeState {
  loading: boolean,
  currentPools: IPool[],
  inputToken: FungibleTokenContract | null,
  outputToken: FungibleTokenContract | null,
  refreshEnabled: boolean,
}

const initialState: IJumboRuntimeState = {
  loading: false,
  currentPools: [],
  inputToken: null,
  outputToken: null,
  refreshEnabled: true,
};

export const jumboRuntimeSlice = createSlice({
  name: 'jumboRuntime',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setInputToken: (state, action: PayloadAction<FungibleTokenContract | null>) => {
      state.inputToken = action.payload;
      localStorage.setItem(SWAP_INPUT_KEY, action.payload?.contractId || '');
    },
    setOutputToken: (state, action: PayloadAction<FungibleTokenContract | null>) => {
      state.outputToken = action.payload;
      localStorage.setItem(SWAP_OUTPUT_KEY, action.payload?.contractId || '');
    },
    setCurrentToken: (
      state,
      action: PayloadAction<{
        activeToken: FungibleTokenContract,
        availablePools: IPool[],
        type: TokenType,
      }>,
    ) => {
      const { activeToken, availablePools, type } = action.payload;
      if (type === TokenType.Output) {
        state.outputToken = activeToken;
      } else {
        state.inputToken = activeToken;
      }
      state.currentPools = availablePools;
    },
    setCurrentPools: (state, action: PayloadAction<IPool[]>) => {
      state.currentPools = action.payload;
    },
    setRefreshEnabled: (state, action: PayloadAction<boolean>) => {
      state.refreshEnabled = action.payload;
    },
  },
});

export const {
  setLoading,
  setInputToken,
  setOutputToken,
  setCurrentToken,
  setCurrentPools,
  setRefreshEnabled,
} = jumboRuntimeSlice.actions;
export const selectJumboRuntime = (state: RootState) => state.jumboRuntime;

export default jumboRuntimeSlice.reducer;
