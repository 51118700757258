import React, {
  MutableRefObject, useCallback, useRef, useState,
} from 'react';
import { ReactComponent as MenuIcon } from 'assets/images-app/menu.svg';
import ClaimArrow from 'assets/images-app/claim-arrow';
import RetrodropIcon from 'assets/images-app/retrodrop-icon';
import SwapIcon from 'assets/images-app/swap-icon';
import i18n from 'i18n';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { useNavigate } from 'react-router-dom';
import { SWAP_FUTURES, RETRODROP } from 'utils/routes';
import { colors } from 'theme';
import { isMobile, isTablet } from 'utils/userAgent';
import { EModals, useModalContext } from 'providers/modals-provider';
import {
  MenuFlyout, StyledMenu, StyledMenuButton, MenuItem, LogoContainer,
} from './styles';

export default function Menu() {
  const node = useRef<HTMLDivElement>();
  const [show, setShow] = useState<boolean>(false);
  const { showModal } = useModalContext();
  const handleClick = () => setShow(!show);
  const navigate = useNavigate();
  const [currentMenuItem, setCurrentMenu] = useState<string | null>(null);

  useOnClickOutside(node, show ? handleClick : null);

  const onMouseOver = useCallback((key: string) => {
    if (isMobile || isTablet) return;
    setCurrentMenu(key);
  }, [setCurrentMenu]);

  const onMouseOut = useCallback(() => {
    if (isMobile || isTablet) return;
    setCurrentMenu(null);
  }, [setCurrentMenu]);

  const menu = [
    {
      title: i18n.t('action.claim'),
      logo: ClaimArrow,
      onClick: () => {
        showModal(EModals.WITHDRAW_DEPOSIT_MODAL, {});
        setShow(false);
        setCurrentMenu(null);
      },
    },
    {
      title: i18n.t('action.swapFutures'),
      logo: SwapIcon,
      onClick: () => {
        navigate(SWAP_FUTURES);
        setShow(false);
        setCurrentMenu(null);
      },
    },
    {
      title: i18n.t('action.retrodrop'),
      logo: RetrodropIcon,
      onClick: () => {
        navigate(RETRODROP, { replace: true });
        setShow(false);
        setCurrentMenu(null);
      },
    },
  ];

  return (
    <StyledMenu ref={node as MutableRefObject<HTMLDivElement>}>
      <StyledMenuButton
        onClick={handleClick}
      >
        <MenuIcon />
      </StyledMenuButton>

      {show && (
        <MenuFlyout>
          {menu.map(({ title, logo: Logo, onClick }) => (
            <MenuItem
              key={title}
              onClick={onClick}
              onMouseOver={() => onMouseOver(title)}
              onMouseOut={onMouseOut}
            >
              <LogoContainer>
                <Logo color={currentMenuItem === title ? colors.globalWhite : colors.globalGrey} />
              </LogoContainer>
              <p>{title}</p>
            </MenuItem>
          ))}
        </MenuFlyout>
      )}
    </StyledMenu>
  );
}
