import { colors } from 'theme';

const RetrodropIcon = ({ color = colors.globalWhite }: { color?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.0858 5.28148C14.827 4.95803 14.3551 4.90559 14.0316 5.16435L12.7502 6.18951V2.75C12.7502 2.33579 12.4144 2 12.0002 2C11.586 2 11.2502 2.33579 11.2502 2.75V6.18955L9.96867 5.16435C9.64522 4.90559 9.17326 4.95803 8.9145 5.28148C8.65574 5.60493 8.70818 6.07689 9.03163 6.33565L11.5289 8.33349C11.6577 8.43763 11.8217 8.5 12.0002 8.5C12.1802 8.5 12.3455 8.43655 12.4747 8.33079L14.9687 6.33565C15.2921 6.07689 15.3446 5.60492 15.0858 5.28148Z"
      fill={color}
    />
    <circle
      cx="12"
      cy="14"
      r="3.25"
      stroke={color}
      strokeWidth="1.5"
    />
    <path
      d="M7.04854 9C5.7828 10.2721 5 12.029 5 13.9696C5 17.8524 8.13401 21 12 21C15.866 21 19 17.8524 19 13.9696C19 12.029 18.2172 10.2721 16.9515 9"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default RetrodropIcon;
