import Big from 'big.js';

import {
  ACCOUNT_MIN_STORAGE_AMOUNT,
  MIN_DEPOSIT_PER_TOKEN,
  ONE_MORE_DEPOSIT_AMOUNT,
  LP_STORAGE_AMOUNT,
  STORAGE_PER_TOKEN,
  ONE_YOCTO_NEAR,
  NEAR_TOKEN_ID,
  STABLE_LP_TOKEN_DECIMALS,
} from 'utils/constants';
import {
  percentLess, toComparableAmounts, toNonDivisibleNumber,
} from 'utils/calculations';
import { IPool, PoolType } from 'store';
import getConfig from 'services/config';
import {
  ILiquidityToken, IPoolVolumes, PoolContractMethod, ITransaction, PoolContractViewMethods,
} from 'services/interfaces';
import FungibleTokenContract from 'services/contracts/FungibleToken';
import { IRPCProviderService } from 'services/RPCProviderService';
import { calculateAddLiquidity } from './helpers';

export const registerTokensAction = (contractId: string, tokenIds: string[]) => ({
  receiverId: contractId,
  functionCalls: [{
    methodName: PoolContractMethod.registerTokens,
    args: { token_ids: tokenIds },
    amount: ONE_YOCTO_NEAR,
    gas: '30000000000000',
  }],
});

const config = getConfig();
const CREATE_POOL_NEAR_AMOUNT = '0.05';
const CONTRACT_ID = config.contractId;

export default class PoolContract {
  private provider: IRPCProviderService;

  private accountId: string;

  constructor(provider: IRPCProviderService, accountId: string) {
    this.provider = provider;
    this.accountId = accountId;
  }

  contractId = CONTRACT_ID;

  async createPool({ tokens, fee }: { tokens: FungibleTokenContract[]; fee: string }) {
    const transactions: ITransaction[] = [];
    const tokensStorages = await Promise.all(
      tokens.map((token) => token.checkSwapStorageBalance({ accountId: this.contractId })),
    );
    const tokensStoragesAmounts = tokensStorages.flat();
    if (tokensStoragesAmounts.length) {
      transactions.push(...tokensStoragesAmounts);
    }

    const formattedFee = new Big(fee).mul(100).toFixed(0, 0);
    transactions.push({
      receiverId: this.contractId,
      functionCalls: [
        {
          methodName: PoolContractMethod.addSimplePool,
          args: {
            tokens: tokens.map((token) => token.contractId),
            fee: Number(formattedFee),
          },
          amount: CREATE_POOL_NEAR_AMOUNT,
        },
      ],
    });

    return transactions;
  }

  async addLiquidity({
    tokenAmounts,
    pool,
    isSignedIn = false,
    slippage = '0',
  }: {
    tokenAmounts: ILiquidityToken[];
    pool: IPool;
    isSignedIn: boolean;
    slippage: string;
  }): Promise<ITransaction[] | undefined> {
    const transactions: ITransaction[] = [];
    const storageAmount = await this.checkStorageBalance();
    const [inputToken, outputToken] = tokenAmounts;
    const [firstTokenName, secondTokenName] = pool.tokenAccountIds;
    const firstToken = tokenAmounts.find((el) => el.token.contractId === firstTokenName);
    const secondToken = tokenAmounts.find((el) => el.token.contractId === secondTokenName);
    if (!firstToken || !secondToken) return undefined;

    const tokenInAmount = toNonDivisibleNumber(
      firstToken.token.metadata.decimals,
      firstToken.amount,
    );

    const tokenOutAmount = toNonDivisibleNumber(
      secondToken.token.metadata.decimals,
      secondToken.amount,
    );
    if (storageAmount.length) transactions.push(...storageAmount);
    const whitelistedTokens = await this.getWhitelistedTokens(isSignedIn);

    pool.tokenAccountIds.forEach((tokenId: string) => {
      if (!whitelistedTokens.includes(tokenId)) {
        transactions.push(registerTokensAction(this.contractId, [tokenId]));
      }
    });

    const isInputTokenStorage = await inputToken.token.transfer({
      accountId: this.contractId,
      inputToken: inputToken.token.contractId,
      amount: tokenInAmount,
    });
    if (isInputTokenStorage.length) transactions.push(...isInputTokenStorage);

    const isOutputTokenStorage = await outputToken.token.transfer({
      accountId: this.contractId,
      inputToken: outputToken.token.contractId,
      amount: tokenOutAmount,
    });
    if (isOutputTokenStorage.length) transactions.push(...isOutputTokenStorage);

    if (pool.type === PoolType.SIMPLE_POOL) {
      transactions.push({
        receiverId: this.contractId,
        functionCalls: [
          {
            methodName: PoolContractMethod.addLiquidity,
            args: { pool_id: pool.id, amounts: [tokenInAmount, tokenOutAmount] },
            amount: LP_STORAGE_AMOUNT,
          },
        ],
      });
    } else {
      const depositAmounts = [firstToken.amount, secondToken.amount]
        .map((amount) => Number(toNonDivisibleNumber(STABLE_LP_TOKEN_DECIMALS, amount)));
      const comparableAmounts = toComparableAmounts(pool.supplies, [
        firstToken.token,
        secondToken.token,
      ]);

      if (!comparableAmounts) return [];

      const [shares] = calculateAddLiquidity(
        Number(pool.amp),
        depositAmounts,
        Object.values(comparableAmounts),
        Number(pool.sharesTotalSupply),
        pool.totalFee,
      );

      const minShares = percentLess(slippage, Big(shares).toFixed(0), 0);

      transactions.push({
        receiverId: this.contractId,
        functionCalls: [
          {
            methodName: PoolContractMethod.addStableLiquidity,
            args: {
              pool_id: pool.id,
              amounts: [tokenInAmount, tokenOutAmount],
              min_shares: minShares,
            },
            amount: LP_STORAGE_AMOUNT,
          },
        ],
      });
    }

    return transactions;
  }

  async checkStorageState(accountId = this.accountId) {
    const storage = await this.getStorageState(accountId);
    return storage ? new Big(storage?.deposit).lte(new Big(storage?.usage)) : true;
  }

  async getStorageState(accountId = this.accountId) {
    return this.provider.viewFunction(PoolContractViewMethods.getUserStorageState, CONTRACT_ID, {
      account_id: accountId,
    });
  }

  async currentStorageBalance(accountId = this.accountId) {
    return this.provider.viewFunction(PoolContractViewMethods.storageBalanceOf, CONTRACT_ID, {
      account_id: accountId,
    });
  }

  async getNumberOfPools() {
    return this.provider.viewFunction(PoolContractViewMethods.getNumberOfPools, CONTRACT_ID);
  }

  async getPool(poolId: number) {
    return this.provider.viewFunction(PoolContractViewMethods.getPool, CONTRACT_ID, {
      pool_id: poolId,
    });
  }

  async getPools(from: number, limit: number) {
    return this.provider.viewFunction(PoolContractViewMethods.getPools, CONTRACT_ID, {
      from_index: from,
      limit,
    });
  }

  async checkStorageBalance(accountId = this.accountId) {
    const transactions: ITransaction[] = [];

    let storageAmount = new Big(0);

    const storageAvailable = await this.getStorageState(accountId);

    if (!storageAvailable) {
      storageAmount = new Big(ONE_MORE_DEPOSIT_AMOUNT);
    } else {
      const balance = await this.currentStorageBalance(accountId);

      if (!balance) {
        storageAmount = new Big(ACCOUNT_MIN_STORAGE_AMOUNT);
      }

      if (new Big(balance?.available || '0').lt(MIN_DEPOSIT_PER_TOKEN)) {
        storageAmount = storageAmount.plus(Number(STORAGE_PER_TOKEN));
      }
    }

    if (storageAmount.gt(0) && this.contractId !== NEAR_TOKEN_ID) {
      transactions.push({
        receiverId: this.contractId,
        functionCalls: [
          {
            methodName: PoolContractMethod.storageDeposit,
            args: {
              registration_only: false,
              account_id: accountId,
            },
            amount: storageAmount.toFixed(),
          },
        ],
      });
    }
    return transactions;
  }

  async removeLiquidity({
    pool,
    shares,
    minAmounts,
    tokens,
  }: {
    pool: IPool;
    shares: string;
    minAmounts: { [tokenId: string]: string };
    tokens: FungibleTokenContract[];
  }) {
    const transactions: ITransaction[] = [];
    const storageAmount = await this.checkStorageBalance();
    const storageDeposits = await Promise.all(
      tokens.map((token) => token.checkSwapStorageBalance({
        accountId: this.accountId,
      })),
    );

    if (storageDeposits.length) transactions.push(...storageDeposits.flat());

    if (storageAmount.length) transactions.push(...storageAmount);

    transactions.push({
      receiverId: this.contractId,
      functionCalls: [
        {
          methodName: PoolContractMethod.removeLiquidity,
          args: { pool_id: pool.id, shares, min_amounts: Object.values(minAmounts) },
          amount: ONE_YOCTO_NEAR,
        },
      ],
    });

    pool.tokenAccountIds.map((tokenId) => transactions.push({
      receiverId: this.contractId,
      functionCalls: [
        {
          methodName: PoolContractMethod.withdraw,
          args: {
            token_id: tokenId,
            amount: minAmounts[tokenId],
          },
          amount: ONE_YOCTO_NEAR,
        },
      ],
    }));

    return transactions;
  }

  async getPoolVolumes(pool: IPool) {
    const volumes = await this.provider.viewFunction(
      PoolContractViewMethods.getPoolVolumes,
      this.contractId,
      { pool_id: pool.id },
    );

    const sumValues = pool.tokenAccountIds.reduce((acc: IPoolVolumes, tokenId, i) => {
      acc[tokenId] = volumes[i];
      return acc;
    }, {});
    return sumValues;
  }

  async withdraw({
    claimList,
    tokens,
  }: {
    claimList: [string, string][];
    tokens: { [key: string]: FungibleTokenContract };
  }): Promise<ITransaction[] | undefined> {
    try {
      const transactions: ITransaction[] = [];
      const storageAmount = await this.checkStorageBalance();
      const tokensContracts = claimList.map(([id]) => tokens[id]);
      const storageDeposits = await Promise.all(
        tokensContracts.map((token) => token.checkSwapStorageBalance({
          accountId: this.accountId,
        })),
      );

      if (storageDeposits.length) transactions.push(...storageDeposits.flat());
      if (storageAmount.length) transactions.push(...storageAmount);

      claimList.forEach(([tokenId, value]) => {
        if (tokens[tokenId]) {
          transactions.push({
            receiverId: this.contractId,
            functionCalls: [
              {
                methodName: PoolContractMethod.withdraw,
                args: {
                  token_id: tokenId,
                  amount: value,
                },
                amount: ONE_YOCTO_NEAR,
              },
            ],
          });
        }
      });

      return transactions;
    } catch (e) {
      console.warn(`${e} during withdrawing`);
      return [];
    }
  }

  async getWhitelistedTokens(isSignedIn: boolean) {
    let userWhitelist = [];
    const globalWhitelist = await this.provider
      .viewFunction(PoolContractViewMethods.getWhitelistedTokens, CONTRACT_ID);
    if (isSignedIn) {
      userWhitelist = await this.provider
        .viewFunction(PoolContractViewMethods.getUserWhitelistedTokens, CONTRACT_ID, {
          account_id: this.accountId,
        });
    }
    const tokenList = [...globalWhitelist, ...userWhitelist];
    const uniqueTokens = new Set(tokenList);

    return Array.from(uniqueTokens);
  }

  async getSharesInPool(poolId: any, accountId = this.accountId) {
    return this.provider.viewFunction(PoolContractViewMethods.getPoolShares, CONTRACT_ID, {
      pool_id: poolId,
      account_id: accountId,
    });
  }

  async getDeposits(accountId = this.accountId) {
    const deposits = await this.provider.viewFunction(
      PoolContractViewMethods.getDeposits, CONTRACT_ID, {
        account_id: accountId,
      },
    );

    const blacklistedTokensMap = config.blacklistedTokens.reduce(
      (acc, tokenId) => ({ ...acc, [tokenId]: '0' }), {},
    );
    return { ...deposits, ...blacklistedTokensMap };
  }
}
