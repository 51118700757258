/* eslint-disable camelcase */
import FungibleTokenContract from 'services/contracts/FungibleToken';
import { PoolStage } from 'pages/Retrodrop/constants';

export interface ITransaction {
  receiverId: string;
  functionCalls: { gas?:
    string; amount?: string;
    methodName: string;
    args?: object;
  }[];
}

export enum FTTokenContractMethod {
  ftTransferCall = 'ft_transfer_call',
  nearDeposit = 'near_deposit',
  nearWithdraw = 'near_withdraw',
  storageDeposit = 'storage_deposit',
}

export const FTViewMethod = {
  storageBalanceOf: 'storage_balance_of',
  FTMetadata: 'ft_metadata',
  FTBalanceOf: 'ft_balance_of',
};

export enum SwapContractMethod {
  ftTransferCall = 'ft_transfer_call',
}

export const SwapContractViewMethod = {
  getReturn: 'get_return',
};

export enum PoolContractMethod {
  ftTransferCall = 'ft_transfer_call',
  storageDeposit = 'storage_deposit',
  registerTokens = 'register_tokens',
  addSimplePool = 'add_simple_pool',
  addLiquidity = 'add_liquidity',
  addStableLiquidity = 'add_stable_liquidity',
  removeLiquidity = 'remove_liquidity',
  withdraw = 'withdraw',
}

export const PoolContractViewMethods = {
  getUserStorageState: 'get_user_storage_state',
  storageBalanceOf: 'storage_balance_of',
  getNumberOfPools: 'get_number_of_pools',
  getPool: 'get_pool',
  getPools: 'get_pools',
  getPoolVolumes: 'get_pool_volumes',
  getWhitelistedTokens: 'get_whitelisted_tokens',
  getUserWhitelistedTokens: 'get_user_whitelisted_tokens',
  getPoolShares: 'get_pool_shares',
  getDeposits: 'get_deposits',
};

export const ClaimContractViewMethod = {
  getPoolsCount: 'get_pools_count',
  getPools: 'get_pools',
  getAccount: 'get_account',
};

export enum ClaimContractMethod {
  claim = 'claim'
}

export const FarmContractMethod = {
  storageDeposit: 'storage_deposit',
  mftTransferCall: 'mft_transfer_call',
  withdrawSeed: 'withdraw_seed',
  withdrawReward: 'withdraw_reward',
  claimRewardBySeed: 'claim_reward_by_seed',
};

export const FarmContractViewMethod = {
  getNumberOfFarms: 'get_number_of_farms',
  listFarms: 'list_farms',
  getReward: 'get_reward',
  listRewards: 'list_rewards',
  listUserSeeds: 'list_user_seeds',
  listSeeds: 'list_seeds',
  getUnclaimedReward: 'get_unclaimed_reward',
  storageBalanceOf: 'storage_balance_of',
  getFarm: 'get_farm',
};

export const SwapFuturesContractViewMethod = {
  getPool: 'get_pool',
  getPools: 'get_pools',
};

export enum SWAP_ENUM { DIRECT_SWAP = 1, INDIRECT_SWAP = 2 }

export interface IPoolVolumes {
  [tokenId: string]: { input: string; output: string };
}

export interface ILiquidityToken {
  token: FungibleTokenContract;
  amount: string
}

export interface IClaimingPool {
  pool_id: number;
  metadata: {
    title: string;
    icon: string;
    description: string;
    conditions: string[];
  };
  total_amount: string;
  total_amount_claimed: string;
  reward_token: string;
  stage: PoolStage;
  claim_available: boolean;
  start_date: number;
  total_accounts: number;
  vesting: {steps: number, quota: number, date: number}[];
}

export interface IClaimingAccount {
  total_amount: string,
  claimed: string,
}

export const StakingContractMethod = {
  ...FarmContractMethod,
  ftTransferCall: 'ft_transfer_call',
};

export const StakingContractViewMethod = {
  listFarmsBySeed: 'list_farms_by_seed',
  getSeedInfo: 'get_seed_info',
  listUserSeeds: 'list_user_seeds',
  getUnclaimedReward: 'get_unclaimed_reward',
  getUserRps: 'get_user_rps',
  storageBalanceOf: 'storage_balance_of',
  getReward: 'get_reward',
};

export interface FarmOutput {
  farm_id: string
  farm_kind: string
  farm_status: string
  seed_id: string
  reward_token: string
  start_at: number
  reward_per_session: string
  session_interval: number
  total_reward: string
  cur_round: number
  last_round: number
  claimed_reward: string
  unclaimed_reward: string
  beneficiary_reward: string
}

export enum ESeedType {
  FT = 'FT',
  MFT = 'MFT'
}
export interface SeedInfo {
  seed_id: string,
  seed_type: ESeedType,
  farms: Array<string>,
  next_index: number,
  amount: string,
  min_deposit: string,
}
