import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITokenPrice } from 'store/interfaces';
import { RootState } from 'store/redux-store';

interface IPricesState {
  value: {[key:string]: ITokenPrice}
}

const initialState: IPricesState = { value: {} };

export const pricesSlice = createSlice({
  name: 'prices',
  initialState,
  reducers: {
    setPrices: (state, action: PayloadAction<{[key:string]: ITokenPrice}>) => {
      // eslint-disable-next-line no-param-reassign
      state.value = action.payload;
    },
  },
});

export const { setPrices } = pricesSlice.actions;
export const selectPrices = (state: RootState) => state.prices.value;

export default pricesSlice.reducer;
