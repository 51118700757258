import { useState, useMemo, useCallback } from 'react';
import { ReactComponent as Info } from 'assets/images-app/info.svg';
import { isMobile, isTablet } from 'utils/userAgent';
import { EModals, useModalContext } from 'providers/modals-provider';
import { HoverContent, ApyContainer, Container } from './styles';

export default function Tooltip(
  {
    children,
    title,
    bottom,
  }:{
    children?: JSX.Element;
    title: string | JSX.Element[];
    bottom?: string;
  },
) {
  const { showModal, modal: currentModal } = useModalContext();
  const [show, setShow] = useState(false);

  const isString = useMemo(() => typeof (title) === 'string', [title]);

  const onMouseOver = useCallback(() => {
    if (isMobile || isTablet) return;
    setShow(true);
  }, [setShow]);

  const onMouseOut = useCallback(() => {
    if (isMobile || isTablet) return;
    setShow(false);
  }, [setShow]);

  const handleClick = useCallback(() => {
    if (isMobile || isTablet) {
      showModal(EModals.TOOLTIP_MODAL, { title });
    }
  }, [title, showModal]);

  const isTooltipModalOpen = currentModal === EModals.TOOLTIP_MODAL;

  return (
    <Container
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      onClick={handleClick}
    >
      {children || <Info />}
      {show && !isTooltipModalOpen
        && (
        <HoverContent className={show ? 'show' : ''} bottom={bottom}>
          {isString ? title : <ApyContainer>{title}</ApyContainer>}
        </HoverContent>
        )}
    </Container>
  );
}
