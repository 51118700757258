export default function getConfig(
  env: string | undefined = process.env.REACT_APP_NEAR_ENV,
) {
  switch (env) {
    case 'development':
    case 'testnet':
      return {
        networkId: 'testnet',
        nodeUrl: 'https://rpc.testnet.near.org',
        walletUrl: 'https://wallet.testnet.near.org',
        helperUrl: 'https://testnet-api.kitwallet.app',
        explorerUrl: 'https://explorer.testnet.near.org',
        indexerUrl: 'https://jumbo-ps.stage.hapi.farm',
        contractId: 'jumbo-exchange-v1.rkonoval.testnet',

        farmContractId: 'jumbo-farming-v2.rkonoval.testnet',
        swapFuturesContractId: 'swap.rkonoval.testnet',

        nearAddress: 'wrap.testnet',
        jumboAddress: 'jumbo_exchange_v2.solniechniy.testnet',
        jumboPoolId: 1,
        stablePoolId: 13,
        futuresPoolId: 0,

        claimContract: 'claiming-v2.rkonoval.testnet',
        blacklistedTokens: [''],
        stakingContractId: 'sarap.testaking.testnet',
        stakingSeedId: 'token.testaking.testnet',
      };
    default:
      return {
        networkId: 'mainnet',
        nodeUrl: 'https://rpc.mainnet.near.org',
        walletUrl: 'https://wallet.near.org',
        helperUrl: 'https://api.kitwallet.app',
        explorerUrl: 'https://explorer.mainnet.near.org',
        indexerUrl: 'https://price-service.jumbo.exchange',
        contractId: 'v1.jumbo_exchange.near',

        farmContractId: 'farming-v1.jumbo_exchange.near',
        swapFuturesContractId: 'futures-v1.jumbo_exchange.near',

        nearAddress: 'wrap.near',
        jumboAddress: 'token.jumbo_exchange.near',
        jumboPoolId: 4,
        stablePoolId: 248,
        futuresPoolId: 0,

        claimContract: 'claiming-v1.jumbo_exchange.near',
        blacklistedTokens: [
          'nearx.stader-labs.near',
        ],
        stakingContractId: 'staking-v1.jumbo_exchange.near',
        stakingSeedId: 'token.jumbo_exchange.near',
      };
  }
}

export const config = getConfig();
