import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { ReactComponent as Soon } from 'assets/images-app/logo-soon.svg';

export const FilterBlock = styled.div`
  display: flex;
  align-content: center;
  white-space: nowrap;
  a {
    text-decoration: none;
  }
  button {
    margin: 0 .625rem;
  }
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    button {
      margin: 0 .25rem;
    }
  `}
`;

interface IFilterButton {
  isActive?: boolean
}

export const LogoSoon = styled(Soon)`
  margin-left: .25rem;
`;

export const FilterButton = styled.button<PropsWithChildren<IFilterButton>>`
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.063rem;
  color: ${({ theme, isActive }) => (isActive ? theme.globalWhite : theme.globalGrey)};
  border: none;
  background: ${({ theme, isActive }) => (isActive ? theme.globalGreyOp02 : 'none')};
  border-radius: 4px;
  padding: 0.125rem 0.25rem;
  :hover {
    cursor: ${({ isActive }) => (isActive ? 'default' : 'pointer')};
  }
  :disabled {
    cursor: default;
    color: ${({ theme }) => theme.globalGreyOp02};
  }
`;
