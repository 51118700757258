export const LANDING = '/';
export const SWAP = 'swap';
export const POOL = 'pool';
export const STAKING = 'staking';
export const RETRODROP = 'retrodrop';
export const SWAP_FUTURES = 'swap-futures';
export const ALL_MATCH = '/*';

export const toPoolSlider = (slide: string) => `/${POOL}/${slide}`;

export const POOL_ROUTES = {
  AllPools: 'allPools',
  YourLiquidity: 'yourLiquidity',
  Farming: 'farming',
  SmartPools: 'smartPools',
};

export const toStakingSlider = (slide: string) => `/${STAKING}/${slide}`;

export const STAKING_ROUTES = {
  Stake: 'stake',
  Unstake: 'unstake',
};
