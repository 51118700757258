import Big, { BigSource } from 'big.js';
import FungibleTokenContract from 'services/contracts/FungibleToken';
import { IPool, ITokenPrice } from 'store/interfaces';
import { toArray } from 'utils';
import { FEE_DIVISOR, STABLE_LP_TOKEN_DECIMALS } from './constants';

const BASE = 10;
Big.RM = Big.roundDown;
Big.DP = 30;

export const round = (decimals: number, minAmountOut: string) => (
  Number.isInteger(Number(minAmountOut))
    ? minAmountOut
    : Math.ceil(
      Math.round(Number(minAmountOut) * (BASE ** decimals))
          / (BASE ** decimals),
    ).toString());

export const toNonDivisibleNumber = (
  decimals: number,
  number: string,
): string => {
  if (decimals === null || decimals === undefined) return number;
  const [wholePart, fracPart = ''] = number.split('.');

  return `${wholePart}${fracPart.padEnd(decimals, '0').slice(0, decimals)}`
    .replace(/^0+/, '')
    .padStart(1, '0');
};

export const formatTokenAmount = (value: string | number,
  decimals = 18, precision?: number): string => {
  if (!value) return '0';
  return Big(value).div(Big(BASE).pow(decimals)).toFixed(precision);
};

export const parseTokenAmount = (value:string, decimals = 18) => value
  && Big(value).times(Big(BASE).pow(decimals)).toFixed(0);

export const removeTrailingZeros = (amount: string) => {
  if (amount.includes('.') || amount.includes(',')) {
    return amount.replace(/\.?0*$/, '');
  }
  return amount;
};

export const percentLess = (
  percent: number | string,
  num: number | string,
  precision: number = 6,
): string => {
  const FULL_AMOUNT_PERCENT = 100;
  const percentDiff = Big(FULL_AMOUNT_PERCENT).minus(percent || '0');
  return Big(num).div(FULL_AMOUNT_PERCENT).mul(percentDiff).toFixed(precision);
};

export const percent = (numerator: string, denominator: string) => Big(numerator)
  .div(denominator).mul(100);

export function scientificNotationToString(strParam: string) {
  const flag = /e/.test(strParam);
  if (!flag) return strParam;

  let symbol = true;
  if (/e-/.test(strParam)) {
    symbol = false;
  }

  const negative = Number(strParam) < 0 ? '-' : '';

  const index = Number((strParam).match(/\d+$/)?.[0] ?? '0');

  // eslint-disable-next-line no-useless-escape
  const basis = strParam.match(/[\d\.]+/)?.[0] ?? '0';

  const ifFraction = basis.includes('.');

  let wholeStr;
  let fractionStr;

  if (ifFraction) {
    [wholeStr, fractionStr] = basis.split('.');
  } else {
    wholeStr = basis;
    fractionStr = '';
  }

  if (symbol) {
    if (!ifFraction) {
      return negative + wholeStr.padEnd(index + wholeStr.length, '0');
    }
    if (fractionStr.length <= index) {
      return negative + wholeStr + fractionStr.padEnd(index, '0');
    }
    return (
      `${negative
            + wholeStr
            + fractionStr.substring(0, index)
      }.${
        fractionStr.substring(index)}`
    );
  }
  if (!ifFraction) {
    return (
      negative
          + wholeStr.padStart(index + wholeStr.length, '0').replace(/^0/, '0.')
    );
  }

  return (
    negative
          + wholeStr.padStart(index + wholeStr.length, '0').replace(/^0/, '0.')
          + fractionStr
  );
}

export const calculateFairShare = (
  totalSupply: string,
  shares: BigSource,
  sharesTotalSupply: BigSource,
) => {
  const mul = new Big(totalSupply).mul(shares);
  const div = new Big(mul).div(sharesTotalSupply);
  return div.toFixed();
};

export const formatBalance = (value: string): string => {
  const formattedValue = new Big(value);
  if (!value || formattedValue.eq(0)) return value;

  if (formattedValue.lte('0.00001')) return '>0.00001';
  if (formattedValue.lt('1000')) return formattedValue.toFixed(5);
  if (formattedValue.gt('100000')) return formattedValue.toFixed(1);
  return formattedValue.toFixed(0);
};

export const checkInvalidAmount = (
  balances: {[key:string]: string},
  token: FungibleTokenContract | null,
  amount: string,
) => {
  if (amount === '') return true;
  if (!token || !toArray(balances).length) return false;
  const balance = token ? balances[token.contractId] : '0';
  return Big(amount).gt(formatTokenAmount(balance, token.metadata.decimals));
};

export const normalizedTradeFee = (
  tokenNumber: number,
  amount: number,
  tradeFee: number,
) => {
  const adjustedTradeFee = Number(
    Math.floor((tradeFee * tokenNumber) / (4 * (tokenNumber - 1))),
  );
  return (amount * adjustedTradeFee) / FEE_DIVISOR;
};

export const toCompAmount = (
  token: FungibleTokenContract, amount: string | number,
): number => {
  try {
    return Big(amount).mul(Big(BASE).pow(
      STABLE_LP_TOKEN_DECIMALS - token.metadata.decimals,
    )).toNumber();
  } catch (e) {
    console.warn(`Error while converting to comparable amount ${token.contractId} ${amount}`);
    return 0;
  }
};

export const fromCompToDecimals = (
  amount: string|number, token: FungibleTokenContract,
): string => {
  try {
    return Big(amount)
      .mul(Big(BASE).pow(token.metadata.decimals))
      .div(Big(BASE).pow(STABLE_LP_TOKEN_DECIMALS))
      .toFixed(0);
  } catch (e) {
    console.warn(`Error while converting from comparable amount ${token.contractId} ${amount}`);
    return '0';
  }
};

export const toComparableAmounts = (
  supplies: { [key: string]: string }, tokens: FungibleTokenContract[],
): {[key:string]: number} | null => {
  try {
    const suppliesArray = Object.entries(supplies);
    return suppliesArray.reduce((acc, [tokenId, supply]) => {
      const token = tokens.find((el) => el.contractId === tokenId);
      if (!token) return 0;
      return {
        ...acc,
        [tokenId]: toCompAmount(token, supply),
      };
    }, {});
  } catch (e) {
    return null;
  }
};

export const calcYourLiquidity = (
  tokensData: {[key:string]: FungibleTokenContract},
  pricesData: {[key: string]: ITokenPrice},
  pool: IPool,
) => {
  const [tokenInputName, tokenOutputName] = pool.tokenAccountIds;
  const tokenInput = tokensData[tokenInputName] ?? null;
  const tokenOutput = tokensData[tokenOutputName] ?? null;
  const priceInputToken = pricesData[tokenInputName]?.price ?? 0;
  const priceOutputToken = pricesData[tokenOutputName]?.price ?? 0;

  if (!tokenInput || !tokenOutput) return null;

  const checkTotalSupply = pool?.sharesTotalSupply === '0' ? '1' : pool?.sharesTotalSupply;

  const minAmounts = Object.entries(pool.supplies).reduce<{
    [tokenId: string]: string;
  }>((acc, [tokenId, totalSupply]) => {
    acc[tokenId] = calculateFairShare(
      totalSupply,
      pool.shares || '0',
      checkTotalSupply,
    );
    return acc;
  }, {});
  const [inputToken, outputToken] = Object.values(minAmounts).map((el) => el);

  const inputAmount = formatTokenAmount(
    Big(inputToken).mul(priceInputToken).toFixed(),
    tokenInput.metadata.decimals,
  );
  const outputAmount = formatTokenAmount(
    Big(outputToken).mul(priceOutputToken).toFixed(),
    tokenOutput.metadata.decimals,
  );

  const yourLiquidityAmount = Big(inputAmount).plus(outputAmount).toFixed();
  return yourLiquidityAmount;
};

export const displayPriceWithComma = (str: string) => str.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
export const displayPriceWithSpace = (str: string) => str.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const displayAmount = (amount: string) => {
  const amountBig = new Big(amount);
  if (amountBig.eq('0')) return '-';
  if (amountBig.lte('0.01')) return '>0.01';
  if (amountBig.lte('5')) return `${removeTrailingZeros(amountBig.toFixed(2))}`;
  return `${displayPriceWithSpace(amountBig.toFixed(0))}`;
};

export const toInternationalCurrencySystem = (labelValue: string | number, precision = 0) => {
  const num = Math.abs(Number(labelValue));
  if (num >= 1.0e9) {
    return {
      value: Number((num / 1.0e9).toFixed(precision)),
      suffix: 'b',
    };
  }
  if (num >= 1.0e6) {
    return {
      value: Number((num / 1.0e6).toFixed(precision)),
      suffix: 'm',
    };
  }
  if (num >= 1.0e3) {
    return {
      value: Number((num / 1.0e3).toFixed(precision)),
      suffix: 'k',
    };
  }
  return {
    value: Number(num.toFixed(precision)),
  };
};
