import { FarmStatusEnum } from 'components/FarmStatus';
import { IPoolVolumes } from 'services/interfaces';

export enum StatusLink { Swap = 'swap', Pool ='pool', Farm = 'farm' }
export enum TokenType { 'Input', 'Output'}
export enum PoolType {'SIMPLE_POOL' = 'SIMPLE_POOL', 'STABLE_SWAP' = 'STABLE_SWAP'}
export enum CurrentButton {
  'Swap',
  'AddLiquidity',
  'CreatePool',
  'Withdraw',
  'Stake',
  'Unstake',
  'ClaimRetrodrop'
}

export interface IPool {
  id: number;
  lpTokenId: string;
  lpTokenDecimals: number;
  type: PoolType;
  tokenAccountIds: string[];
  amounts: string[];
  totalFee: number;
  sharesTotalSupply: string;
  supplies: { [key: string]: string };
  amp: string;

  shares?: string;
  volumes?: IPoolVolumes ;
  farms: string[] | null,

  totalLiquidity: string;
  dayVolume: string;
  apy: string;
}

export interface ITokenMetadata {
  version: string;
  name: string;
  symbol: string;
  reference: string;
  decimals: number;
  icon: string;
}

export interface ITokenPrice {
    id: string,
    decimal: number,
    price: string,
    symbol: string
}

export interface IFarm {
  id: number;
  type: string;
  status: FarmStatusEnum;
  seedId: string;
  rewardTokenId: string;
  startAt: number;
  rewardPerSession: string;
  sessionInterval: number;
  totalReward: string;
  curRound: number;
  lastRound: number;
  claimedReward: string;
  unclaimedReward: string;

  poolId: number;
  totalSeedAmount: string;

  userStaked?: string;
  userUnclaimedReward?: string;
  totalStaked?: string;
  yourStaked?: string;
  apy: string;
}

export interface IDayVolume {
  id: string,
  volume24hFirst: string,
  volume24hSecond: string,
  tokenFirst: string,
  tokenSecond: string,
  updatedAt: string,
}

export enum FuturesPoolEnum {
  Created = 'Created',
  Running = 'Running',
  Empty = 'Empty',
}
export interface IFuturesPool {
  id: number,
  metadata: {
    title: string,
    description: string,
    icon: string,
  }
  stage: FuturesPoolEnum,
  tokenIn: {
    id: string,
    decimals: number,
  },
  tokenOut: {
    id: string,
    decimals: number,
  },
  totalSwappedAmount: string,
  totalAvailableAmount: string
}

export interface IFormattedStaking {
  id: number;
  stakeId: string;
  type: string;
  status: FarmStatusEnum;
  seedId: string;
  rewardTokenId: string;
  startAt: number;
  endAt: number;
  rewardPerSession: string;
  sessionInterval: number;
  totalReward: string;
  curRound: number;
  lastRound: number;
  claimedReward: string;
  unclaimedReward: string;
  beneficiaryReward: string;
  rewardPerSecond: string;
  apy: string;
}

export interface IUserStakingData {
  userUnclaimedReward: string;
  yourStaked: string;
  rewardPerSecond: string;
  claimedReward: string
}
export interface IStaking {
  rewardTokenId: string;
  startAt: number;
  endAt: number;
  rewardPerSecond: string;
  apy: string;
  totalStaked: string;
  minStaked: string,
}
