import { colors } from 'theme';

const ClaimArrow = ({ color = colors.globalWhite }: { color?: string }) => (
  <svg
    width="10"
    height="14"
    viewBox="0 0 10 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.75 11.1893L8.46967 8.46967C8.76256 8.17678 9.23744 8.17678 9.53033 8.46967C9.82322 8.76256 9.82322 9.23744 9.53033 9.53033L5.53033 13.5303C5.23744 13.8232 4.76256 13.8232 4.46967 13.5303L0.46967 9.53033C0.176777 9.23744 0.176777 8.76256 0.46967 8.46967C0.762563 8.17678 1.23744 8.17678 1.53033 8.46967L4.25 11.1893L4.25 1.5C4.25 1.08579 4.58579 0.75 5 0.75C5.41421 0.75 5.75 1.08579 5.75 1.5L5.75 11.1893Z"
      fill={color}
    />
  </svg>

);

export default ClaimArrow;
