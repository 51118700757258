import { useCallback, useMemo, useState } from 'react';
import { ReactComponent as JumboLogo } from 'assets/images-app/jumbo-token-retrodrop.svg';
import { ReactComponent as Star } from 'assets/images-app/star.svg';
import FungibleTokenContract from 'services/contracts/FungibleToken';
import { getToken } from 'store/helpers';
import { formatBalance, formatTokenAmount, removeTrailingZeros } from 'utils/calculations';

import ClaimingContract from 'services/contracts/ClaimingContract';
import { t } from 'i18next';
import { useWalletSelector } from 'providers/wallet-provider';
import {
  PoolCardWrapper,
  RowItem, Row,
  CardSubtitle, CardTitle,
  ConditionsWrapper,
  Wrapper, ConditionsTitle,
  Arrow, ConditionsList, ConditionBlock, ConditionListContainer,
} from './styles';
import { IRetrodropPool } from '../constants';
import { ActionRow, RetrodropStatuses, getRetrodropCardStatus } from './helpers';

export default function RetrodropCard(
  { pool, tokens }: {pool: IRetrodropPool, tokens: {[key:string]: FungibleTokenContract}},
) {
  const [isOpen, setIsOpen] = useState(false);
  const token = getToken(pool.rewardToken, tokens);
  const tokenDecimals = token?.metadata.decimals || 0;
  const tokenName = token?.metadata.symbol || pool.rewardToken;
  const {
    isSignedIn, RPCProvider, accountId, requestSignTransactions,
  } = useWalletSelector();
  const isConnected = isSignedIn();
  const tokenAmount = formatTokenAmount(pool.amountToClaim, tokenDecimals);
  const amountToClaim = isConnected
    ? `${removeTrailingZeros(formatBalance(tokenAmount))} ${tokenName}`
    : '-';

  const status: RetrodropStatuses = getRetrodropCardStatus(pool, isConnected);
  const claimingContract = useMemo(
    () => new ClaimingContract(RPCProvider, accountId),
    [RPCProvider, accountId],
  );
  const onClaimReward = useCallback(
    async () => {
      if (!token) return;
      const transactions = await claimingContract.claim(pool.id, token);
      await requestSignTransactions(transactions);
    },
    [pool, claimingContract, token, requestSignTransactions],
  );

  return (
    <Wrapper>
      <PoolCardWrapper>
        <Row>
          <RowItem>
            <JumboLogo />
            <CardTitle>{pool.title}</CardTitle>
          </RowItem>
          <RowItem>
            <CardSubtitle>
              {pool.totalAccounts} {t('retrodropPage.card.winners')}
            </CardSubtitle>
          </RowItem>
        </Row>
        <Row>
          <ActionRow
            status={status}
            amountToClaim={amountToClaim}
            onClick={onClaimReward}
            startDate={pool.startDate}
            claimAvailable={pool.claimAvailable}
          />
        </Row>
      </PoolCardWrapper>
      <ConditionsWrapper onClick={() => setIsOpen(!isOpen)}>
        <ConditionsTitle>Conditions
        </ConditionsTitle>
        <Arrow reversed={isOpen} />
      </ConditionsWrapper>
      <ConditionsList isOpen={isOpen}>
        <ConditionListContainer>
          {pool.conditions.map((el) => (
            <ConditionBlock key={el}>
              <Star /><p>{el}</p>
            </ConditionBlock>
          ))}
        </ConditionListContainer>
      </ConditionsList>
    </Wrapper>
  );
}
