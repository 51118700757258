/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPool } from 'store/interfaces';
import { RootState } from 'store/redux-store';
import merge from 'lodash/merge';

interface IPoolsState {
  value: {[key:string]: IPool}
}

const initialState: IPoolsState = { value: {} };

export const poolsSlice = createSlice({
  name: 'pools',
  initialState,
  reducers: {
    setPools: (state, action: PayloadAction<{[key:string]: IPool}>) => {
      state.value = action.payload;
    },
    updatePools: (state, action: PayloadAction<IPool[]>) => {
      const newPools: IPool[] = action.payload;
      const newPoolSet = newPools.reduce((acc, item) => (
        {
          ...acc,
          [item.id]: merge(item, acc[item.id]),
        }), state.value);

      state.value = newPoolSet;
    },
  },
});

export const { setPools, updatePools } = poolsSlice.actions;
export const selectPools = (state: RootState) => state.pools.value;

export default poolsSlice.reducer;
