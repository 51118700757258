import Big from 'big.js';
import { t } from 'i18next';
import FungibleTokenContract from 'services/contracts/FungibleToken';
import { CurrentButton, IStaking, IUserStakingData } from 'store';
import { getParseDate } from 'utils/calculationDate';
import { displayAmount, formatTokenAmount, parseTokenAmount } from 'utils/calculations';
import {
  DASH, SECONDS_IN_MONTH, THOUSAND, ZERO_AMOUNT,
} from 'utils/constants';
import { EStakeType } from './constants';

export const getUserData = (decimals: number, user: IUserStakingData | undefined) => {
  if (!user) {
    return {
      reward: DASH,
      yourStaked: DASH,
      rewardPerMonth: DASH,
    };
  }
  const claimedReward = Big(user.userUnclaimedReward).plus(user.claimedReward).toFixed();
  const readableReward = formatTokenAmount(claimedReward, decimals);
  const readableYourStaked = formatTokenAmount(user.yourStaked, decimals);
  const rewardPerMonth = Big(user.rewardPerSecond).times(SECONDS_IN_MONTH).toFixed();
  const readableRewardPerMonth = formatTokenAmount(rewardPerMonth, decimals);
  return {
    reward: displayAmount(readableReward),
    yourStaked: displayAmount(readableYourStaked),
    rewardPerMonth: displayAmount(readableRewardPerMonth),
  };
};

export const getAmountPerMonth = (
  amount: string,
  stakingData: IStaking,
  decimals: number,
  user: IUserStakingData | undefined,
  type: EStakeType,
) => {
  if (!user || !amount) return ZERO_AMOUNT;
  const { totalStaked, rewardPerSecond } = stakingData;
  const parsedAmount = parseTokenAmount(amount, decimals);
  const divisor = type === EStakeType.Stake
    ? Big(totalStaked).plus(parsedAmount).toFixed()
    : totalStaked;
  const userRewardPerSecond = Big(parsedAmount).times(rewardPerSecond).div(divisor).toFixed();
  const rewardPerMonth = Big(userRewardPerSecond).times(SECONDS_IN_MONTH).toFixed();
  const readableRewardPerMonth = formatTokenAmount(rewardPerMonth, decimals);
  return displayAmount(readableRewardPerMonth);
};

export const formatDate = (endAt: number): string => {
  const dateInMilliseconds = endAt * THOUSAND;
  const date = getParseDate(dateInMilliseconds);
  const month = new Date(dateInMilliseconds).toLocaleString('en-US', { month: 'short' });
  return t('staking.date', {
    day: date.day,
    month,
    year: date.year,
  });
};

export const getCurrentActionData = (
  type: EStakeType,
  value: string,
  token: FungibleTokenContract,
  balances: { [key: string]: string },
  stakeAction: () => Promise<void>,
  unstakeAction: () => Promise<void>,
  data: IStaking,
  user?: IUserStakingData,
) => {
  if (type === EStakeType.Stake) {
    return {
      prefix: '+',
      amountPerMonth: getAmountPerMonth(
        value,
        data,
        token.metadata.decimals,
        user,
        type,
      ),
      button: CurrentButton.Stake,
      balance: balances[token.contractId],
      handler: stakeAction,
    };
  }
  return {
    prefix: '-',
    button: CurrentButton.Unstake,
    amountPerMonth: getAmountPerMonth(
      value,
      data,
      token.metadata.decimals,
      user,
      type,
    ),
    balance: user?.yourStaked || ZERO_AMOUNT,
    handler: unstakeAction,
  };
};
