import { SpecialContainer } from 'components/SpecialContainer';
import styled, { css } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 31.75rem;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    max-width: 20.5rem;
    align-self: center;
  `}
`;

const Title = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.75rem;
  text-align: center;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 1.25rem;
    line-height: 1.5rem;
  `}
`;

const SubTitle = styled.p`
  margin: 0;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.375rem;
  text-align: center;
  color: ${({ theme }) => theme.globalGrey};
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 0.875rem;
    line-height: 1.25rem;
  `}
`;

const Body = styled.div`
  margin-top: 1.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.625rem;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    gap: 1.25rem;
    padding: 0 1.5rem;
  `}
`;

const CardWrapper = styled(SpecialContainer)`
  max-width: 30.75rem;
  border-radius: 1.5rem;
  ::before{
    border-radius: 1.5rem;
  }
`;

const Row = styled.div<{ sideIndents?: boolean }>`
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  padding: 0 ${({ sideIndents }) => (sideIndents ? '0.75rem' : '0')};
  & > div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    :first-child {
      align-items: flex-start;
      flex: 1;
    }
    :last-child {
      align-items: flex-end;
      flex: 1;
    }
  }
`;

const ElementTitle = styled.div`
  display: flex;
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 0.875rem;
  color: ${({ theme }) => theme.globalGrey};
`;

const ElementValue = styled.div<{ color?: string, isButton?: boolean, smallFont?: boolean, disabled?: boolean }>`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.188rem;
  color: ${({ theme, color, disabled }) => (disabled ? theme.globalGrey : (color || theme.globalWhite))};
  cursor: ${({ isButton, disabled }) => (isButton && !disabled ? 'pointer' : 'default')};
  ${({ theme, smallFont }) => theme.mediaWidth.upToExtraSmall`
  ${smallFont && (
    css`
      font-size: 0.75rem;
      line-height: 0.875rem;
    `
  )}
  `}
`;

const RewardContainer = styled.div`
  background-color:${({ theme }) => theme.claimBackground};
  padding: 0.5rem  0.75rem;
  border-radius: 0.75rem;
  margin-top: 1.625rem;
`;

export default {
  Container,
  Header,
  Title,
  SubTitle,
  Body,
  CardWrapper,
  Row,
  ElementTitle,
  ElementValue,
  RewardContainer,
};
