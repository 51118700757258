import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IStaking, IUserStakingData } from 'store/interfaces';
import { RootState } from 'store/redux-store';
import { ZERO, ZERO_AMOUNT } from 'utils/constants';

interface IStakingState {
  data: IStaking,
  user?: IUserStakingData
}

const initialState: IStakingState = {
  data: {
    rewardTokenId: '',
    startAt: ZERO,
    endAt: ZERO,
    rewardPerSecond: ZERO_AMOUNT,
    minStaked: ZERO_AMOUNT,
    apy: ZERO_AMOUNT,
    totalStaked: ZERO_AMOUNT,
  },
};

export const stakingSlice = createSlice({
  name: 'staking',
  initialState,
  reducers: {
    setStakingData: (state, action: PayloadAction<IStaking>) => {
      // eslint-disable-next-line no-param-reassign
      state.data = action.payload;
    },
    setStakingUserData: (state, action: PayloadAction<IUserStakingData>) => {
      // eslint-disable-next-line no-param-reassign
      state.user = action.payload;
    },
  },
});

export const { setStakingData, setStakingUserData } = stakingSlice.actions;
export const selectStaking = (state: RootState) => state.staking;

export default stakingSlice.reducer;
