import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0 1rem 0 1rem;
  `}
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin: 0 1rem;
  `}
`;

export const Title = styled.p`
  margin: 0;
  margin-bottom: 1.5rem;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.75rem;
  color: ${({ theme }) => theme.globalWhite};
`;

export const ContainerCard = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.backgroundCard};

  width: 100%;
  min-width: 310px;
  height: fit-content;
  min-height: 100px;
  max-width: 500px;
  display: flex;
  flex-direction: column;

  color: ${({ theme }) => theme.white};
  box-shadow: 0px 32px 72px -12px ${({ theme }) => theme.boxShadowCard};
  border-radius: 36px;
  padding: 1.5rem;
  ::before {
    content: '';
    position: absolute;
    top: -1px;
    bottom: 0;
    left: -1px;
    right: -1px;
    background: ${({ theme }) => theme.specialBorderCard};
    border-radius: 36px;
    z-index: -1;
  }

  transition: all .2s ease-out;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 1.563rem 1rem 1rem 1rem;
  `}
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ChangeTokenContainer = styled.div`
  display: flex;
  align-self: center;
  color: ${({ theme }) => theme.globalGrey};
  margin: 1.125rem 0;
  & > span {
    font-style: normal;
    font-weight: 500;
    font-size: .75rem;
    line-height: .875rem;
  }
  :hover {
    & > svg {
      path {
        fill: ${({ theme }) => theme.globalWhite};
      }
    }
    cursor: pointer;
    color: ${({ theme }) => theme.globalWhite};
  }
`;

export const ExchangeBlock = styled.div`
  display: flex;
  justify-content: center;
  margin: 1.125rem .75rem 1.375rem .75rem;
  font-style: normal;
  font-weight: normal;
  font-size: .75rem;
  line-height: .875rem;
  color: ${({ theme }) => theme.globalGrey};

  white-space: nowrap;
  overflow: hidden;
`;

export const BlockButton = styled.div`
  z-index: 4;
  background-color: ${({ theme }) => theme.backgroundCard};
  padding-top: 1.625rem;
  & > button {
    width: 100%;
  }
`;
