import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ReactComponent as CloseIcon } from 'assets/images-app/close.svg';
import { ReactComponent as SearchIcon } from 'assets/images-app/search-icon.svg';
import { ReactComponent as ClearSearch } from 'assets/images-app/clear-search.svg';
import { useTranslation } from 'react-i18next';

import FungibleTokenContract from 'services/contracts/FungibleToken';
import { INITIAL_INPUT_PLACEHOLDER } from 'utils/constants';
import { ISearchModal } from 'providers/modals.interfaces';
import { useAppSelector } from 'hooks/redux-hooks';
import { selectTokens } from 'store/slices';
import { selectJumboRuntime } from 'store/slices/jumboRuntime';
import {
  Layout, Modal, ModalBlock, ModalTitle, ModalIcon,
} from '../styles';
import SearchRow from './SearchRow';
import PopularToken from './PopularToken';

const Container = styled.div`
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    position: fixed;
    bottom: 0;
    width: 100%;
  `}
`;

const SearchModalContainer = styled(Modal)`
  width: 420px;
  max-height: 80vh;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 540px;
  `}
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    min-width: 100%;
    width: 100%;
    margin: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `}
  transition: all 1s ease;
`;

const SearchInputBlock = styled(ModalBlock)`
  margin-top: 0;
`;

const SearchInputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.globalGreyOp04};
  box-sizing: border-box;
  border-radius: 12px;
  padding: 12px;
  height: 100%;
  min-height: 50px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    border-radius: 18px;
    padding: 16px;
  `}
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    border-radius: 12px;
    padding: 12px;
  `}
  :focus-within {
    border: 2px solid ${({ theme }) => theme.pink};
    padding: 11px;
    ${({ theme }) => theme.mediaWidth.upToMedium`
      padding: 15px;
  `}
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      padding: 11px;
  `}
  }
`;

const LogoSearchIcon = styled(SearchIcon)`
  position: absolute;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 26px;
    height: 26px;
  `}
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 18px;
    height: 18px;
  `}
  transition: all 1s ease;
`;

const SearchInput = styled.input`
  padding: 0 28px;
  background: none;
  border: none;
  outline: none;
  font-style: normal;
  font-weight: normal;
  width: 100%;
  font-size: 1rem;
  line-height: 1.188rem;
  color: ${({ theme }) => theme.globalWhite};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 0 38px;
    font-size: 1.5rem;
    line-height: 1.75rem;
  `}
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 0 28px;
    font-size: 1rem;
    line-height: 1.188rem;
  `}
  transition: all 1s ease;
  ::placeholder {
    color: ${({ theme }) => theme.globalGreyOp04};
  }
`;

const PopularTokenResult = styled(ModalBlock)`
  margin: 0 1rem 1rem 1rem;
`;

const SearchResults = styled(ModalBlock)`
  flex-direction: column;
  justify-content: flex-start;
  overflow-x: hidden;
  flex: 1;
  margin: 0 .5rem 0 1rem;
  padding-right: .5rem;
  & > div{
    width: 100%;
  }

  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.globalGreyOp04};
    border-radius: 10px;
  }
`;

const ClearSearchIcon = styled(ClearSearch)`
  position: absolute;
  right: 12px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 32px;
    height: 32px;
    right: 16px;
  `}
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 24px;
    height: 24px;
    right: 12px;
  `}
`;

const SearchModal: React.FC<ISearchModal> = ({
  closeModal, activeToken, setActiveToken, closeAfterSelect = true,
}) => {
  const { loading } = useAppSelector(selectJumboRuntime);
  const tokens = useAppSelector(selectTokens);
  const { t } = useTranslation();

  const initialTokens = Object.values(tokens);
  const [tokensArray, setTokensArray] = useState<FungibleTokenContract[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');

  const onChange = ({ target }: {target: HTMLInputElement}) => {
    const newValue = target.value.trim().toLowerCase();
    setSearchValue(newValue);
    const newTokens = newValue !== ''
      ? initialTokens.filter(
        (el) => el.metadata.name.toLowerCase().includes(newValue)
        || el.metadata.symbol.toLowerCase().includes(newValue),
      )
      : initialTokens;
    setTokensArray(newTokens);
  };

  useEffect(() => {
    const newTokens = Object.values(tokens);
    if (newTokens.length !== tokensArray.length && searchValue === INITIAL_INPUT_PLACEHOLDER) {
      setTokensArray(newTokens);
    }
  }, [tokensArray.length, tokens, loading, searchValue]);

  const closeModalIfNeed = () => {
    if (closeAfterSelect) closeModal();
  };

  return (
    <>
      <Layout onClick={closeModal}>
        <Container>
          <SearchModalContainer onClick={(e) => e.stopPropagation()}>
            <ModalBlock>
              <ModalTitle>
                {t('searchModal.selectToken')}
              </ModalTitle>
              <ModalIcon onClick={closeModal}>
                <CloseIcon />
              </ModalIcon>
            </ModalBlock>
            <SearchInputBlock>
              <SearchInputContainer>
                <LogoSearchIcon />
                <SearchInput
                  value={searchValue}
                  onChange={onChange}
                  placeholder={t('searchModal.placeholder')}
                />
                {searchValue && (
                  <ClearSearchIcon onClick={() => {
                    setSearchValue('');
                    setTokensArray(initialTokens);
                  }}
                  />
                )}
              </SearchInputContainer>
            </SearchInputBlock>
            <PopularTokenResult>
              <PopularToken
                activeToken={activeToken}
                setActiveToken={setActiveToken}
                callbackAfterSelect={closeModalIfNeed}
              />
            </PopularTokenResult>
            <SearchResults>
              <SearchRow
                tokensArray={tokensArray}
                activeToken={activeToken}
                setActiveToken={setActiveToken}
                callbackAfterSelect={closeModalIfNeed}
              />
            </SearchResults>
          </SearchModalContainer>
        </Container>
      </Layout>
    </>
  );
};

export default SearchModal;
