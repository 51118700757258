import { providers } from 'near-api-js';
import { AccountView, CodeResult } from 'near-api-js/lib/providers/provider';

export interface IRPCProviderService {
  viewFunction: (method: string, accountId: string, args?: any) => Promise<any>;
  viewAccount: (accountId: string) => Promise<any>;
}

enum RPCProviderMethods {
  CALL_FUNCTION = 'call_function',
  VIEW_ACCOUNT = 'view_account'
}

const FINALITY_FINAL = 'final';

export default class RPCProviderService implements IRPCProviderService {
  private provider?: providers.JsonRpcProvider

  constructor(provider?: providers.JsonRpcProvider) {
    this.provider = provider;
  }

  async viewFunction(method: string, accountId: string, args: any = {}) {
    try {
      if (!this.provider) return console.warn('No Provider selected');

      const response = await this.provider
        .query<CodeResult>({
          request_type: RPCProviderMethods.CALL_FUNCTION,
          account_id: accountId,
          method_name: method,
          args_base64: btoa(JSON.stringify(args || {})),
          finality: FINALITY_FINAL,
        });

      const result = JSON.parse(Buffer.from(response.result).toString());
      return result;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  async viewAccount(accountId: string) {
    try {
      if (!this.provider) return console.warn('No Provider selected');
      const response = await this.provider.query<AccountView>({
        request_type: RPCProviderMethods.VIEW_ACCOUNT,
        finality: FINALITY_FINAL,
        account_id: accountId,
      });

      return response;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }
}
