import { FilterButton } from 'components/Button';
import { isMobile } from 'utils/userAgent';
import { NavLink } from 'react-router-dom';
import { ITabs } from 'pages/Pool/constants';
import { FilterBlock, LogoSoon } from './styles';

export default function TabsComponent(
  { slides, to }: { slides: ITabs[], to: (to: string) => string },
) {
  return (
    <FilterBlock>
      {slides.map((el) => (
        <NavLink key={el.title} to={to(el.value)}>
          {({ isActive }) => (
            <FilterButton
              isActive={isActive}
              disabled={el.disabled}
            >
              {el.title}
              {el.disabled && !isMobile && <LogoSoon />}
            </FilterButton>
          )}
        </NavLink>
      ))}
    </FilterBlock>
  );
}
