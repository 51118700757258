import Big from 'big.js';
import {
  ACCOUNT_MIN_STORAGE_AMOUNT,
  FT_GAS, ONE_YOCTO_NEAR,
  STORAGE_TO_REGISTER_MFT,
  MIN_DEPOSIT_PER_TOKEN_FARM,
} from 'utils/constants';
import { parseTokenAmount } from 'utils/calculations';
import { IPool } from 'store';
import getConfig from 'services/config';
import { FarmContractMethod, FarmContractViewMethod, ITransaction } from 'services/interfaces';
import RPCProviderService from 'services/RPCProviderService';
import FungibleTokenContract from './FungibleToken';

const config = getConfig();

const EXCHANGE_CONTRACT_ID = config.contractId;
const FARM_CONTRACT_ID = config.farmContractId;

export default class FarmContract {
  contractId = FARM_CONTRACT_ID;

  private accountId: string;

  private provider: RPCProviderService;

  constructor(provider: RPCProviderService, accountId: string) {
    this.provider = provider;
    this.accountId = accountId;
  }

  async getNumberOfFarms() {
    return this.provider.viewFunction(FarmContractViewMethod.getNumberOfFarms, this.contractId);
  }

  async getListFarms(fromIndex: number, limit: number) {
    return this.provider.viewFunction(FarmContractViewMethod.listFarms, this.contractId, {
      from_index: fromIndex, limit,
    });
  }

  async getRewardByTokenId(tokenId: string, accountId = this.accountId) {
    return this.provider.viewFunction(FarmContractViewMethod.getReward, this.contractId, {
      account_id: accountId, token_id: tokenId,
    });
  }

  async getRewards(accountId = this.accountId) {
    return this.provider
      .viewFunction(FarmContractViewMethod.listRewards, this.contractId, { account_id: accountId });
  }

  async getStakedListByAccountId(accountId = this.accountId) {
    return this.provider.viewFunction(FarmContractViewMethod.listUserSeeds, this.contractId, {
      account_id: accountId,
    });
  }

  async getSeeds(fromIndex: number, limit: number) {
    return this.provider.viewFunction(FarmContractViewMethod.listSeeds, this.contractId, {
      from_index: fromIndex, limit,
    });
  }

  async getUnclaimedReward(farmId: string | number, accountId = this.accountId) {
    return this.provider.viewFunction(FarmContractViewMethod.getUnclaimedReward, this.contractId, {
      account_id: accountId, farm_id: farmId,
    });
  }

  async currentStorageBalance(accountId = this.accountId) {
    return this.provider.viewFunction(FarmContractViewMethod.storageBalanceOf, this.contractId, {
      account_id: accountId,
    });
  }

  async checkFarmStorageBalance(accountId: string = this.accountId) {
    const transactions: ITransaction[] = [];

    let storageAmount = new Big(0);
    const balance = await this.currentStorageBalance(accountId);

    if (!balance) {
      storageAmount = new Big(ACCOUNT_MIN_STORAGE_AMOUNT);
    }

    if (new Big(balance?.available || '0').lt(MIN_DEPOSIT_PER_TOKEN_FARM)) {
      storageAmount = storageAmount.plus(STORAGE_TO_REGISTER_MFT);
    }

    if (storageAmount.gt(0)) {
      transactions.push({
        receiverId: this.contractId,
        functionCalls: [{
          methodName: FarmContractMethod.storageDeposit,
          args: {
            registration_only: false,
            account_id: accountId,
          },
          amount: storageAmount.toFixed(),
        }],
      });
    }
    return transactions;
  }

  async stake(
    tokenId: string,
    amount: string,
    pool: IPool,
    message: string = '',
  ) {
    const transactions: ITransaction[] = [];
    const checkStorage = await this.checkFarmStorageBalance();
    transactions.push(...checkStorage);
    transactions.push({
      receiverId: EXCHANGE_CONTRACT_ID,
      functionCalls: [{
        methodName: FarmContractMethod.mftTransferCall,
        args: {
          receiver_id: this.contractId,
          token_id: tokenId,
          amount: parseTokenAmount(amount, pool.lpTokenDecimals),
          msg: message,
        },
        amount: ONE_YOCTO_NEAR,
        gas: FT_GAS,
      }],
    });

    return transactions;
  }

  async unstake(
    seedId: string,
    amount: string,
    pool: IPool,
    message: string = '',
  ) {
    const transactions: ITransaction[] = [];
    const checkStorage = await this.checkFarmStorageBalance();
    transactions.push(...checkStorage);
    transactions.push({
      receiverId: this.contractId,
      functionCalls: [{
        methodName: FarmContractMethod.withdrawSeed,
        args: {
          seed_id: seedId,
          amount: parseTokenAmount(amount, pool.lpTokenDecimals),
          msg: message,
        },
        amount: ONE_YOCTO_NEAR,
        gas: FT_GAS,
      }],
    });
    return transactions;
  }

  claimRewardBySeed(seedId: string): ITransaction[] {
    return [
      {
        receiverId: this.contractId,
        functionCalls: [{
          methodName: FarmContractMethod.claimRewardBySeed,
          args: { seed_id: seedId },
          gas: FT_GAS,
        }],
      },
    ];
  }

  async withdrawAllReward(
    rewardList: {
      token: FungibleTokenContract;
      value: string;
  }[],
  ) {
    let transactions: ITransaction[] = [];

    const storageDeposits = await Promise.all(
      rewardList.map((reward) => reward.token.checkSwapStorageBalance({
        accountId: this.accountId,
      })),
    );
    if (storageDeposits.length) transactions = transactions.concat(...storageDeposits);

    rewardList.forEach((farmReward) => {
      transactions.push({
        receiverId: this.contractId,
        functionCalls: [{
          methodName: FarmContractMethod.withdrawReward,
          args: {
            token_id: farmReward.token.contractId,
            amount: farmReward.value,
          },
          gas: '40000000000000',
          amount: ONE_YOCTO_NEAR,
        }],
      });
    });

    return transactions;
  }
}
