import getConfig from 'services/config';
import { ITransaction, SwapFuturesContractViewMethod } from 'services/interfaces';
import RPCProviderService from 'services/RPCProviderService';
import { parseTokenAmount } from 'utils/calculations';
import { DEFAULT_FT_GAS } from 'utils/constants';
import FungibleTokenContract from './FungibleToken';

const config = getConfig();
const CONTRACT_ID = config.swapFuturesContractId;

export default class SwapFuturesContractId {
  private accountId: string;

  private provider: RPCProviderService;

  constructor(provider: RPCProviderService, accountId: string) {
    this.provider = provider;
    this.accountId = accountId;
  }

  contractId = CONTRACT_ID;

  async getPool(poolId: number) {
    return this.provider
      .viewFunction(SwapFuturesContractViewMethod.getPool, CONTRACT_ID, { pool_id: poolId });
  }

  async getPools() {
    return this.provider.viewFunction(SwapFuturesContractViewMethod.getPools, CONTRACT_ID, {
      from_index: 0, limit: 100,
    });
  }

  async swapFutures(
    poolId: number,
    inputToken: FungibleTokenContract,
    outputToken:FungibleTokenContract,
    amount: string,
  ) {
    const transactions: ITransaction[] = [];
    const outputTokenStorage = await outputToken.checkSwapStorageBalance(
      { accountId: this.accountId },
    );
    if (outputTokenStorage.length) transactions.push(...outputTokenStorage);

    const transferTransactions = await inputToken.transfer(
      {
        accountId: this.accountId,
        inputToken: inputToken.contractId,
        amount: parseTokenAmount(amount, inputToken.metadata.decimals),
        message: JSON.stringify({ Swap: { pool_id: poolId } }),
        receiverId: this.contractId,
        gas: DEFAULT_FT_GAS,
      },
    );
    if (transferTransactions.length) transactions.push(...transferTransactions);
    return transactions;
  }
}
