export interface IDetailsTransaction {
  methodName: string;
  href: string;
  status: StatusType;
  type: TransactionType;
  title: string;
}

export enum TransactionType {
  None = 'None',
  Swap = 'Swap',
  CreatePool = 'CreatePool',
  AddLiquidity = 'AddLiquidity',
  RemoveLiquidity = 'RemoveLiquidity',
  NearDeposit = 'NearDeposit',
  NearWithdraw = 'NearWithdraw',
  StakeFarm = 'StakeFarm',
  UnstakeFarm = 'UnstakeFarm',
  StakeStaking = 'StakeStaking',
  UnstakeStaking = 'UnstakeStaking'
}

export enum StatusType {
  None,
  SuccessValue,
  Failure,
}
