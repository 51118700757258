import styled, { css } from 'styled-components';

import { ReactComponent as ErrorIcon } from 'assets/images-app/error-toast.svg';
import { ReactComponent as SuccessIcon } from 'assets/images-app/success-toast.svg';
import { ReactComponent as ExternalLink } from 'assets/images-app/external-link.svg';

export const flexRowNoWrap = css`
  display: flex;
  flex-flow: row nowrap;
`;

const Container = styled.div`
  ${flexRowNoWrap}
  gap: 0.75rem;
`;

const Description = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.375rem;
  color: ${({ theme }) => theme.globalWhite};
`;

const Link = styled.a`
  margin-left: .5rem;
  text-decoration: none;
  svg {
    path {
    fill:  ${({ theme }) => theme.globalGrey}
    }
  }
`;

export default {
  SuccessIcon,
  Container,
  Description,
  Link,
  ErrorIcon,
  ExternalLink,
};
