import React from 'react';
import {
  Wrapper, UpperRow, BlockTitle, LogoPool, TitlePool, Button,
  LowerRow, BlockVolume, TitleVolume, LabelVolume, Column,
} from './styles';

export default function RetrodropCardPlaceholder() {
  return (
    <Wrapper>
      <UpperRow>
        <BlockTitle>
          <LogoPool>
            <div />
          </LogoPool>
          <TitlePool />
        </BlockTitle>
      </UpperRow>
      <LowerRow>
        <BlockVolume>
          <Column>
            <TitleVolume />
            <LabelVolume />
          </Column>
        </BlockVolume>
        <Button />
      </LowerRow>
    </Wrapper>
  );
}
