import { ReactComponent as CloseIcon } from 'assets/images-app/close.svg';
import { ITooltipModal } from 'providers/modals.interfaces';
import { Layout } from '../styles';
import {
  NewModal,
  TooltipBlock,
  ModalIcon,
  ApyBlock,
} from './styles';

const TooltipModal: React.FC<ITooltipModal> = ({ closeModal, title }) => {
  const isString = typeof (title) === 'string';

  return (
    <>
      <Layout onClick={closeModal}>
        <NewModal onClick={(e) => e.stopPropagation()}>
          <TooltipBlock>
            {isString ? <p>{title}</p> : <ApyBlock>{title}</ApyBlock>}
            <ModalIcon onClick={closeModal}>
              <CloseIcon />
            </ModalIcon>
          </TooltipBlock>
        </NewModal>
      </Layout>
    </>
  );
};

export default TooltipModal;
