import styled from 'styled-components';
import { SpecialContainer } from 'components/SpecialContainer';

export const Wrapper = styled(SpecialContainer)`
  background-color: ${({ theme }) => theme.backgroundCard};

  max-width: 736px;
  width: 100%;
  border-radius: 24px;
  justify-content: space-between;
  margin: 1rem 0 1rem 0;
  min-height: 160px;

  & > div:first-child {
    margin-bottom: 1.5rem;
  }
  ::before{
    border-radius: 24px;
  }
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    max-width: 328px;
    padding: 1.5rem;
  `}
`;

export const UpperRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    flex-direction: column-reverse;
    align-items: flex-start;
  `}
`;

export const LowerRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    flex-direction: column;
    align-items: flex-start;
  `}
`;

export const BlockTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const LogoPool = styled.div`
  margin-right: 1.75rem;
  & > div {
    border-radius: 8px;
    background-color: ${({ theme }) => theme.globalGreyOp02};
    width: 32px;
    height: 32px;
  }
`;

export const TitlePool = styled.div`
  width: 76px;
  height: 16px;
  background-color: ${({ theme }) => theme.globalGreyOp02};
  border-radius: 4px;
`;

export const BlockVolume = styled.div`
  display: flex;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    flex-direction: column;
  `}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 2.125rem;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin: 0 0 1.125rem;
    flex-direction: row;
    justify-content: space-between;
  `}
`;

export const TitleVolume = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.globalGreyOp02};
  border-radius: 12px;

  width: 63px;
  height: 12px;
  margin-bottom: .75rem;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    margin: 0;
  `}
`;

export const LabelVolume = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.globalGreyOp02};
  border-radius: 12px;
  width: 27px;
  height: 6px;
`;

export const Button = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: 157px;
  width: 100%;
  height: 40px;
  background-color: ${({ theme }) => theme.globalGreyOp02};
  border-radius: 12px;
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    max-width: 100%;
  `}
`;
