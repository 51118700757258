import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FungibleTokenContract from 'services/contracts/FungibleToken';
import { RootState } from 'store/redux-store';

interface ITokensState {
  value: {[key:string]: FungibleTokenContract}
}

const initialState: ITokensState = { value: {} };

export const tokensSlice = createSlice({
  name: 'tokens',
  initialState,
  reducers: {
    setTokens: (state, action: PayloadAction<{[key:string]: FungibleTokenContract}>) => {
      // eslint-disable-next-line no-param-reassign
      state.value = action.payload;
    },
  },
});

export const { setTokens } = tokensSlice.actions;
export const selectTokens = (state: RootState) => state.tokens.value;

export default tokensSlice.reducer;
