import { Navigate, Route, Routes } from 'react-router-dom';
import TabsComponent from 'components/TabsComponent';
import { t } from 'i18next';
import { STAKING_ROUTES, toStakingSlider } from 'utils/routes';
import { ReactComponent as TooltipIcon } from 'assets/images-app/tooltip-type-2.svg';
import { colors } from 'theme';
import Tooltip from 'components/Tooltip';
import { useAppSelector } from 'hooks/redux-hooks';
import { selectStaking } from 'store/slices/staking';
import { selectTokens } from 'store/slices';
import { getToken } from 'store/helpers';
import { displayAmount, formatTokenAmount } from 'utils/calculations';
import { selectJumboRuntime } from 'store/slices/jumboRuntime';
import { useMemo } from 'react';

import { useWalletSelector } from 'providers/wallet-provider';
import StakingContract from 'services/contracts/StakingContract';
import getConfig from 'services/config';
import { Big } from 'big.js';
import { ZERO } from 'utils/constants';
import { StakingCardPlaceholder } from 'components/Placeholders';
import { EStakeType, stakingTabs } from './constants';
import styles from './styles';
import ActionBlock from './ActionBlock';
import { formatDate, getUserData } from './utils';

const config = getConfig();

export default function Staking() {
  const { loading } = useAppSelector(selectJumboRuntime);
  const { data, user } = useAppSelector(selectStaking);
  const tokens = useAppSelector(selectTokens);
  const token = useMemo(() => getToken(data.rewardTokenId, tokens), [data.rewardTokenId, tokens]);
  const {
    accountId, RPCProvider, requestSignTransactions, isSignedIn,
  } = useWalletSelector();
  const stakingContract = useMemo(
    () => new StakingContract(RPCProvider, accountId),
    [RPCProvider, accountId],
  );

  if (!token || loading) {
    return (
      <StakingCardPlaceholder />
    );
  }

  const disableClaimAction = !isSignedIn || Big(user?.yourStaked || ZERO).eq(ZERO);
  const claimAction = async () => {
    if (!user) return;
    const rewardAmount = Big(user.userUnclaimedReward).plus(user.claimedReward).toFixed();
    const claim = stakingContract.claimRewardBySeed(config.stakingSeedId);
    const withdraw = await stakingContract.withdrawReward(token, rewardAmount);
    await requestSignTransactions([claim, ...withdraw]);
  };

  const { symbol, decimals } = token.metadata;
  const readableTotalStaked = formatTokenAmount(data.totalStaked, decimals);
  const userData = getUserData(decimals, user);
  const stakedInfo = [
    {
      title: t('staking.totalStaked'),
      value: t('token', { amount: displayAmount(readableTotalStaked), symbol }),
    },
    {
      title: t('staking.apy.title'),
      value: t('staking.apy.value', { apy: data.apy }),
      tooltip: t('tooltipTitle.APY'),
      color: colors.greenText,
    },
    {
      title: t('staking.yourStake'),
      value: t('token', { amount: userData.yourStaked, symbol }),
    },
  ];
  const rewards = [
    {
      title: t('staking.reward'),
      value: t('token', { amount: userData.reward, symbol }),
    },
    {
      title: t('staking.rewardPerMonth', { amount: userData.rewardPerMonth, symbol }),
      value: 'Claim',
      color: colors.pink,
      handleClick: claimAction,
      disabled: disableClaimAction,
    },
  ];

  return (
    <styles.Container>
      <styles.Header>
        <styles.Title>
          {t('staking.title')}
        </styles.Title>
        <styles.SubTitle>
          {t('staking.subTitle', { endDate: formatDate(data.endAt) })}
        </styles.SubTitle>
      </styles.Header>
      <styles.Body>
        <TabsComponent slides={stakingTabs} to={toStakingSlider} />
        <styles.CardWrapper>
          <styles.Row sideIndents>
            {stakedInfo.map(({
              title, value, color, tooltip,
            }) => (
              <div key={title}>
                <styles.ElementTitle>
                  {title}
                  {tooltip && (
                    <Tooltip title={tooltip}>
                      <TooltipIcon />
                    </Tooltip>
                  )}
                </styles.ElementTitle>
                <styles.ElementValue color={color} smallFont>{value}</styles.ElementValue>
              </div>
            ))}
          </styles.Row>
          <styles.RewardContainer>
            <styles.Row>
              {rewards.map(({
                title, value, color, handleClick, disabled,
              }) => (
                <div key={title}>
                  <styles.ElementTitle>{title}</styles.ElementTitle>
                  <styles.ElementValue
                    color={color}
                    onClick={() => {
                      if (!handleClick || disabled) return;
                      handleClick();
                    }}
                    isButton={Boolean(handleClick)}
                    disabled={disabled}
                  >
                    {value}
                  </styles.ElementValue>
                </div>
              ))}
            </styles.Row>
          </styles.RewardContainer>
          <Routes>
            <Route
              path="*"
              element={<Navigate to={toStakingSlider(STAKING_ROUTES.Stake)} />}
            />
            <Route
              path={STAKING_ROUTES.Stake}
              element={(<ActionBlock type={EStakeType.Stake} />)}
            />
            <Route
              path={STAKING_ROUTES.Unstake}
              element={(<ActionBlock type={EStakeType.Unstake} />)}
            />
          </Routes>
        </styles.CardWrapper>
      </styles.Body>
    </styles.Container>
  );
}
