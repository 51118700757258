import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  max-width: 100%;
  width: 31.75rem;
  flex-direction: column;

  & > div {
    &:last-child {
      margin: 0;
      max-width: 100%;
      min-width: unset;
    }
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0 1rem;
  `}
`;

const TitleDescription = styled.div`
  gap: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.div`
  width: 20.9rem;
  max-width: 100%;
  height: 1.75rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.backgroundCard};
  
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 16.5rem;
  `}
`;

const Description = styled.div`
  gap: 0.5rem;
  display: flex;
  flex-direction: column;

  div {
    height: 1.25rem;
    width: 31.75rem;
    max-width: 100%;
    border-radius: 0.25rem;
    background-color: ${({ theme }) => theme.backgroundCard};

    ${({ theme }) => theme.mediaWidth.upToSmall`
      width: 17.75rem;
    `}
  }
`;

const Controls = styled.div`
  gap: 1.25rem; 
  display: flex;
  margin: 1.625rem 0;
  align-items: center;
  justify-content: center;

  div {
    width: 4rem;
    height: 1.3125rem;
    border-radius: 0.25rem;
    background-color: ${({ theme }) => theme.backgroundCard};
  }
`;

const Reward = styled.div`
  width: 100%;
  height: 3.5625rem;
  margin-bottom: 2rem;
  margin-top: 1.625rem;
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.claimBackground};
`;

const InputLabel = styled.div`
  margin: 0 0.8rem 0.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LabelWallet = styled.div`
  display: flex;
  gap: 0.438rem;
  align-items: center;

  svg {
    width: 1rem;
  }
`;

const LabelControls = styled.div`
  width: 4rem;
  height: 0.875rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.globalGreyOp02};
`;

const Input = styled.div`
  display: flex;
  height: 3.75rem;
  align-items: center;
  border-radius: 0.75rem;
  justify-content: space-between;
  padding: 0.688rem 1.375rem 0.688rem 0.688rem;
  border: 1px solid ${({ theme }) => theme.globalGreyOp02};
`;

const InputIcon = styled.div`
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.globalGreyOp02};
`;

const InputCurrency = styled.div`
  height: 1.5rem;
  width: 5.8125rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.globalGreyOp02};
`;

const InputDescription = styled.div`
  height: 0.875rem;
  width: 10.375rem;
  border-radius: 0.25rem;
  margin: 1rem auto 2.25rem auto;
  background-color: ${({ theme }) => theme.globalGreyOp02};
`;

const Button = styled.div`
  height: 3.5rem;
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.globalGreyOp01};
`;

export default {
  Container,
  TitleDescription,
  Title,
  Description,
  Controls,
  Reward,
  InputLabel,
  LabelWallet,
  LabelControls,
  Input,
  InputIcon,
  InputCurrency,
  InputDescription,
  Button,
};
