import Big from 'big.js';
import { calcD } from 'services/contracts/SwapContract/helpers';
import FungibleTokenContract from 'services/contracts/FungibleToken';
import { IPool, ITokenPrice } from 'store/interfaces';
import {
  calculateFairShare,
  formatBalance, formatTokenAmount, normalizedTradeFee, removeTrailingZeros,
} from 'utils/calculations';

export const calculateAddLiquidity = (
  amp: number,
  depositAmounts: number[],
  oldAmounts: number[],
  poolTokenSupply: number,
  tradeFee: number,
) => {
  const tokenNum = oldAmounts.length;
  const d0 = calcD(amp, oldAmounts);
  const comparableAmounts = [];
  for (let i = 0; i < oldAmounts.length; i += 1) {
    comparableAmounts[i] = oldAmounts[i] + depositAmounts[i];
  }
  const d1 = calcD(amp, comparableAmounts);

  if (Number(d1) <= Number(d0)) { throw new Error('D1 need less then or equal to D0.'); }

  for (let i = 0; i < tokenNum; i += 1) {
    const idealBalance = (oldAmounts[i] * d1) / d0;
    const difference = Math.abs(idealBalance - comparableAmounts[i]);
    const fee = normalizedTradeFee(tokenNum, difference, tradeFee);
    comparableAmounts[i] -= fee;
  }
  const d2 = calcD(amp, comparableAmounts);

  if (Number(d1) < Number(d2)) throw new Error('D2 need less then D1.');

  if (Number(d2) <= Number(d0)) { throw new Error('D1 need less then or equal to D0.'); }
  const mintShares = (poolTokenSupply * (d2 - d0)) / d0;
  const diffShares = (poolTokenSupply * (d1 - d0)) / d0;

  return [mintShares, diffShares - mintShares];
};

export const calcYourLiquidity = (
  tokensData: {[key:string]: FungibleTokenContract},
  pricesData: {[key: string]: ITokenPrice},
  pool: IPool,
) => {
  const [tokenInputName, tokenOutputName] = pool.tokenAccountIds;
  const tokenInput = tokensData[tokenInputName] ?? null;
  const tokenOutput = tokensData[tokenOutputName] ?? null;
  const priceInputToken = pricesData[tokenInputName]?.price ?? 0;
  const priceOutputToken = pricesData[tokenOutputName]?.price ?? 0;

  if (!tokenInput || !tokenOutput) return null;

  const checkTotalSupply = pool?.sharesTotalSupply === '0' ? '1' : pool?.sharesTotalSupply;

  const minAmounts = Object.entries(pool.supplies).reduce<{
    [tokenId: string]: string;
  }>((acc, [tokenId, totalSupply]) => {
    acc[tokenId] = calculateFairShare(
      totalSupply,
      pool.shares || '0',
      checkTotalSupply,
    );
    return acc;
  }, {});
  const [inputToken, outputToken] = Object.values(minAmounts).map((el) => el);

  const inputAmount = formatTokenAmount(
    Big(inputToken).mul(priceInputToken).toFixed(),
    tokenInput.metadata.decimals,
  );
  const outputAmount = formatTokenAmount(
    Big(outputToken).mul(priceOutputToken).toFixed(),
    tokenOutput.metadata.decimals,
  );

  const yourLiquidityAmount = removeTrailingZeros(formatBalance(
    Big(inputAmount).plus(outputAmount).toFixed(2),
  ));
  return yourLiquidityAmount;
};
