import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { map, distinctUntilChanged } from 'rxjs';
import {
  FinalExecutionOutcome, NetworkId, setupWalletSelector, Transaction,
} from '@near-wallet-selector/core';
import type { WalletSelector, AccountState } from '@near-wallet-selector/core';
import { setupModal } from '@near-wallet-selector/modal-ui';
import type { WalletSelectorModal } from '@near-wallet-selector/modal-ui';
import { setupMyNearWallet } from '@near-wallet-selector/my-near-wallet';
import { setupSender } from '@near-wallet-selector/sender';
import { setupHereWallet } from '@near-wallet-selector/here-wallet';
import getConfig from 'services/config';
import { providers } from 'near-api-js';
import RPCProviderService, { IRPCProviderService } from 'services/RPCProviderService';
import { ITransaction } from 'services/interfaces';
import { getGas, getAmount } from 'utils';

import { parseTransactions } from 'hooks/useTransactionHash/helpers';
import { setupMeteorWallet } from '@near-wallet-selector/meteor-wallet';

const config = getConfig();
const CONTRACT_ID = config.contractId;
const NETWORK_ID = config.networkId;

declare global {
  interface Window {
    selector: WalletSelector;
    modal: WalletSelectorModal;
  }
}

interface WalletSelectorContextValue {
  openModal: () => void;
  isSignedIn: () => boolean;
  requestSignTransactions: (t: ITransaction[]) => Promise<void | FinalExecutionOutcome[]>;
  accountId: string;
  RPCProvider: IRPCProviderService;
  signOut: () => Promise<void>;
  lastTransaction: number;
}

const WalletSelectorContext = React.createContext<WalletSelectorContextValue>(
  {} as WalletSelectorContextValue,
);

export const WalletSelectorContextProvider: React.FC = ({ children }) => {
  const [selector, setSelector] = useState<WalletSelector | null>(null);
  const [modal, setModal] = useState<WalletSelectorModal | null>(null);
  const [accountId, setAccountId] = useState<string>('');
  const [RPCProvider, setRPCProvider] = useState<IRPCProviderService>(new RPCProviderService());
  const [lastTransaction, setLastTransaction] = useState<number>(Date.now());

  const syncAccountState = (
    currentAccountId: string | null,
    newAccounts: Array<AccountState>,
  ) => {
    if (!newAccounts.length) {
      localStorage.removeItem('accountId');
      setAccountId('');

      return;
    }

    const validAccountId = currentAccountId
      && newAccounts.some((x) => x.accountId === currentAccountId);
    const newAccountId = validAccountId
      ? currentAccountId
      : newAccounts[0].accountId;

    localStorage.setItem('accountId', newAccountId);
    setAccountId(newAccountId);
  };

  const init = useCallback(async () => {
    const selectorInstance = await setupWalletSelector({
      network: NETWORK_ID as NetworkId,
      debug: true,
      modules: [
        setupMyNearWallet(),
        setupSender(),
        setupHereWallet(),
        setupMeteorWallet(),
      ],
    });
    const modalInstance = setupModal(selectorInstance, { contractId: CONTRACT_ID });
    const state = selectorInstance.store.getState();
    syncAccountState(localStorage.getItem('accountId'), state.accounts);

    window.selector = selectorInstance;
    window.modal = modalInstance;

    const { network } = selectorInstance.options;
    const provider = new providers.JsonRpcProvider({ url: network.nodeUrl });
    const providerService = new RPCProviderService(provider);
    setRPCProvider(providerService);
    setSelector(selectorInstance);
    setModal(modalInstance);
  }, []);

  useEffect(() => {
    init().catch((err) => {
      console.error(err);
    });
  }, [init]);

  useEffect(() => {
    if (!selector) {
      return;
    }

    const subscription = selector.store.observable
      .pipe(
        map((state: any) => {
          const result = state.accounts;
          return result;
        }),
        distinctUntilChanged(),
      )
      .subscribe((nextAccounts: any) => {
        syncAccountState(accountId, nextAccounts);
      });

    // eslint-disable-next-line consistent-return
    return () => subscription.unsubscribe();
  }, [selector, accountId]);

  const isSignedIn = useCallback(() => (selector && selector.isSignedIn()) || false, [selector]);
  const requestSignTransactions = useCallback(async (transactions: ITransaction[]) => {
    if (!selector) return console.warn('No wallet selected');

    const nearTransactions: Transaction[] = transactions.map((transaction: ITransaction) => ({
      signerId: accountId,
      receiverId: transaction.receiverId,
      actions: transaction.functionCalls.map((fc) => ({
        type: 'FunctionCall',
        params: {
          methodName: fc.methodName,
          args: fc.args || {},
          gas: getGas(fc.gas).toString(),
          deposit: getAmount(fc.amount).toString(),
        },
      })),
    }));

    const walletInstance = await selector.wallet();
    const result = await walletInstance.signAndSendTransactions({ transactions: nearTransactions });
    if (result) {
      parseTransactions(result);
    }
    setLastTransaction(Date.now());
    return result;
  }, [selector, accountId]);

  const openModal = useCallback(() => {
    if (!modal) return;

    modal.show();
  }, [modal]);

  const signOut = useCallback(async () => {
    try {
      if (!selector) return;
      const wallet = await selector.wallet();

      await wallet.signOut();
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  }, [selector]);

  if (!selector || !modal) {
    return null;
  }

  return (
    <WalletSelectorContext.Provider
      value={{
        isSignedIn,
        accountId,
        openModal,
        requestSignTransactions,
        RPCProvider,
        signOut,
        lastTransaction,
      }}
    >
      {children}
    </WalletSelectorContext.Provider>
  );
};

export function useWalletSelector() {
  return useContext(WalletSelectorContext);
}
