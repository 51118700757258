import {
  AccountModal,
  AddLiquidityModal,
  CreatePoolModal,
  RemoveLiquidityModal,
  SearchModal,
  StakeModal,
  TooltipModal,
  UnstakeModal,
  WithdrawDepositModal,
} from 'components/Modals';
import {
  useContext, useState, useCallback, createContext, useMemo,
} from 'react';
import {
  IBaseModal, IAddLiquidityModal, IRemoveLiquidityModal,
  ISearchModal, IStakeModal, ITooltipModal, IUnstakeModal, ModalEnum, ICreatePoolModal,
} from './modals.interfaces';

export enum EModals {
  EMPTY = 'EMPTY',
  ACCOUNT_MODAL = 'ACCOUNT_MODAL',
  ADD_LIQUIDITY_MODAL = 'ADD_LIQUIDITY_MODAL',
  CREATE_POOL_MODAL = 'CREATE_POOL_MODAL',
  REMOVE_LIQUIDITY_MODAL = 'REMOVE_LIQUIDITY_MODAL',
  SEARCH_MODAL = 'SEARCH_MODAL',
  STAKE_MODAL = 'STAKE_MODAL',
  TOOLTIP_MODAL = 'TOOLTIP_MODAL',
  UN_STAKE_MODAL = 'UN_STAKE_MODAL',
  WITHDRAW_DEPOSIT_MODAL = 'WITHDRAW_DEPOSIT_MODAL',
}

export const getModalFromUrl = (modal: string) => {
  switch (modal) {
    case ModalEnum.Search:
      return EModals.SEARCH_MODAL;
    case ModalEnum.Account:
      return EModals.ACCOUNT_MODAL;
    case ModalEnum.AddLiquidity:
      return EModals.ADD_LIQUIDITY_MODAL;
    case ModalEnum.CreatePool:
      return EModals.CREATE_POOL_MODAL;
    case ModalEnum.RemoveLiquidity:
      return EModals.REMOVE_LIQUIDITY_MODAL;
    case ModalEnum.Stake:
      return EModals.STAKE_MODAL;
    case ModalEnum.Unstake:
      return EModals.UN_STAKE_MODAL;
    default:
      return EModals.EMPTY;
  }
};

type IModalsProps = {
  [EModals.ACCOUNT_MODAL]: IBaseModal;
  [EModals.ADD_LIQUIDITY_MODAL]: IAddLiquidityModal;
  [EModals.CREATE_POOL_MODAL]: ICreatePoolModal;
  [EModals.REMOVE_LIQUIDITY_MODAL]: IRemoveLiquidityModal;
  [EModals.SEARCH_MODAL]: ISearchModal;
  [EModals.STAKE_MODAL]: IStakeModal;
  [EModals.UN_STAKE_MODAL]: IUnstakeModal;
  [EModals.TOOLTIP_MODAL]: ITooltipModal;
  [EModals.WITHDRAW_DEPOSIT_MODAL]: IBaseModal;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [EModals.EMPTY]: any;
};

type IModals = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [EModals.EMPTY]: any;
  [EModals.ACCOUNT_MODAL]: React.FC<IBaseModal>;
  [EModals.ADD_LIQUIDITY_MODAL]: React.FC<IAddLiquidityModal>;
  [EModals.CREATE_POOL_MODAL]: React.FC<ICreatePoolModal>;
  [EModals.REMOVE_LIQUIDITY_MODAL]: React.FC<IRemoveLiquidityModal>;
  [EModals.SEARCH_MODAL]: React.FC<ISearchModal>;
  [EModals.STAKE_MODAL]: React.FC<IStakeModal>;
  [EModals.UN_STAKE_MODAL]: React.FC<IUnstakeModal>;
  [EModals.TOOLTIP_MODAL]: React.FC<ITooltipModal>;
  [EModals.WITHDRAW_DEPOSIT_MODAL]: React.FC<IBaseModal>;
};

const MODALS: IModals = {
  [EModals.ACCOUNT_MODAL]: AccountModal,
  [EModals.ADD_LIQUIDITY_MODAL]: AddLiquidityModal,
  [EModals.CREATE_POOL_MODAL]: CreatePoolModal,
  [EModals.REMOVE_LIQUIDITY_MODAL]: RemoveLiquidityModal,
  [EModals.SEARCH_MODAL]: SearchModal,
  [EModals.STAKE_MODAL]: StakeModal,
  [EModals.UN_STAKE_MODAL]: UnstakeModal,
  [EModals.TOOLTIP_MODAL]: TooltipModal,
  [EModals.WITHDRAW_DEPOSIT_MODAL]: WithdrawDepositModal,
  [EModals.EMPTY]: null,
};

interface IInternalProviderState {
  modal: EModals;
  props: IModalsProps[EModals];
}

const initialState: IInternalProviderState = {
  modal: EModals.EMPTY,
  props: null,
};

type ModalProps<T extends EModals> = Omit<IModalsProps[T], 'closeModal'>;

interface IContextState {
  modal: EModals;
  props: IModalsProps[EModals];
  showModal: <T extends EModals>(modal: T, props: ModalProps<T>) => void;
  closeModal: () => void;
}

const initialContextState: IContextState = {
  modal: EModals.EMPTY,
  props: null,
  showModal: () => undefined,
  closeModal: () => undefined,
};

const ModalContext = createContext<IContextState>(initialContextState);
export const useModalContext = (): IContextState => useContext(ModalContext);

export const ModalProvider: React.FC = ({ children }): JSX.Element => {
  const [state, setState] = useState<IInternalProviderState>(initialState);
  const { modal, props } = state;

  const closeModal = useCallback(() => {
    setState(initialState);
  }, []);

  const Component: IModalsProps[EModals] = MODALS[modal];

  const showModal = useCallback(
    <T extends EModals>(newModal: T, newProps: ModalProps<T>) => setState({
      modal: newModal, props: newProps,
    }),
    [setState],
  );

  const contextValue = useMemo(
    () => ({
      ...state,
      showModal,
      closeModal,
    }),
    [state, showModal, closeModal],
  );

  return (
    <ModalContext.Provider value={contextValue}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      {Component && <Component closeModal={closeModal} {...props} />}

      {children}
    </ModalContext.Provider>
  );
};
