export const methodsName = {
  ft_transfer_call: 'ft_transfer_call',
  add_simple_pool: 'add_simple_pool',
  add_liquidity: 'add_liquidity',
  remove_liquidity: 'remove_liquidity',
  near_deposit: 'near_deposit',
  near_withdraw: 'near_withdraw',
  mft_transfer_call: 'mft_transfer_call',
  withdraw_seed: 'withdraw_seed',

  // TODO: add toasty
  withdraw_reward: 'withdraw_reward',
  withdraw: 'withdraw',
};

export const FUNCTION_CALL = 'FunctionCall';
export const SUCCESS_VALUE = 'SuccessValue';
export const BASE64 = 'base64';
export const ASCII = 'ascii';
export const TRANSACTION_HASHES = 'transactionHashes';
export const ERROR_CODE = 'errorCode';
export const ERROR_MESSAGE = 'errorMessage';
